<template>
    <div class="wd-100p logs-container" style="max-height: 600px; overflow-y: scroll;">
        <table v-if="loaded" class="table">
            <tbody>
            <tr v-for="log in claim.logs" :key="log.id">
                <td class="wd-5p">{{log.id}}</td>
                <td class="wd-10p">{{log.created_at_formatted}}</td>
                <td class="wd-20p tx-medium">{{log.user}}</td>
                <td class="wd-65p" v-if="!log.documentId"><pre style="color: #868ba1">{{log.activity}}</pre></td>
                <td class="wd-65p" v-else>
                  <pre style="color: #868ba1">{{log.activity.replace(log.documentId, '')}}</pre> <a href="javascript:void(0);" @click="openDocument(log.documentId)">{{log.documentId}}</a>
                </td>
            </tr>
            <tr v-if="claim.logs.length < 1">
                <td class="wd-100p">No logs available for this claim</td>
            </tr>
            </tbody>
        </table>

        <div :id="'documentModal-' + claim.reference" class="modal fade" style="display: none; padding-right: 17px;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document" style="max-width: 80%;">
                <div class="modal-content bg-dark tx-white tx-size-sm">
                    <div class="modal-header bg-dark pd-x-20">
                        <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">View Document: #{{document.id}}</h6>
                        <button type="button" class="close tx-white" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div v-if="document.attributes" class="modal-body bg-dark pd-20" style="max-height: 75vh; overflow-y: scroll;">

                        <p>Document Status: {{document.attributes.status}}</p>
                        <p>Submission: {{document.attributes.submission_date}}</p>
                        <p>Completion: {{document.attributes.completion_date}}</p>
                        <p>Consignment Number: {{document.attributes.consignment_number}}</p>

                    </div>
                    <div class="modal-footer bg-dark tx-white">
                        <button :id="'closeDocumentModal-' + document.id" type="button" class="btn btn-secondary tx-size-xs" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {DateTime} from 'luxon';
import axios from 'axios';
import authHeader from '@/services/auth-header';
export default {
    name: "ClaimLogs",
    props: {
      claim: {
        type: Object,
        required: true
      },
      client_id: {
        type: [Number, String],
        required: true
      }
    },
    data(){
        return {
          loaded: false,
          document: {}
        }
    },
    mounted(){
      this.claim.logs.forEach((log) => {
        let parts = log.activity.split('Document ID: ');
        if(parts[1]){
          console.log("Found document id: ", parts[1]);
          log.documentId = parts[1].replace(/\s/g, '');
        }
        log.created_at_formatted = DateTime.fromSeconds(log.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
      });
      this.loaded = true;
      this.updateScroll();
    },
    methods: {
        updateScroll(){
            var element = document.getElementsByClassName("logs-container")[0];
            element.scrollTop = element.scrollHeight;
        },
        openDocument(documentId){
          axios.get(`https://api.varsanpr.com/api/misc/imail/${documentId}`, {
            headers: authHeader()
          })
          .then(response => {
            if(response.data.document?.attributes?.submission_date !== null && response.data.document?.attributes?.submission_date !== undefined){
              response.data.document.attributes.submission_date = DateTime.fromISO(response.data.document.attributes.submission_date).toFormat('dd/MM/yyyy HH:mm');
            }
            if(response.data.document?.attributes?.completion_date !== null && response.data.document?.attributes?.completion_date !== undefined){
              response.data.document.attributes.completion_date = DateTime.fromISO(response.data.document.attributes.completion_date).toFormat('dd/MM/yyyy HH:mm');
            }
            this.document = response.data.document;
            $(`#documentModal-` + this.claim.reference).modal('toggle');
          })
          .catch(error => {
            this.$error("Failed to load document details", error);
          });
        }
    }
}
</script>

<style scoped>

    tbody tr:first-child td {
        border-top: none;
    }

    a {
      color: white;
    }

    a:hover {
      text-decoration: underline;
    }

</style>
