<template>
    <div class="pd-x-10">
        <div v-show="!showingModule" class="row">
            <div class="col-6">
                <div class="row">
                    <p class="tx-white tx-medium tx-16">Cancellations</p>
                    <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 95%; margin-left: 0px;"/>
                </div>
                <div class="row">
                    <div class="card shadow-base widget-11 wd-95p" style="border: 1px dashed white;">
                        <div class="card-header">
                            <div class="card-title">Cancellation</div>
                        </div><!-- card-header -->
                        <div class="card-body" v-show="claim.status !== 'Cancelled' && claim.status !== 'Paid'">
                            <!-- <label class="rdiobox">
                                <input type="radio" value="Invalid Claim" v-model="selectedCancellationReason">
                                <span>Invalid Claim (i.e. Fake plates)</span>
                            </label> -->
                            <label class="rdiobox" v-show="claim.type == 'parking'">
                                <input type="radio" value="Customer" v-model="selectedCancellationReason">
                                <span>Customer (i.e Hotel Guest)</span>
                            </label>
                            <label class="rdiobox" v-show="claim.type == 'parking'">
                                <input type="radio" value="Possible Missed Reads" v-model="selectedCancellationReason">
                                <span>Possible Missed Reads</span>
                            </label>
                            <label class="rdiobox">
                                <input type="radio" value="Requested by Site" v-model="selectedCancellationReason">
                                <span>Requested by Site</span>
                            </label>
                            <label class="rdiobox">
                                <input type="radio" value="Unable to continue" v-model="selectedCancellationReason">
                                <span>Unable to continue (i.e. Ignored all letters)</span>
                            </label>
                            <label v-if="claim.type === 'parking'" class="rdiobox">
                                <input type="radio" value="Possible Staff / Staff / Delivery Truck" v-model="selectedCancellationReason">
                                <span>Possible Staff / Staff / Delivery Truck</span>
                            </label>
                            <label v-if="claim.type === 'parking'" class="rdiobox">
                                <input type="radio" value="Emergency Services" v-model="selectedCancellationReason">
                                <span>Emergency Services</span>
                            </label>
                            <label v-if="claim.type === 'parking' && claim.sub_type === 'EV'" class="rdiobox">
                                <input type="radio" value="Charging vehicle" v-model="selectedCancellationReason">
                                <span>Charging vehicle</span>
                            </label>
                            <label v-if="claim.type === 'parking' && claim.sub_type === 'HGV'" class="rdiobox">
                                <input type="radio" value="HGV" v-model="selectedCancellationReason">
                                <span>HGV</span>
                            </label>
                            <label class="rdiobox">
                                <input type="radio" value="Other" v-model="selectedCancellationReason">
                                <span>Other</span>
                            </label>
                            <label v-if="selectedCancellationReason === 'Possible Staff / Staff / Delivery Truck' || selectedCancellationReason === 'Emergency Services'" class="ckbox ckbox-danger mg-t-20">
                                <input v-model="whitelistWithCancellation" type="checkbox">
                                <span>Add to Whitelist</span>
                            </label>
                            <label v-if="selectedCancellationReason === 'Requested by Site' && claim.type !== 'parking' && claim.type !== 'evparking'" class="ckbox ckbox-danger mg-t-20">
                                <input v-model="paidOnSite" type="checkbox">
                                <span>Mark as Paid on Site</span>
                            </label>
                            <div v-if="selectedCancellationReason === 'Other'" class="form-group">
                                <textarea v-model="cancellation.reason" rows="4" resize="false" class="form-control form-control-dark tx-14" placeholder="Please explain why you are cancelling this claim. This will be visible to the client."></textarea>
                            </div><!-- form-group -->
                            <button :disabled="isPaid" class="btn btn-danger mg-t-20 pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p" v-show="!cancellation.waiting && !pendingCancellations" @click="cancelClaim">CANCEL CLAIM</button>
                            <p class="tx-info" v-show="cancellation.waiting">Please wait, submitting cancellation request...</p>
                            <p class="tx-danger" v-show="pendingCancellations">There are pending cancellations for this claim. Please go to the cancellations tab.</p>
                        </div><!-- card-block -->
                        <div class="card-body" v-show="claim.status === 'Cancelled'">
                            <p><strong>Cancellation Reason:</strong> {{ claim.cancellation_reason }}</p>
                            <div class="form-group mg-t-10">
                                <label class="form-label">Cancellation Category:</label>
                                <select v-model="claim.cancellation_category" class="form-control select2" @change="updateCancellationCategory" v-select2>
                                    <option value="Duplicate Claim">Duplicate Claim</option>
                                    <option value="Tampered Plate">Tampered Plate</option>
                                    <option value="Cloned Plate">Cloned Plate</option>
                                    <option value="Fake Plate">Fake Plate</option>
                                    <option value="Foreign Plate">Foreign Plate</option>
                                    <option value="No Plate">No Plate</option>
                                    <option value="Requested by Site">Requested by Site</option>
                                    <option value="Stolen Plates/Vehicle">Stolen Plates/Vehicle</option>
                                    <option value="Requested by Head Office">Requested by Head Office</option>
                                    <option value="Incorrect Submission">Incorrect Submission</option>
                                    <option value="Site failed to provide evidence">Site failed to provide evidence</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <p class="tx-info">This claim has already been cancelled!</p>
                            <div class="form-group mg-t-10">
                                <label class="form-label">Status to change to:</label>
                                <select v-model="uncancelStatus" class="form-control select2" v-select2>
                                    <option value="Pending">Pending</option>
                                    <option value="At DVLA">At DVLA</option>
                                    <option value="Sent to Keeper">Sent to Keeper</option>
                                    <option v-if="claim.type === 'cnaf'" value="Second Letter Sent">Letter Sent</option>
                                    <option v-if="claim.type === 'driveoff' || claim.type === 'escalated' || claim.type === 'cnafe'" value="Second Letter Sent">Second Letter Sent</option>
                                    <option v-if="claim.type === 'parking'" value="Final Reminder">Final Reminder</option>
                                    <option v-if="claim.type !== 'nmop'" value="Debt Collection">Debt Collection</option>
                                    <option v-if="claim.type === 'parking'" value="Keeper Appealed">Keeper Appealed</option>
                                    <option value="Paid">Paid</option>
                                </select>
                            </div>
                            <button :disabled="isPaid" class="btn btn-danger mg-t-20 pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p" v-show="!cancellation.waiting" @click="uncancelClaim">UNCANCEL CLAIM</button>
                            <p class="tx-info" v-show="cancellation.waiting">Please wait, submitting uncancellation request...</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <p class="tx-white tx-medium tx-16">Miscellaneous</p>
                    <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 95%; margin-left: 0px;"/>
                </div>

                <div class="row wd-95p">

                    <div class="col-6">
                        <div v-if="claim.type !== 'nmop'" class="card shadow-base widget-11" style="border: 1px dashed white;">
                            <div class="card-header">
                                <div class="card-title">DVLA Responses</div>
                            </div><!-- card-header -->
                            <div class="card-body">
                                <button :disabled="isPaid" class="btn btn-warning pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p" @click="openManageDvla">DVLA Response</button>
                            </div><!-- card-block -->
                        </div>
                        <div v-else class="card shadow-base widget-11" style="border: 1px dashed white;">
                            <div class="card-header">
                                <div class="card-title">Voluntary Details</div>
                            </div><!-- card-header -->
                            <div class="card-body">
                                <button :disabled="isPaid" class="btn btn-warning pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p" @click="openVoluntaryDetails">Voluntary Details</button>
                            </div><!-- card-block -->
                        </div>
                        <div class="card shadow-base mg-t-20 widget-11" style="border: 1px dashed white;">
                            <div class="card-header">
                                <div class="card-title">Vehicle</div>
                            </div><!-- card-header -->
                            <div class="card-body">
                                <button :disabled="isPaid" class="btn btn-danger pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p" @click="openManageVehicle">Change Vehicle</button>
                            </div><!-- card-block -->
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card shadow-base widget-11" style="border: 1px dashed white;">
                            <div class="card-header">
                                <div class="card-title">Evidence Pack</div>
                            </div>
                            <div class="card-body">
                                <button class="btn btn-success pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p" :disabled="downloadingPack || isPaid" @click="openEvidencePack">
                                    <div v-if="!downloadingPack">
                                        <i class="fas fa-download"></i> Evidence Pack
                                    </div>
                                    <div v-else class="sk-wave tx-white float-left" style="margin: 0;">
                                        <div class="sk-rect sk-rect1 bg-white"></div>
                                        <div class="sk-rect sk-rect2 bg-white"></div>
                                        <div class="sk-rect sk-rect3 bg-white"></div>
                                        <div class="sk-rect sk-rect4 bg-white"></div>
                                        <div class="sk-rect sk-rect5 bg-white"></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>

        <div v-show="!showingModule" class="row mg-t-20">

            <div class="col-6">
                <div class="row mg-t-20">
                    <p class="tx-white tx-medium tx-16">Claim Settings</p>
                    <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 95%; margin-left: 0px;"/>
                </div>

                <div class="row">
                    <div class="wd-95p">

                        <div v-if="$can('claims-lock-lower-fee')" class="form-group dashed-form">
                            <label class="ckbox">
                                <input v-model="lockFee" type="checkbox" @change="toggleLock">
                                <span>Lock claim to lower fee ({{lowerFee}})</span>
                            </label>
                        </div>
                        <div class="form-group dashed-form danger">
                            <label class="ckbox ckbox-danger">
                                <input v-model="lockClaim" type="checkbox" @change="togglePause(false)">
                                <span>Lock Claim - Prevent further processing</span>
                            </label>
                        </div>
                        <div v-if="$can('claims-cheque')" class="form-group dashed-form danger">
                            <label class="ckbox ckbox-danger">
                                <input :disabled="isPaid" v-model="lockClaimCheque" type="checkbox" @change="togglePause(true)">
                                <span>Lock Claim - Cheque Received</span>
                            </label>
                        </div>
                        <div v-if="$can('claims-fix-fee')" class="form-group dashed-form">
                            <div class="form-group">
                                <label class="form-control-label">Fixed Fee: <span class="tx-danger">*</span></label>
                                <input class="form-control" type="number" step="0.01" min="5.00" max="100.00" v-model="claim.locked_fee">
                            </div>
                            <label class="ckbox">
                                <input v-model="lockedFee" type="checkbox" @change="toggleLockedFee">
                                <span>Lock claim to fee set above</span>
                            </label>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="row mg-t-20">
                    <p class="tx-white tx-medium tx-16">Claim Status Override</p>
                    <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 95%; margin-left: 0px;"/>
                </div>

                <div class="row">
                    <div class="wd-95p">
                        <button :disabled="isPaid" v-if="claim.status !== 'Pending'" class="btn btn-sm btn-danger mg-r-10" @click="clearPersonalData">Reset Claim</button>
                        <button :disabled="isPaid" v-if="claim.status !== 'Paid'" class="btn btn-sm btn-success mg-r-10" @click="openPaid">Mark as Paid</button>
                        <button :disabled="isPaid" v-if="claim.type === 'nmop'" class="btn btn-sm btn-warning mg-r-10" @click="escalate">Escalate to Drive Off</button>
                        <button :disabled="isPaid" v-if="claim.type !== 'nmop' && ($store.state.auth.user.id == 511 || $store.state.auth.user.id == 737 || $store.state.auth.user.id == 3)" class="btn btn-sm btn-warning mg-r-10" @click="escalate">Escalate to Debt Collection Review</button>
                        <button :disabled="isPaid" v-if="claim.status === 'At DVLA'" class="btn btn-sm btn-warning mg-r-10" @click="clericalRecords">Clerical Records</button>
                        <button :disabled="isPaid" v-if="claim.status === 'Pending' && (claim.type === 'parking' || claim.type === 'evparking')" class="btn btn-sm btn-warning mg-r-10" @click="clericalRecords">Clerical Records</button>
                        <button :disabled="isPaid" v-if="claim.type === 'driveoff' || claim.type === 'escalated' || claim.type === 'cnaf' || claim.type === 'cnafe'" class="btn btn-sm btn-warning mg-r-10" @click="download">Generate DVLA Enquiry</button>
                        <button :disabled="isPaid" v-if="(claim.type === 'parking' || claim.type === 'evparking') && ['Sent to Keeper', 'Sent to Driver', 'Sent to Hirer', 'Final Reminder'].includes(claim.status)" class="btn btn-sm btn-primary mg-r-10" @click="openAppeal">Process Appeal</button>
                        <button :disabled="isPaid" v-if="claim.type === 'driveoff' || claim.type === 'escalated' || claim.type === 'cnafe' || claim.type === 'nmop'" class="btn btn-sm btn-primary mg-r-10" @click="awaitingEvidence">Awaiting Evidence</button>
                        <div class="form-group mg-t-10">
                            <label class="form-label">Progress Message - Will automatically update when changed.</label>
                            <select :disabled="isPaid" v-model="selectedProgressMessage" class="form-control select2" v-select2>
                                <option v-for="msg in messages" :selected="selectedProgressMessage === msg" v-bind:value="msg">{{msg.length > 0 ? msg : "No Message"}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <submit-button :disabled="isPaid" :background="'btn btn-sm btn-success'" :clicked="updatingProgressMessage" @click="saveProgress" :text="'Save Progress Message'"></submit-button>
                        </div>

                        <button :disabled="isPaid" v-if="claim.status == 'Debt Collection Review'" class="btn btn-sm btn-primary mg-t-10" @click="regenDc">Regenerate Debt Collection Review</button>

                        <button v-if="claim.status == 'Paid' && $can('claims-receipt')" class="btn btn-sm btn-primary mg-t-10" @click="downloadReceipt" :disabled="downloadingReceipt">
                            Generate Receipt
                        </button>
                    </div>
                </div>

                <div v-if="$can('claims-manage-team')" class="row mg-t-20">
                    <p class="tx-white tx-medium tx-16">Log a Mistake</p>
                    <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 95%; margin-left: 0px;"/>
                </div>

                <div class="row" v-if="$can('claims-manage-team')">

                    <div class="col">

                        <div class="form-group">
                            <label class="form-control-label">Who's mistake is it? <span class="tx-danger">*</span></label>
                            <select v-model="mistake.user_id" class="form-control form-control-dark select2" v-select2>
                                <option v-for="user in processors" v-bind:value="user.id">{{user.email}}</option>
                            </select>
                        </div>
    
                        <div class="form-group">
                            <label class="form-control-label">What was the mistake? <span class="tx-danger">*</span></label>
                            <textarea v-model="mistake.description" class="form-control form-control-dark" rows="4" resize="false" placeholder="Please explain the mistake..."></textarea>
                        </div>
    
                        <div class="form-group">
                            <submit-button :background="'btn btn-sm btn-success'" :clicked="mistake.creating" @click="postMistake" :text="'Log Mistake'"></submit-button>
                        </div>

                    </div>

                </div>
            </div>

        </div>

        <div v-show="!showingModule" class="row mg-t-20">

            <div class="col-6">
                <div class="row mg-t-20">
                    <p class="tx-white tx-medium tx-16">Issue Notices</p>
                    <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 95%; margin-left: 0px;"/>
                </div>

                <div class="row" v-show="manualNoticeJustified || claim.status !== 'DVLA Review'">
                    <div class="wd-95p">
                        <p class="tx-warning">Please be aware that by clicking these buttons you will permanently alter the state of the claim. If a previous notice has been sent this information will be overwritten.</p>
                        <template v-if="claim.type === 'driveoff' || claim.type === 'escalated' || claim.type === 'cnafe'">
                            <button :disabled="isPaid" class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="sendNoticeServerless('driveoffnotice')">Send First Letter</button>
                            <button :disabled="isPaid" class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="sendNoticeServerless('driveoffsecond')">Send Second Letter</button>
                            <button :disabled="isPaid" class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="markAtDVLA">Set to At DVLA</button>
                            <button :disabled="isPaid" class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="markPending">Set to Pending</button>
                        </template>
                        <template v-if="claim.type === 'cnaf'">
                            <button :disabled="isPaid" class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="sendNotice('cnaf')">Send CNAF Letter</button>
                            <button :disabled="isPaid" class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="markPending">Set to Pending</button>
                        </template>
                        <template v-if="claim.type === 'nmop'">
                            <button :disabled="isPaid" class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="sendNoticeServerless('nmop')">Send NMOP Letter</button>
                            <button :disabled="isPaid" class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="markPending">Set to Pending</button>
                        </template>
                        <template v-if="claim.type === 'parking' || claim.type === 'evparking'">
                            <button :disabled="isPaid" class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="sendNoticeServerless('englandpofa')">Send Notice to Keeper (POFA)</button>
                            <button :disabled="isPaid" class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="sendNoticeServerless('england')">Send Notice to Keeper</button>
                            <button :disabled="isPaid" class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="sendNoticeServerless('hirerpofa')">Send Notice to Hirer (POFA)</button>
                            <button :disabled="isPaid"class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="sendNoticeServerless('hirer')">Send Notice to Keeper</button>
                            <button :disabled="isPaid" class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="sendNoticeServerless('finalreminder')">Send Final Reminder</button>
                            <button :disabled="isPaid" class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="sendNoticeServerless('appealrejection')">Send Appeal Rejection</button>
                            <button :disabled="isPaid" class="btn btn-sm btn-primary mg-r-10 mg-b-10" @click="markPending">Set to Pending</button>
                        </template>
                        <button :disabled="isPaid" class="btn btn-sm btn-danger mg-r-10 mg-b-10" @click="sendNotice('cancellation')">Send Cancellation Letter</button>
                        <button :disabled="isPaid" class="btn btn-sm btn-info mg-r-10 mg-b-10" @click="sendNoticeServerless('partpayment')">Send Part Payment Letter</button>
                        <button :disabled="isPaid" v-if="$can('claims-custom-letters')" class="btn btn-sm btn-primary mg-b-10" @click="sendLetter()">Send Letter</button>
                    </div>
                </div>
                <div class="row" v-show="!manualNoticeJustified && claim.status === 'DVLA Review'">

                    <div class="wd-95p">

                        <p>In order to manually send a notice, please justify why you are not able to do this through the normal DVLA Review process.</p>

                        <div class="form-group">
                            <label class="form-control-label">Justification: <span class="tx-danger">*</span></label>
                            <textarea v-model="manualNoticeJustification" class="form-control form-control-dark" rows="4" resize="false" placeholder="Please explain why you are sending this notice manually..."></textarea>
                        </div>

                        <button class="btn btn-sm" :disabled="submittingJustification" @click="submitJustificationForManualNotice">Justify</button>

                    </div>

                </div>
            </div>

        </div>

        <!-- MODULES -->

        <manage-d-v-l-a @close="closeManageDvla" @update="$emit('update')" v-if="showingModule && modules.dvla" :claim="claim" :client_id="client_id"></manage-d-v-l-a>
        <manage-vehicle @update="$emit('update')" @close="closeManageVehicle" v-if="showingModule && modules.vehicle" :claim="claim" :client_id="client_id"></manage-vehicle>
        <ManageNmop @close="closeVoluntaryDetails" v-if="showingModule && modules.voluntary" :claim="claim" :client_id="client_id"></ManageNmop>

        <!-- END MODULES -->

        <edit-modal v-if="claim.type === 'parking' || claim.type === 'evparking'" :id="'editAppealModal' + claim.id" title="Submit Appeal">

            <div class="form-layout form-layout-2">
                <div class="row no-gutters">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label">Customer Name: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="text" v-model="appeal.name" placeholder="Customer name">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label">Appellant Type: <span class="tx-danger">*</span></label>
                            <select class="form-control select2" v-model="appeal.appellant_type" v-select2>
                                <option value="Keeper">Keeper</option>
                                <option value="Hirer">Hirer</option>
                                <option value="Driver">Driver</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>
                </div><!-- row -->
                <div v-show="appeal.appellant_type !== 'Keeper'" class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Proof of Authorisation: <span class="tx-danger">*</span></label>
                            <input type="file" class="form-control" @change="handleFileUpload" accept="image/*,application/pdf" />
                        </div>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Customer Email: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="email" v-model="appeal.email" placeholder="Customer email address">
                        </div>
                    </div>
                </div><!-- row -->
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Appeal Message: <span class="tx-danger">*</span></label>
                            <textarea class="form-control" type="text" v-model="appeal.message" placeholder="Please enter the appeal message received from the customer..."></textarea>
                        </div>
                    </div>
                </div>
                <div class="form-layout-footer bd pd-20 bd-t-0">
                    <submit-button v-show="appeal.message.length > 5" :clicked="appealClicked" :background="'btn btn-info'" :text="'Save Changes'" @click="saveAppeal" />
                    <button class="btn btn-secondary" :id="'closeEditAppealModal' + claim.id" data-bs-dismiss="modal">Cancel</button>
                </div><!-- form-group -->
            </div>

        </edit-modal>

        <edit-modal :id="'sendLetterModal' + claim.id" :large="true" title="Send Letter">

            <div class="form-layout form-layout-2">
                <div class="row no-gutters">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-control-label">Letter Message: <span class="tx-danger">*</span></label>
                            <textarea @keyup="handlePreviewKeyup" style="height: 100%;" class="form-control" type="text" @keyup.once="loadLetterPreview" v-model="letter.message" placeholder="Please enter the letter message to send to the customer"></textarea>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <iframe v-if="letter.preview !== null" :src="letter.preview" width="100%" height="750px" min-height="500px;"></iframe>
                    </div> 
                </div>
                <div class="form-layout-footer bd pd-20 bd-t-0">
                    <submit-button v-show="letter.message.length > 5" :clicked="letterClicked" :background="'btn btn-info'" :text="'Send Letter'" @click="saveLetter" />
                    <button class="btn btn-primary" @click="loadLetterPreview">Refresh Preview</button>
                    <button class="btn btn-secondary" :id="'closeSendLetterModal' + claim.id" data-bs-dismiss="modal">Cancel</button>
                </div><!-- form-group -->
            </div>

        </edit-modal>

        <edit-modal :id="'markPaidModal' + claim.id" title="Mark Paid">

            <div class="form-layout form-layout-2">
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Payment Date</label>
                            <input type="date" class="form-control" v-model="payment_date" placeholder="DD/MM/YYYY">
                        </div>
                    </div>
                </div><!-- row -->
                <div class="form-layout-footer bd pd-20 bd-t-0">
                    <submit-button :clicked="paidClicked" :background="'btn btn-info'" :text="'Mark Paid'" @click="markPaid" />
                    <button class="btn btn-secondary" :id="'closePaidModal' + claim.id" data-bs-dismiss="modal">Cancel</button>
                </div><!-- form-group -->
            </div>

        </edit-modal>

    </div>
</template>

<script>
import ManageDVLA from "./management/ManageDVLA";
import ManageVehicle from "./management/ManageVehicle";
import SubmitButton from "@/components/SubmitButton";
import Swal from 'sweetalert2';
import EditModal from "@/components/EditModal";
import {DateTime} from 'luxon';
import ManageNmop from "./management/ManageNmop.vue";
import axios from 'axios';
import authHeader from "@/services/auth-header";

export default {
    name: "ClaimManagement",
    components: { SubmitButton, ManageVehicle, ManageDVLA, EditModal, ManageNmop },
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
            cancellation: {
                reason: "",
                category: "",
                waiting: false
            },
            uncancelStatus: null,
            email: {
                issues: [
                    {issue: ""}
                ],
                resolution: ""
            },
            lockFee: false,
            lockClaim: false,
            lockClaimCheque: false,
            lockedFee: false,
            showingModule: false,
            modules: {
                dvla: false,
                voluntary: false,
                vehicle: false,
                evidence: false,
                logs: false
            },
            selectedCancellationReason: 'Other',
            whitelistWithCancellation: false,
            paidOnSite: false,
            selectedProgressMessage: "",
            updatingProgressMessage: false,
            messages: [
                "",
                "Waiting for Receipt",
                "Waiting for Clearer Image",
                "Waiting for Evidence",
                "Tampered Plate",
                "System Issue",
                "Suspected Staff",
                "Suspected Guest",
                "Waiting for DVLA Response (KADOE)",
                "Payment Plan",
                "At POPLA",
                "Clerical Records",
                "Waiting for authorisation",
                "10 day evidence period",
            ],
            appeal: {
                email: "",
                message: "",
                appellant_type: "Keeper",
                file: null,
                name: "",
            },
            letter: {
                message: "",
                preview: null,
                previewTimeout: null
            },
            letterClicked: false,
            appealClicked: false,
            notices: {
                type: "",
                send: false
            },
            paidClicked: false,
            payment_date: DateTime.local().toFormat('yyyy-MM-dd'),
            atDvlaClicked: false,
            pendingClicked: false,
            downloadingPack: false,
            clericalRecordsClicked: false,
            updating: false,
            processors: [],
            mistake: {
                user_id: null,
                description: "",
                creating: false
            },
            manualNoticeJustified: false,
            manualNoticeJustification: "",
            submittingJustification: false
        }
    },
    beforeUnmount(){
        if(this.letter.preview){
            window.URL.revokeObjectURL(this.letter.preview);
        }
    },
    beforeMount(){
        this.selectedProgressMessage = this.claim.progress_message;
    }, 
    mounted(){
        this.lockFee = this.claim.lock_lower_fee === 1;
        this.lockedFee = this.claim.lock_fee === 1;
        this.lockClaim = this.claim.status === 'Locked';
        this.lockClaimCheque = this.claim.status === 'Locked' && this.claim.cheque === 1;
        $('.select2').select2();
        this.loadClaimProcessors();
    },
    methods: {
        submitJustificationForManualNotice(){

            this.submittingJustification = true;

            axios.post(`https://api.varsanpr.com/api/claims/${this.claim.id}/telemetry`, {
                event: 'dvla_review_manual_notice_justification',
                client_id: this.client_id,
                justification: this.manualNoticeJustification
            }, {
                headers: authHeader()
            })
            .then(() => {
                this.manualNoticeJustified = true;
                this.$success("Successfully submitted justification for manual notice");
            })
            .catch(err => {
                console.log(err);
                this.manualNoticeJustified = false;
                this.$error("Failed to submit justification for manual notice", err);
            })
            .finally(() => {
                this.submittingJustification = false;
            });

        },  
        handlePreviewKeyup(){
            if(this.letter.previewTimeout){
                clearTimeout(this.letter.previewTimeout);
            }
            this.letter.previewTimeout = setTimeout(() => {
                this.loadLetterPreview();
            }, 1000);
        },
        sendLetter(){
            $('#sendLetterModal' + this.claim.id).modal('toggle');
        },  
        loadLetterPreview(){
            if(this.letter.preview){
                window.URL.revokeObjectURL(this.letter.preview);
            }
            axios.post(`https://api.varsanpr.com/api/claims/${this.claim.id}/preview`, {
                letter_contents: Buffer.from(this.letter.message).toString('base64')
            }, {
                headers: authHeader(),
                params: {
                    client_id: this.client_id,
                    notice_type: 'letter'
                },
                responseType: 'arraybuffer',
            })
            .then(response => {
                let fileURL = new Blob([response.data], {type: 'application/pdf'});
                if(this.letter.preview){
                    window.URL.revokeObjectURL(this.letter.preview);
                }
                this.letter.preview = window.URL.createObjectURL(fileURL);;
            })
            .catch(error => {
                this.$error(error?.response?.data?.message || error?.message || error, error);
            });
        },
        saveLetter(){
            if(this.letterClicked) return;
            this.letterClicked = true;
            axios.post(`https://api.varsanpr.com/api/claims/${this.claim.id}/letter`, {
                client_id: this.client_id,
                notice_type: 'letter',
                letter_contents: Buffer.from(this.letter.message).toString('base64')
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$success("Successfully sent letter!");
                $('#sendLetterModal' + this.claim.id).modal('toggle');
                this.letter.message = "";
                if(this.letter.preview){
                    window.URL.revokeObjectURL(this.letter.preview);
                }
                this.$emit('update');
                this.$emit('changeMade');
            })
            .catch(error => {
                this.$error(error?.response?.data?.message || error?.message || error, error);
            })
            .finally(() => {
                this.letterClicked = false;
            });
        },  
        postMistake(){
            if(!this.$can('claims-manage-team')){
                return;
            }
            if(this.mistake.creating) return;
            this.mistake.creating = true;
            axios.post(`https://api.varsanpr.com/api/users/${this.mistake.user_id}/mistakes`, {
                client_id: this.client_id,
                reference: this.claim.reference,
                description: this.mistake.description
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$success("Successfully logged mistake!");
                this.mistake.user_id = null;
                this.mistake.description = "";
            })
            .catch(error => {
                this.$error(error?.response?.data?.message || error?.message || error, error);
            })
            .finally(() => {
                this.mistake.creating = false;
            });
        },      
        loadClaimProcessors(){
            if(!this.$can('claims-process')){
                return;
            }
            axios.get(`https://api.varsanpr.com/api/users/processors`, {
                headers: authHeader()
            })
            .then(response => {
                response.data.users = response.data.users.sort((a, b) => {
                    return a.email.localeCompare(b.email);
                });
                this.processors = response.data.users;
            })
            .catch(error => {
                this.$error("Failed loading processors!", error);
            });
        },
        updateCancellationCategory(){
            if(this.updating) return;
            this.updating = true;
            console.log(`Cancellation Category: ${this.claim.cancellation_category}`);
            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/cancellationcategory`, {
                client_id: this.client_id,
                cancellation_category: this.claim.cancellation_category
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$success(`Updated cancellation category!`);
                this.$emit('changeMade');
            })
            .catch(error => {
                this.$error(error.response?.data?.message || error?.message || error, error);
            })
            .finally(() => {
                this.updating = false;
            })
        },
        regenDc(){
            if(this.updating) return;
            this.updating = true;
            axios.post(`https://api.varsanpr.com/api/claims/${this.claim.id}/debtreview`, {
                client_id: this.client_id
            }, {
                headers: authHeader()
            })
            .then(response => {
                if(response.data.success){
                    this.$success("Successfully regenerated Debt Collection Review");
                    this.$emit('update');
                    this.$emit('changeMade');
                }else{
                    throw new Error("Failed to regenerate Debt Collection Review");
                }
            })
            .catch(error => {
                this.$error(error.response?.data?.message || "Failed to regenerate Debt Collection Review", error);
            })
            .finally(() => {
                this.updating = false;
            })
        },
        sendNoticeServerless(type){
            if(this.updating) return;
            this.updating = true;
            this.notices.type = type;
            let msg = "You won't be able to revert this!";
            if(type === 'nmop'){
                msg = msg + ' This will also send the text and email alerts to the customer.';
            }
            if(type === 'appealrejection'){
                msg = msg + ' If you send the notice the POPLA code will be regenerated.';
            }
          Swal.fire({
                title: 'Do you want to post a notice or just set the status?',
                text: msg,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Send Notice!',
                cancelButtonText: 'Set status Only!',
                reverseButtons: true
            }).then((result) => {

                let send = false;

                if(result.isConfirmed){
                    send = true;
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                } else {
                    this.updating = false;
                    return;
                }

                axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/letters/${type}`, {
                  client_id: this.client_id,
                  send: send
                }, {
                    headers: authHeader()
                })
                .then(response => {
                    this.$success("Updated claim status!");
                    this.$emit('update');
                    this.$emit('changeMade');
                })
                .catch(error => {
                    this.$error("Failed to process letter!", error);
                })
                .finally(() => {
                    this.updating = false;
                })
            });
        },
        sendNotice(type){
            if(this.updating) return;
            this.updating = true;
            this.notices.type = type;
            // ask the user if they want to send the notice or just set the status back
            let msg = "You won't be able to revert this!";
            if(type === 'appealrejection'){
                msg = msg + ' If you send the notice the POPLA code will be regenerated.';
            }
            Swal.fire({
                title: 'Do you want to post a notice or just set the status?',
                text: msg,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Send Notice!',
                cancelButtonText: 'Set Status Only!',
                reverseButtons: true
            }).then((result) => {

                let send = false;

                if(result.isConfirmed){
                    send = true;
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                } else {
                    this.updating = false;
                    return;
                }

                axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/status`, {
                    client_id: this.client_id,
                    job: type,
                    send: send
                }, {
                    headers: authHeader()
                })
                .then(response => {
                    this.$success("Updated claim status!");
                    this.$emit('update');
                    this.$emit('changeMade');
                })
                .catch(error => {
                    this.$error("Failed to process status change!", error);
                })
                .finally(() => {
                    this.updating = false;
                })

            });
        },
        awaitingEvidence(){
            if(this.updating) return;
            this.updating = true;
            let tillCheck = this.claim.assets.filter((a) => {
                return a.type === 'till_receipt';
            }).length;
            let vehicleCheck = this.claim.assets.filter((a) => {
                return a.type === 'vehicle_image';
            }).length;
            vehicleCheck += this.claim.events.length;

            if(tillCheck > 0 && vehicleCheck > 0){
                Swal.fire({
                    title: `Unable to move!`,
                    html: `There are currently ${tillCheck} till receipts and ${vehicleCheck} vehicle images. In order to move this claim to Awaiting Evidence one or both need to be 0`,
                    confirmButtonText: `OK`
                });
                this.updating = false;
            }else{
                axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/changestatus`, {
                    client_id: this.client_id,
                    status: 'Awaiting Evidence'
                }, {
                    headers: authHeader()
                })
                .then(response => {
                    if(response.data.success){
                        this.$success("Moved claim to Awaiting Evidence");
                        this.$emit('update');
                        this.$emit('changeMade');
                    }else{
                        this.$error(response.data.message);
                    }
                })
                .catch(error => {
                    this.$error("Failed to move claim to Awaiting Evidence", error);
                })
                .finally(() => {
                    this.updating = false;
                })
            }
        },
        openAppeal(){
            $('#editAppealModal' + this.claim.id).modal('toggle');
        },
        handleFileUpload(event){
            this.appeal.file = event.target.files[0];
        },
        async toBase64(file){
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        async saveAppeal(){
            if(this.appealClicked) return;
            this.appealClicked = true;

            if(this.appeal.file){
                this.appeal.filename = this.appeal.file.name;
                this.appeal.file = await this.toBase64(this.appeal.file);
            }else{
                // this.appeal.file = null;
            }

            axios.put(`https://api.varsanpr.com/api/claims/${this.claim.id}/appeals`, {
                ...this.appeal,
                client_id: this.client_id
            }, {
                headers: authHeader()
            })
            .then(response => {
                if(response.data.success){
                    this.$success('Successfully submitted appeal');
                    this.$emit('update');
                    this.$emit('changeMade');
                    $('#closeEditAppealModal' + this.claim.id).click();
                }else{
                    throw new Error("Failed to submit appeal");
                }
            })
            .catch(error => {
                this.$error("Failed to submit appeal.", error);
            })
            .finally(() => {
                this.appealClicked = false;
            })
        },
        async saveProgress(){
            this.updatingProgressMessage = true;

            let reasonForUpdate;

            if(this.selectedProgressMessage === 'Tampered Plate'){
                const {value: reason} = await Swal.fire({
                    title: `Why are you setting ${this.claim.reference} to Tampered Plate?`,
                    input: 'text',
                    inputPlaceholder: 'Input a reason',
                    showCancelButton: true
                });

                if(reason) reasonForUpdate = reason;
            }

            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/progress`, {
                message: this.selectedProgressMessage,
                client_id: this.client_id,
                reason: reasonForUpdate
            }, {
                headers: authHeader()
            })
            .then(response => {
                if(response.status === 200){
                    this.$emit('update');
                    this.$emit('changeMade');
                    this.$success("Successfully updated progress message!");
                }else{
                    throw new Error("Failed to update progress message.");
                }
            })
            .catch(error => {
                this.$error("Failed to update progress message.", error);
            });
        },
        async cancelClaim(){
            if(this.updating) return;
            this.updating = true;
            let reason = this.cancellation.reason;
            if(this.selectedCancellationReason === 'Other'){
                if(this.cancellation.reason.length < 2){
                    this.$error("Please provide a reason for cancellation!");
                    this.updating = false;
                    return;
                }
            }else{
                reason = this.selectedCancellationReason;
            }

            if(this.selectedCancellationReason !== 'Requested by Site'){
                this.paidOnSite = false;
            }

            let {value: category} = await Swal.fire({
                title: 'Please select a cancellation category',
                text: 'This will be used by customers to understand the reasons their claims are being cancelled. If you are unsure which one to select please speak to your manager.',
                icon: 'warning',
                input: 'select',
                inputValue: 'Other',
                inputOptions: {
                    'Duplicate Claim': 'Duplicate Claim',
                    'Tampered Plate': 'Tampered Plate',
                    'Cloned Plate': 'Cloned Plate',
                    'Fake Plate': 'Fake Plate',
                    'Foreign Plate': 'Foreign Plate',
                    'No Plate': 'No Plate',
                    'Requested by Site': 'Requested by Site',
                    'Requested by Head Office': 'Requested by Head Office',
                    'Incorrect Submission': 'Incorrect Submission',
                    'Other': 'Other'
                },
            })

            this.cancellation.waiting = true;
            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/changestatus`, {
                reason: reason,
                client_id: this.client_id,
                whitelist: this.whitelistWithCancellation,
                paidonsite: this.paidOnSite,
                status: this.paidOnSite ? 'Paid on Site' : 'Cancelled',
                cancellation_category: category
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$emit('update');
                this.$emit('changeMade');
                this.cancellation.waiting = false;
            })
            .catch(error => {
                this.$error("Failed to cancel claim.", error);
                this.cancellation.waiting = false;
            })
            .finally(() => {
                this.updating = false;
            })
        },
        async uncancelClaim(){
            if(this.updating) return;
            this.updating = true;
            const {value: reason} = await Swal.fire({
                title: `Input a reason for uncancelling ${this.claim.reference}`,
                input: 'text',
                inputPlaceholder: 'Input a reason',
                showCancelButton: true
            });

            if(reason){
                this.cancellation.waiting = true;
                axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/changestatus`, {
                    reason: reason,
                    client_id: this.client_id,
                    status: this.uncancelStatus,
                    whitelist: false,
                    paidonsite: false
                }, {
                    headers: authHeader()
                })
                    .then(response => {
                        this.$emit('update');
                        this.$emit('changeMade');
                        this.uncancelStatus = null;
                        this.cancellation.waiting = false;
                    })
                    .catch(error => {
                        this.$error("Failed to uncancel claim.", error);
                        this.cancellation.waiting = false;
                    })
                    .finally(() => {
                        this.updating = false;
                    })
            }else{
                this.updating = false;
            }
        },
        async escalate(){
            if(this.updating) return;
            this.updating = true;
            const {value: reason} = await Swal.fire({
                title: `Select a reason for escalating ${this.claim.reference}`,
                input: 'select',
                inputOptions: {
                    "No Keeper Details": "No Keeper Details",
                    "Invalid Address": "Invalid Address",
                    "Provided Keeper Denies Liability": "Provided Keeper Denies Liability",
                    "No intention to pay": "No intention to pay",
                    "Requested to commence court proceedings": "Requested to commence court proceedings",
                    "Other": "Other"
                },
                inputPlaceholder: 'Select a reason',
                showCancelButton: true
            });
            if(reason){
                axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/escalate`, {
                    client_id: this.client_id,
                    reason: reason
                }, {
                    headers: authHeader()
                })
                    .then(response => {
                        this.$success(`Escalated ${this.claim.reference} to Drive Off.`);
                        this.$emit('update');
                        this.$emit('changeMade');
                    })
                    .catch(error => {
                        this.$error("Failed to escalate to Drive Off.", error);
                    })
                    .finally(() => {
                        this.updating = false;
                    })
            }else{
                this.updating = false;
            }
        },
        async clearPersonalData(){
            if(this.updating) return;
            this.updating = true;
          const {value: result} = await Swal.fire({
                title: `Are you sure you wish to reset this claim? It will remove all personal data and cannot be undone!`,
                showCancelButton: true,
                confirmButtonText: 'Reset Claim',
                cancelButtonText: 'Cancel',
            });
            if(result){
              console.log("Clearing data!");

              axios.delete(`https://api.varsanpr.com/api/claims/${this.claim.id}/keeper`, {
                    data: {
                      client_id: this.client_id
                    },
                    headers: authHeader()
                })
                .then(response => {
                    this.$success("Reset claim!");
                    this.$emit('update');
                    this.$emit('changeMade');
                })
                .catch(error => {
                    this.$error("Failed to reset claim!", error);
                })
                .finally(() => {
                    this.updating = false;
                })

            }else{
              console.log("Not clearing data", result);
              this.updating = false;
            }
        },
        openPaid(){
            $('#markPaidModal' + this.claim.id).modal('toggle');
        },
        async markPaid(){
            if(this.updating) return;
            this.updating = true;
            $('#closePaidModal' + this.claim.id).click();
            const {value: reason} = await Swal.fire({
                title: `Input a reason for marking ${this.claim.reference} as paid`,
                input: 'text',
                inputPlaceholder: 'Input a reason',
                showCancelButton: true
            });

            if(reason){
                axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/changestatus`, {
                    client_id: this.client_id,
                    date: this.payment_date,
                    status: 'Paid',
                    reason: reason
                }, {
                    headers: authHeader()
                })
                    .then(response => {
                        this.$emit('update');
                        this.$emit('changeMade');
                        this.$success("Marked claim as paid!");
                        $('#closePaidModal' + this.claim.id).click();
                    })
                    .catch(error => {
                        this.$error("Failed to mark claim as paid.", error);
                    })
                    .finally(() => {
                        this.paidClicked = false;
                        this.updating = false;
                    })
            }else{
                this.updating = false;
                $('#markPaidModal' + this.claim.id).modal('toggle');
            }
        },
        async markAtDVLA(){
            if(this.updating) return;
            this.updating = true;
            const {value: reason} = await Swal.fire({
                title: `Input a reason for marking ${this.claim.reference} as At DVLA`,
                input: 'text',
                inputPlaceholder: 'Input a reason',
                showCancelButton: true
            });

            if(reason){
                this.atDvlaClicked = true;
                axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/changestatus`, {
                    client_id: this.client_id,
                    status: 'At DVLA',
                    reason: reason
                }, {
                    headers: authHeader()
                })
                    .then(response => {
                        this.$emit('update');
                        this.$emit('changeMade');
                        this.$success("Marked claim at DVLA!");
                    })
                    .catch(error => {
                        this.$error("Failed to mark claim at DVLA.", error);
                    })
                    .finally(() => {
                        this.atDvlaClicked = false;
                        this.updating = false;
                    })
            }else{
                this.updating = false;
            }
        },
        async clericalRecords(){
            if(this.updating) return;
            this.updating = true;
            const {value: reason} = await Swal.fire({
                title: `Input a reason for marking ${this.claim.reference} as Clerical Records`,
                input: 'text',
                inputPlaceholder: 'Input a reason',
                showCancelButton: true
            });

            if(reason){
                this.clericalRecordsClicked = true;
                axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/changestatus`, {
                    client_id: this.client_id,
                    status: 'Clerical Records',
                    reason: reason
                }, {
                    headers: authHeader()
                })
                    .then(response => {
                        this.$emit('update');
                        this.$emit('changeMade');
                        this.$success("Marked claim as Clerical Records!");
                    })
                    .catch(error => {
                        this.$error("Failed to mark claim as Clerical Records.", error);
                    })
                    .finally(() => {
                        this.clericalRecordsClicked = false;
                        this.updating = false;
                    })
            }else{
                this.updating = false;
            }
        },
        async markPending(){
            if(this.updating) return;
            this.updating = true;
            const {value: reason} = await Swal.fire({
                title: `Input a reason for marking ${this.claim.reference} as Pending`,
                input: 'text',
                inputPlaceholder: 'Input a reason',
                showCancelButton: true
            });
            if(reason){
                this.pendingClicked = true;
                axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/changestatus`, {
                    client_id: this.client_id,
                    status: 'Pending',
                    reason: reason
                }, {
                    headers: authHeader()
                })
                    .then(response => {
                        this.$emit('update');
                        this.$emit('changeMade');
                        this.$success("Marked claim as Pending!");
                    })
                    .catch(error => {
                        this.$error("Failed to mark claim as Pending.", error);
                    })
                    .finally(() => {
                        this.pendingClicked = false;
                        this.updating = false;
                    })
            }else{
                this.updating = false;
            }
        },
        toggleLock(){
            if(this.updating) return;
            this.updating = true;
            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/fee`, {
                client_id: this.client_id,
                locked: this.lockFee,
                mode: 'lower'
            }, {
                headers: authHeader()
            })
                .then(response => {
                    this.$success(this.lockFee ? 'Locked claim to lower fee' : 'Unlocked claim');
                    this.$emit('lockFee', this.lockFee);
                    this.$emit('changeMade');
                })
                .catch(error => {
                    this.$error("Failed changing the fee lock.", error);
                    this.lockFee = !this.lockFee;
                })
                .finally(() => {
                    this.updating = false;
                })
        },
        toggleLockedFee(){
            if(this.updating) return;
            this.updating = true;
            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/fee`, {
                client_id: this.client_id,
                locked: this.lockedFee,
                fee: this.claim.locked_fee,
                mode: 'override'
            }, {
                headers: authHeader()
            })
                .then(response => {
                    this.$success(this.lockedFee ? 'Locked claim to fee' : 'Unlocked claim');
                    this.$emit('update');
                    this.$emit('changeMade');
                    if(this.claim.locked_fee){
                        this.claim.locked_fee = parseFloat(parseFloat(this.claim.locked_fee).toFixed(2));
                    }
                })
                .catch(error => {
                    this.$error("Failed changing the fee lock.", error);
                    this.lockedFee = !this.lockedFee;
                })
                .finally(() => {
                    this.updating = false;
                })
        },
        async togglePause(cheque = false){

            if(this.lockClaim === true || (this.lockClaimCheque === true && cheque === true)){
                const {value: reason} = await Swal.fire({
                    title: `Why are you locking ${this.claim.reference}`,
                    input: 'text',
                    inputPlaceholder: 'Input a reason',
                    showCancelButton: true
                });

                if(reason) {
                    axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/changestatus`, {
                        client_id: this.client_id,
                        status: "Locked",
                        reason: reason,
                        cheque: cheque ? "yes" : "no"
                    }, {
                        headers: authHeader()
                    })
                        .then(response => {
                            this.$success(this.lockClaim ? 'Locked claim' : 'Unlocked claim');
                            this.$emit('update');
                            this.$emit('changeMade');
                        })
                        .catch(error => {
                            this.$error(this.lockClaim ? 'Failed to lock claim.' : 'Failed to unlock claim.', error);
                            this.lockClaim = !this.lockClaim;
                        })
                }else{
                    this.lockClaim = !this.lockClaim;
                }
            }else{
                axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/changestatus`, {
                    client_id: this.client_id,
                    status: "Locked"
                }, {
                    headers: authHeader()
                })
                    .then(response => {
                        this.$success(this.lockClaim ? 'Locked claim' : 'Unlocked claim');
                        this.$emit('update');
                        this.$emit('changeMade');
                    })
                    .catch(error => {
                        this.$error(this.lockClaim ? 'Failed to lock claim.' : 'Failed to unlock claim.', error);
                        this.lockClaim = !this.lockClaim;
                    })
            }
        },
        openManageDvla(){
            // if(!this.claim.hasDVLAResponse) return;
            this.showingModule = true;
            this.modules.dvla = true;
        },
        closeManageDvla(){
            this.modules.dvla = false;
            this.showingModule = false;
        },
        openVoluntaryDetails(){
            // if(!this.claim.hasDVLAResponse) return;
            this.showingModule = true;
            this.modules.voluntary = true;
        },
        closeVoluntaryDetails(){
            this.modules.voluntary = false;
            this.showingModule = false;
        },
        openManageVehicle(){
            this.showingModule = true;
            this.modules.vehicle = true;
        },
        closeManageVehicle(){
            this.showingModule = false;
            this.modules.vehicle = false;
        },
        downloadReceipt(){
            this.downloadingReceipt = true;
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/receipt?client_id=${this.client_id}`, {
                responseType: 'arraybuffer',
                headers: authHeader()
            })
                .then(response => {
                    saveAs(new Blob([response.data]), this.claim.reference + '-receipt.pdf');
                    this.$success("Downloaded receipt");
                    this.downloadingReceipt = false;
                })
                .catch(error => {
                    this.$error("Failed to download receipt.", error);
                    this.downloadingReceipt = false;
                })
        },
        openEvidencePack(){
            this.downloadingPack = true;
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidencepack?client_id=${this.client_id}`, {
                responseType: 'arraybuffer',
                headers: authHeader()
            })
                .then(response => {
                    saveAs(new Blob([response.data]), this.claim.reference + '-pack.zip');
                    this.$success("Downloaded evidence pack");
                    this.$emit('changeMade');
                    this.downloadingPack = false;
                })
                .catch(error => {
                    this.$error("Failed to download evidence pack.", error);
                    this.downloadingPack = false;
                })
        },
        async download(){
            if(this.claim.type !== 'driveoff' && this.claim.type !== 'escalated' && this.claim.type !== 'cnafe' && this.claim.type !== 'cnaf') return;
            const {value: reason} = await Swal.fire({
                title: `Why are you regenerating the enquiry for ${this.claim.reference}`,
                input: 'text',
                inputPlaceholder: 'Input a reason',
                showCancelButton: true
            });
            
            this.downloading = true;
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/dvlaenquiry?client_id=${this.client_id}&reason=${reason}`, {responseType: 'arraybuffer', headers: authHeader()})
                .then(response => {
                    saveAs(new Blob([response.data]), 'dvla.pdf');
                    this.$success("Downloaded DVLA Enquiry Form");
                    this.$emit('changeMade');
                    this.downloading = false;
                })
                .catch(error => {
                    this.$error(error?.response?.data?.message || error?.message || "Failed to download the DVLA Enquiry Form.", error);
                    this.downloading = false;
                });
        }
    },
    computed: {
        lowerFee: function(){
            switch(this.claim.type){
                case 'nmop':
                    return '£5.00';
                case 'parking':
                    return '£60.00';
                case 'driveoff':
                case 'escalated':
                default:
                    return '£30.00';
            }
        },
        pendingCancellations: function(){
            return this.claim.cancellations.filter((c) => {
                return c.accepted_at == null && c.rejected_at == null;
            }).length > 0;
        },
        isPaid: function(){
            return this.claim.status === 'Paid' || this.claim.status === 'Paid on Site';
        }
    }
}
</script>

<style scoped>

    .dashed-form {
        border: 1px dashed white;
        padding: 7px 7px 0px 7px;
    }

    .dashed-form.danger {
        border: 1px dashed red;
    }

</style>
