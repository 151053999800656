<template>
    <div class="row">
        <div class="col">
            <div class="card bd-0">
              <div class="card-header bg-primary">
                <ul class="nav nav-tabs nav-tabs-for-dark card-header-tabs">
                    <li class="nav-item">
                        <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.information}" href="#" @click="selectTab('information')">Information</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.documents}" href="#" @click="selectTab('documents')">Documents</a>
                  </li>
                  <li class="nav-item" v-if="claim.type === 'parking' || claim.type === 'evparking'">
                    <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.signage}" href="#" @click="selectTab('signage')">Signage</a>
                  </li>
                  <li class="nav-item" v-if="(claim.type === 'parking' || claim.type === 'evparking') && $can('sites-edit-parking-enabled')">
                    <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.settings}" href="#" @click="selectTab('settings')">Settings</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.mailingList}" href="#" @click="selectTab('mailingList')">Mailing List</a>
                  </li>
                  <li class="nav-item" v-if="$can('claims-delete-note')">
                    <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.users}" href="#" @click="selectTab('users')">Users</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.whitelist}" href="#" @click="selectTab('whitelist')">Whitelist</a>
                  </li>
                </ul>
              </div><!-- card-header -->
              <div class="card-body">
                <!-- Information -->
                <div v-if="tabs.information">
                    <!--Site Details-->
                    <div class="card">
                        <div class="card-header text-white">
                            Viewing Site Details
                        </div>
                        <div class="card-body">

                            <div class="form-group">
                                <label>Tier</label>
                                <select :disabled="true" v-model="claim.site.tier" class="form-control select2" v-select2>
                                    <option v-for="t in tiers" v-bind:value="t">{{t}}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Name</label>
                                <input  :disabled="true" autocomplete="none" type="text" required="" name="name" v-model="claim.site.name" class="form-control form-control-dark">
                            </div>
                            <div class="form-group">
                                <label>Reference <span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <input :disabled="true" type="text" class="form-control form-control-dark readonly" style="width: 10em; text-align: center;" :value="claim.site.prefix" readonly>
                                    </div>
                                    <input type="number" class="form-control form-control-dark" required="" name="reference" v-model="claim.site.numbers" readonly>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Postcode</label>
                                <input  :disabled="true" type="text" autocomplete="none" required="" name="postcode" v-model="claim.site.postcode" class="form-control form-control-dark">
                            </div>
                            <div class="form-group">
                                <label>Address Line 1</label>
                                <input  :disabled="true" type="text" autocomplete="none" required="" name="address_one" v-model="claim.site.address_one" class="form-control form-control-dark">
                            </div>
                            <div class="form-group">
                                <label>Address Line 2</label>
                                <input  :disabled="true" type="text" autocomplete="none" required="" name="address_two" v-model="claim.site.address_two" class="form-control form-control-dark">
                            </div>
                            <div class="form-group">
                                <label>Address Line 3</label>
                                <input  :disabled="true" type="text" autocomplete="none" name="address_three" v-model="claim.site.address_three" class="form-control form-control-dark">
                            </div>
                            <div class="form-group">
                                <label>Address Line 4</label>
                                <input  :disabled="true" type="text" autocomplete="none" name="address_four" v-model="claim.site.address_four" class="form-control form-control-dark">
                            </div>

                            <div class="form-group">
                                <label>Telephone Number</label>
                                <input  :disabled="true" type="text" autocomplete="none" name="telephone" v-model="claim.site.telephone" class="form-control form-control-dark">
                            </div>

                            <div class="form-group">
                                <label>Latitude</label>
                                <input  :disabled="true" type="text" id="latitude" name="latitude" v-model="claim.site.latitude" class="form-control form-control-dark">
                            </div>

                            <div class="form-group">
                                <label>Longitude</label>
                                <input  :disabled="true" type="text" id="longitude" name="longitude" v-model="claim.site.longitude" class="form-control form-control-dark">
                            </div>

                        </div>
                    </div>
                </div>
                </div>
                <!-- Documents -->
                <div v-if="tabs.documents">
                    <SiteAssets v-if="documentsLoaded" :site="claim.site"  @reload="$emit('reload')" />
                </div>
                <!-- Signage -->
                <div v-if="tabs.signage">
                    <SiteSignage :site="claim.site" />
                </div>
                <!-- Settings -->
                <div v-if="tabs.settings && $can('sites-edit-parking-enabled')">
                    <SiteClaimSettings :site="claim.site" :siteChange="true" />
                </div>
                <!-- Mailing List -->
                <div v-if="tabs.mailingList">
                    <MailingList :site_id="claim.site.id" :site_name="claim.site.name" />
                </div>
                <!-- Users -->
                <div v-if="tabs.users && $can('claims-delete-note')">
                    <SiteUsers v-if="usersLoaded" :site="claim.site" :users="claim.site.users" />
                </div>
                <!-- Whitelist -->
                <div v-if="tabs.whitelist">

                    <div class="row" style="margin-bottom: 20px;">
                        <div class="col">
                            <div class="form-group">
                                <label>Search Whitelist</label>
                                <input type="text" class="form-control form-control-dark" v-model="whitelist.registration" @keyup.enter="loadSiteWhitelist(1)">
                            </div>

                            <button class="btn btn-sm btn-primary" style="float: right;" @click="loadSiteWhitelist(1)">Search</button>
                            <button class="btn btn-sm btn-secondary" style="float: right;" @click="addToSiteWhitelist">Add to Whitelist</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">

                            <Pagination :data="whitelist.pagination" :showCount="true" @search="loadSiteWhitelist"></Pagination>

                            <table class="table table-striped table-hover tx-white">
                                <thead class="thead-colored thead-primary">
                                    <tr>
                                        <th>Vehicle</th>
                                        <th>Notes</th>
                                        <th>Added</th>
                                        <th>Expiry</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="whitelist.entries.length < 1 && whitelist.loaded">
                                        <td colspan="5" class="text-center">No whitelist entries found.</td>
                                    </tr>
                                    <tr v-if="!whitelist.loaded">
                                        <td colspan="5" class="text-center">Loading...</td>
                                    </tr>
                                    <tr v-if="whitelist.loaded" v-for="entry in whitelist.entries">
                                        <td>{{ entry.registration }}</td>
                                        <td>{{ entry.notes }}</td>
                                        <td>{{ entry.added }}</td>
                                        <td>{{ entry.expiry }}</td>
                                        <td>
                                            <button class="btn btn-sm btn-danger" @click="removeWhitelistEntry(entry)">Remove</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <Pagination :data="whitelist.pagination" :showCount="true" @search="loadSiteWhitelist"></Pagination>

                        </div>
                    </div>

                </div>
            </div><!-- card -->

            <!-- Site Info / Signage / Documents / Parking Settings / Users / Mailing List -->
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import SiteAssets from '../../sites/components/SiteAssets.vue';
import SiteSignage from '../../sites/components/Signage.vue';
import SiteClaimSettings from '../../sites/components/SiteClaimSettings.vue';
import MailingList from '../../sites/components/MailingList.vue';
import SiteUsers from '../../sites/components/SiteUsers.vue';
import Pagination from '@/components/Pagination.vue';
import { DateTime } from 'luxon';
import authHeader from '@/services/auth-header';

export default {
    name: 'ClaimSite',
    components: {
        SiteAssets,
        SiteSignage,
        SiteClaimSettings,
        MailingList,
        SiteUsers,
        Pagination
    },
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        },
    },
    data(){
        return {
            tabs: {
                information: true,
                documents: false,
                signage: false,
                settings: false,
                users: false,
                mailingList: false,
                whitelist: false
            },
            documentsLoaded: false,
            usersLoaded: false,
            tiers: [
                "Full VARS System",
                "Tablet Only",
                "Parking Only",
                "Portal Only",
                "Discontinued"
            ],
            whitelist: {
                entries: [],
                loaded: false,
                registration: "",
                pagination: {
                    currPage: 1,
                    totalResults: 0,
                    from: 0,
                    to: 0,
                    perPage: 0,
                    lastPage: 1,
                }
            }
        }
    },
    mounted(){
        this.loadSiteDocuments();
        this.loadSiteUsers();
        this.loadSiteWhitelist();
    },
    methods: {
        addToSiteWhitelist(){
            let registration = prompt("Please enter the registration you wish to add to the whitelist.");
            if(!registration){
                return;
            }
            if(registration.length < 1){
                return;
            }
            let notes = prompt("Please enter any notes you wish to add to the whitelist entry.");
            if(registration){
                axios.put(`https://api.varsanpr.com/api/sites/${this.claim.site_id}/whitelist`, {
                    plates: [
                        {
                            registration: registration,
                            notes: notes,
                            expiry: null
                        }
                    ]
                }, {
                    headers: authHeader()
                })
                .then(response => {
                    this.$emit('changeMade');
                    this.loadSiteWhitelist(this.whitelist.pagination.currPage);
                })
                .catch(error => {
                    console.log(error);
                    this.$error(error.response?.data?.message || error?.message || error, error);
                });
            }
        },  
        selectTab(tab){
            for (let t in this.tabs){
                this.tabs[t] = false;
            }

            this.tabs[tab] = true;
        },
        loadSiteDocuments(){
            axios.get(`https://api.varsanpr.com/api/sites/${this.claim.site_id}/documents`, {
                headers: authHeader()
            })
            .then(response => {
                this.claim.site.assets = response.data.documents;
                this.documentsLoaded = true;
            })
            .catch(error => {
                console.log(error);
            });
        },
        loadSiteUsers(){
            axios.get(`https://api.varsanpr.com/api/sites/${this.claim.site_id}/users`, {
                headers: authHeader()
            })
            .then(response => {
                this.claim.site.users = response.data.users;
                this.usersLoaded = true;
            })
            .catch(error => {
                console.log(error);
            });
        },
        loadSiteWhitelist(page = 1){
            this.whitelist.loaded = false;
            axios.get(`https://api.varsanpr.com/api/sites/${this.claim.site_id}/whitelist`, {
                params: {
                    page: page,
                    registration: this.whitelist.registration || ""
                },
                headers: authHeader()
            })
            .then(response => {
                response.data.whitelist.forEach((entry) => {
                    entry.added = DateTime.fromSeconds(entry.added).toLocaleString(DateTime.DATETIME_MED);
                    if(entry.expiry){
                        entry.expiry = DateTime.fromSeconds(entry.expiry).toLocaleString(DateTime.DATETIME_MED);
                    }
                })
                this.whitelist.entries = response.data.whitelist;
                this.whitelist.pagination.currPage = parseInt(response.data.pagination.page);
                this.whitelist.pagination.totalResults = response.data.pagination.total;
                this.whitelist.pagination.from = 1 + (response.data.pagination.page -1) * response.data.pagination.showing;
                this.whitelist.pagination.to = 1 + response.data.pagination.page * response.data.pagination.showing;
                if(this.whitelist.pagination.to > this.whitelist.pagination.totalResults){
                    this.whitelist.pagination.to = this.whitelist.pagination.totalResults;
                }
                this.whitelist.pagination.perPage = response.data.pagination.showing;
                this.whitelist.pagination.lastPage = response.data.pagination.lastPage;
                this.whitelist.loaded = true;

            })
            .catch(error => {
                console.log(error);
                this.$error(error.response?.data?.message || error?.message || error, error);
            });
        },
        removeWhitelistEntry(entry){
            if(confirm(`Are you sure you want to remove ${entry.registration} from the whitelist?`)){
                axios.delete(`https://api.varsanpr.com/api/sites/${this.claim.site_id}/whitelist`, {
                    data: {
                        registration: entry.registration
                    },
                    headers: authHeader()
                })
                .then(response => {
                    this.$emit('changeMade');
                    this.loadSiteWhitelist(this.whitelist.pagination.currPage);
                })
                .catch(error => {
                    console.log(error);
                    this.$error(error.response?.data?.message || error?.message || error, error);
                });
            }
        }
    }
}
</script>

<style scoped>
.card-header-tabs.nav-tabs-for-dark .nav-link.active {
    border-bottom: 2px solid white;
    background-color: transparent;
}

.card-header-tabs.nav-tabs-for-dark .nav-link:hover {
    background-color: transparent;
    border-bottom: 2px solid white;
    font-weight: bold;
}

</style>