<template>
    <div class="row">
        <div class="col-md-12 col-sm-12">

            <div class="card">
                <div class="card-header tx-white">
                    DVLA Response <button class="btn btn-sm btn-danger ft-right" @click="back">Back</button>
                </div>
                <div v-if="loading" class="card-body">
                    <loader :show="loading"></loader>
                </div>
                <div v-else class="card-body">

                    <p class="tx-uppercase tx-bold tx-danger">Please be advised that by viewing this page and making any changes to the data shown you are acknowledging that you have sufficient permission to do so and are only doing so because it is absolutely necessary.</p>

                    <p class="tx-bold tx-warning">The data shown on this page is personal data and therefore governed under GDPR. If you cannot guarantee the security of the data you are viewing please immediately close this page.</p>

                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Name <span class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input type="text" name="name" v-model="response.name" class="form-control form-control-dark">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Address One <span class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input type="text" name="address_one" v-model="response.address_one" class="form-control form-control-dark">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Address Two <span class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input type="text" name="address_two" v-model="response.address_two" class="form-control form-control-dark">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Address Three <span class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input type="text" name="address_three" v-model="response.address_three" class="form-control form-control-dark">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Address Four</label>
                        <div class="col-md-9">
                            <input type="text" name="address_four" v-model="response.address_four" class="form-control form-control-dark">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Special Notes</label>
                        <div class="col-md-9">
                            <input type="text" name="special_notes" v-model="response.special_notes" class="form-control form-control-dark">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">DVLA Reference</label>
                        <div class="col-md-9">
                            <input type="text" name="dvla_reference" v-model="response.dvla_reference" class="form-control form-control-dark">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Reason for Change <span class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input type="text" name="reason" v-model="reason" class="form-control form-control-dark">
                        </div>
                    </div>

                    <p>Please select whether the details belong to the registered keeper, driver of the vehicle or hirer of the vehicle at the time of the event.</p>

                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Type <span class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <select class="form-control form-control-dark" v-model="response.type">
                                <option value="Keeper">Keeper</option>
                                <option value="Driver">Driver</option>
                                <option value="Hirer">Hirer</option>
                            </select>
                        </div>
                    </div>

                    <p class="text-danger" v-if="error">{{errorMsg}}</p>

                    <p v-if="changed" class="tx-uppercase tx-bold tx-danger">Before saving changes, please check to make sure all the details above are correct to the best of your knowledge and any changes you have made are justified within the reason you provided!</p>
                    <submit-button @click="saveChanges" v-if="changed" :clicked="submitting" :background="'btn-success'" :text="'Save Changes'"></submit-button>
                </div>
            </div>

        </div>
        <br/>
        <div class="col-md-12 col-sm-12" style="margin-top: 10px;">
            <div class="card">
                <div class="card-header tx-white">
                    Version History
                </div>
                <div v-if="loadingVersions" class="card-body">
                    <loader :show="loadingVersions"></loader>
                </div>
                <div v-else class="card-body">
                    <p><strong>Note:</strong> The user is the person who changed the value and the reason is the reason they provided for changing from that value.</p>
                    <table class="table table-striped table-hover tx-white" width="100%">
                        <thead class="thead-colored thead-primary">
                        <tr>
                            <th>Name</th>
                            <th>Address 1</th>
                            <th>Address 2</th>
                            <th>Address 3</th>
                            <th>Address 4</th>
                            <th>Special Notes</th>
                            <th>DVLA Reference</th>
                            <th>User</th>
                            <th>Reason</th>
                            <th>Type</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="v in versions" :key="v.id">
                            <td>{{v.name}}</td>
                            <td>{{v.address_one}}</td>
                            <td>{{v.address_two}}</td>
                            <td>{{v.address_three}}</td>
                            <td>{{v.address_four}}</td>
                            <td>{{v.special_notes}}</td>
                            <td>{{v.dvla_reference}}</td>
                            <td>{{v.user}}</td>
                            <td>{{v.reason}}</td>
                            <td>{{v.type}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import SubmitButton from "@/components/SubmitButton";
import Loader from "@/components/Loader";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "ManageDVLA",
    components: {
        SubmitButton,
        Loader
    },
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
            response: {
                name: "",
                address_one: "",
                address_two: "",
                address_three: "",
                address_four: "",
                special_notes: "",
                dvla_reference: "",
                type: "Keeper"
            },
            response_original: {
                name: "",
                address_one: "",
                address_two: "",
                address_three: "",
                address_four: "",
                special_notes: "",
                dvla_reference: "",
                type: "Keeper"
            },
            hirerToggle: false,
            versions: [],
            reason: "",
            error: false,
            errorMsg: "",
            submitting: false,
            loading: true,
            loadingVersions: true
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        back(){
            this.$emit('close');
        },
        load(){
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/keeperdata?client_id=${this.client_id}`, {
                headers: authHeader()
            })
                .then(response => {
                    this.response = response.data.current;
                    this.response_original = JSON.parse(JSON.stringify(this.response));
                    this.versions = response.data.historic;
                    this.loadingVersions = false;
                    this.loading = false;
                })
                .catch(error => {
                    this.$error("Failed to load DVLA response.", error);
                });
        },
        saveChanges(){
            if(this.submitting) return;
            this.submitting = true;

            if(!this.reason){
                this.errorMsg = "Please provide a reason for the change.";
                this.error = true;
                this.submitting = false;
                return;
            }

            if(this.reason.length < 10){
                this.errorMsg = "Please provide a reason that is at least 10 characters long.";
                this.error = true;
                this.submitting = false;
                return;
            }

            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/keeper`, {...this.response, reason: this.reason, hirer: this.hirerToggle, client_id: this.client_id}, {
                headers: authHeader()
            })
                .then(response => {
                    if(response.data.errors){
                        console.log("Has errors");
                        this.errorMsg = "";
                        for(let key in response.data.errors){
                            this.errorMsg += `${response.data.errors}`;
                        }
                        this.error = true;
                        this.submitting = false;
                    }else{
                        this.$success("Saved changes to DVLA response.");
                        this.$emit('changeMade');
                        this.$emit('update');
                        this.load();
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.submitting = false;
                    this.$error("Failed to save changes to DVLA response.", error)
                    if(error.response){
                        if(error.response.status == 422){
                            this.errorMsg = "";
                            for(let key in error.response.data.errors){
                                this.errorMsg += `<br/>${error.response.data.errors}`;
                            }
                            this.error = true;
                        }
                        if(error.response.status == 401){
                            this.errorMsg = error.response.data.message;
                            this.error = true;
                        }
                    }
                });
        }
    },
    computed: {
        changed: function(){
            return !(JSON.stringify(this.response) === JSON.stringify(this.response_original));
        }
    }
}
</script>

<style scoped>

</style>
