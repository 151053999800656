<template>
    <div style="width: 100%;" v-if="src !== null && !error">
        <video style="width: 100%;" :id="id" controls ref="video" :src="src" @load="$emit('load')"></video>
        <div class="form-group">
            <label class="form-label">Playback Speed</label>
            <select class="form-control" @change="handlePlaybackSpeed" v-model="playbackSpeed">
                <option value="0.25">0.25x</option>
                <option value="0.5">0.5x</option>
                <option value="1">1x</option>
                <option value="1.5">1.5x</option>
                <option value="2">2x</option>
            </select>
        </div>
    </div>
    <div v-else-if="error">
        <slot></slot>
    </div>
</template>

<script>
import authHeader from '@/services/auth-header';
export default {
    name: "SecureVideo",
    props: {
        filename: String,
        storage_account: String,
        site_id: String,
        client_id: String,
        id: String,
        endpoint: {
            type: String,
            required: false,
        }
    },
    data(){
        return {
            src: null,
            error: false,
            playbackSpeed: 1
        }
    },
    mounted() {

        this.loadImage();

    },
    unmounted() {
        this.src = null;
    },
    methods: {
          loadImage(){
              let API_ENDPOINT = '/api/claims/evidence/';
              if(this.endpoint !== undefined){
                  if(this.endpoint.length > 0){
                      API_ENDPOINT = this.endpoint;
                  }
              }

              axios.get(`https://api.varsanpr.com${API_ENDPOINT}${this.filename}?site_id=${this.site_id}&client_id=${this.client_id}&storage_account=${this.storage_account || 1}`, {
                  responseType: 'arraybuffer',
                  headers: authHeader()
              })
                  .then(response => {
                    this.src = `data:video/mp4;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
                  })
                  .catch(error => {
                      this.$error(`Failed to download video ${this.filename}`, error);
                      this.error = true;
                  });
          },
          handlePlaybackSpeed(){
              this.$refs["video"].playbackRate = parseFloat(this.playbackSpeed);
          }
    },
    watch: {
        filename: function(val){
            this.loadImage();
        },
        site_id: function(val){
            this.loadImage();
        },
        endpoint: function(val){
            this.loadImage();
        }
    }
}
</script>

<style scoped>
    .missing-image{
        width: 250px;
        height: 250px;
        background-color: #8e8c8c;
    }

    .animated-background {
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background-color: #8e8c8c;
        background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
        background-size: 800px 104px;
        position: relative;
    }

    @keyframes placeHolderShimmer {
        0% {
          background-position: -800px 0
        }
        100% {
          background-position: 800px 0
        }
    }
</style>
