<template>
  <div style="width: 100%; color: white;">

    <h6>{{ tablet.name }}</h6>

    <vue-apex-charts type="line" height="450" :options="options" :series="series"></vue-apex-charts>
  </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import VueApexCharts from 'vue3-apexcharts';

export default {
  name: 'TabletUptime',
  props: {
    tablet: {
      type: Object,
      required: true
    },
    start: {
      type: String,
      required: true
    },
    end: {
      type: String,
      required: true
    }
  },
  components: {
    VueApexCharts
  },
  data(){
    return {
      heartBeats: [],
      parking: [],
      series: [],
      options: {
        tooltip: {
          theme: 'dark'
        },
        chart: {
          height: 450,
          type: 'line',
          zoom: {
            enabled: false
          },
          foreColor: 'white'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Uptime',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
          yaxis: {
            lines: {
              show: false  //or just here to disable only x axis grids
            }
          },  
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              if(value == 0){
                return 'Offline'
              }
              if(value > 0.1 && value < 0.25){
                return 'Uh oh'
              }
              if(value >= 0.25 && value < 0.5){
                return 'Not good'
              }
              if(value >= 0.5 && value < 0.75){
                return 'Getting there'
              }
              if(value >= 0.75 && value < 0.9){
                return 'Almost there'
              }
              if(value > 0.9){
                return 'Online'
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
              type:'vertical',
              colorStops: [{
                  offset: 0,
                  color: '#22c55f',
                  opacity: 1
              }, {
                  offset: 100,
                  color: '#ef4544',
                  opacity: 1
              }]
          }
        }
      },
    }
  },
  mounted(){
    this.loadData();
  },
  methods: {
    loadData(){
      axios.get(`https://api.varsanpr.com/api/tablet/${this.tablet.id}/uptime`, {
        params: {
          start: this.start,
          end: this.end
        },
        headers: authHeader()
      })
      .then(response => {
        this.heartBeats = response.data.data.heartBeats;
        this.populateSeries();
      })
      .catch(error => {
        console.log(error);
        this.$error("An error occurred while fetching the data");
      });
    },
    generateColors(data) {
        return data.map((d, idx) => {
            let color = d.y > 0.1 ? '#22c55f': '#ef4544';

            return {
                offset: idx,
                color,
                opacity: 1
            }
        })
    },
    populateSeries(){
      // The heartbeats run on a 5 minute interval
      // so we need to add a new entry every 5 minutes to the categories and then 
      // match each heartbeat to the correct category
      let categories = [];
      let series = [];

      // Using the start and end dates, we can calculate the number of 5 minute intervals
      // between the two dates
      let start = new Date(this.start);
      let end = new Date(this.end);

      let diff = Math.abs(end - start);

      // 300000 is the number of milliseconds in 5 minutes
      let intervals = Math.ceil(diff / 300000);

      // Create the categories
      let categoryDictionary = {};
      for(let i = 0; i < intervals; i++){
        let date = new Date(start.getTime() + (i * 300000));
        categories.push(date.toLocaleDateString() + ' ' + date.toLocaleTimeString());
        categoryDictionary[date.toLocaleDateString() + ' ' + date.toLocaleTimeString()] = date;
      }

      // Create the series
      // If we have a heartbeat between the current category and the next category, we add a 1 (with a max of 1)
      // If we don't have a heartbeat, we add a 0
      for(let i = 0; i < categories.length - 1; i++){
        let count = 0;
        for(let j = 0; j < this.heartBeats.length; j++){
          let heartbeat = new Date(this.heartBeats[j].created_at);
          if(heartbeat >= new Date(categoryDictionary[categories[i]]) && heartbeat < new Date(categoryDictionary[categories[i + 1]])){
            count = 1;
            break;
          }
        }
        series.push({
          x: categoryDictionary[categories[i]],
          y: count
        });
      }

      let colorStops = this.generateColors(series);
      this.options.fill.gradient.colorStops = colorStops;

      this.options.xaxis.categories = categories;
      this.series = [{
        name: 'Uptime',
        data: series
      }];
    }
  }
}
</script>