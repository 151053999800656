<template>
    <div>
        <span v-if="desc" class="float-left">Min {{min}} Characters. Max {{max}} Characters.</span>
        <span class="float-right"><span v-bind:class="{'tx-success': length > (min - 1), 'tx-danger': length < (min)}">{{length}}</span>/{{max}}</span>
    </div>
</template>

<script>
export default {
    name: "CharacterLimit",
    props: {
        min: {
            type: Number,
            default: 255
        },
        max: {
            type: Number,
            default: 255
        },
        desc: {
            type: Boolean,
            default: true
        },
        text: {
            type: String,
            required: true
        }
    },
    computed: {
        length: function(){
            return this.text.length;
        }
    }
}
</script>

<style scoped>

</style>
