<template>
    <div>

        <div v-show="in_future" class="alert alert-danger" role="alert">
            <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <strong class="d-block d-sm-inline-block-force">Warning!</strong> The incident date and time appears to be in the future.
        </div><!-- alert -->

        <table class="table">
            <thead>
            <tr>
                <th>#</th>
                <th>Reference</th>
                <th>Site</th>
                <th>Reported By</th>
                <th>Incident Timestamp</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{{claim.id}}</td>
                <td><span class="emph">{{claim.reference}}</span></td>
                <td><span class="emph" style="color: indianred" title="'Unique Site Reference'">{{claim.site.reference}}</span> - {{claim.site.name}}</td>
                <td>{{claim.user}}</td>
                <td v-if="claim.type !== 'parking'" @click="openEditDateModal" style="cursor: pointer;">{{created_at}}</td>
                <td v-else>{{created_at}}</td>
                <td>{{claim.status}}</td>
            </tr>
            </tbody>
        </table>

        <div v-if="claim.status === 'Cancelled'" class="pd-x-10">

            <div class="row mg-t-20">
                <p class="tx-white tx-medium tx-16">Reason for Cancellation</p>
                <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 100%;"/>
            </div>

            <p>{{claim.cancellation_reason || "No reason provided."}}</p>

        </div>

        <div class="pd-x-10" style="margin-bottom: 20px;">

            <div v-show="claim.type !== 'parking'" class="row mg-t-20">
                <p class="tx-white tx-medium tx-16">Comments from Site</p>
                <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 100%;"/>
            </div>

            <p v-show="claim.type !== 'parking'">{{claim.comments || "No comments provided."}}</p>

            <div v-show="claim.type === 'parking'" class="row mg-t-20">
                <p class="tx-white tx-medium tx-16">Contravention Type</p>
                <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 100%;"/>
            </div>

            <p v-show="claim.type === 'parking'">{{claim.sub_type}}</p>

            <div v-show="claim.site.brands.length > 0" class="row mg-t-20">
                <p class="tx-white tx-medium tx-16">Site Facilities</p>
                <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 100%;"/>
            </div>

            <div v-for="b in claim.site.brands" :key="b.id" style="display: inline-block;">
                <secure-image style="height: auto; width: 75px; margin-left: 10px;" v-if="b.filename !== undefined && b.filename !== null"
                :title="b.name" :filename="b.filename" :site_id="1" :endpoint="`/api/brands/`"></secure-image>
            </div>
        </div>


        <driveoff-information
            @changetab="(tab) => $emit('changetab', tab)" v-if="claim.type === 'driveoff' || claim.type === 'escalated' || claim.type === 'cnafe'"
            @setstatus="(status) => $emit('setstatus', status)"
            @reloadClaim="$emit('reloadClaim')"
            @pendingLock="(value) => $emit('pendingLock', value)"
            @eventLock="(value) => $emit('eventLock', value)"
            @evidenceLock="(value) => $emit('evidenceLock', value)"
            :claim="claim"
            :client_id="client_id"
            @changeMade="$emit('changeMade')"></driveoff-information>
        <cnaf-information
            @changetab="(tab) => $emit('changetab', tab)" v-if="claim.type === 'cnaf'"
            @setstatus="(status) => $emit('setstatus', status)"
            @reloadClaim="$emit('reloadClaim')"
            @pendingLock="(value) => $emit('pendingLock', value)"
            @eventLock="(value) => $emit('eventLock', value)"
            @evidenceLock="(value) => $emit('evidenceLock', value)"
            :claim="claim"
            :client_id="client_id"
            @changeMade="$emit('changeMade')"
        ></cnaf-information>
        <nmop-information
            @changetab="(tab) => $emit('changetab', tab)" v-if="claim.type === 'nmop'"
            @setstatus="(status) => $emit('setstatus', status)"
            @reloadClaim="$emit('reloadClaim')"
            :claim="claim"
            :client_id="client_id"
            ></nmop-information>
        <parking-information
            @changetab="(tab) => $emit('changetab', tab)" v-if="claim.type === 'parking'"
            @setstatus="(status) => $emit('setstatus', status)"
            @reloadClaim="$emit('reloadClaim')"
            :claim="claim"
            :client_id="client_id"
            @changeMade="$emit('changeMade')"></parking-information>
        <ev-parking-information
            @changetab="(tab) => $emit('changetab', tab)" v-if="claim.type === 'evparking'"
            @setstatus="(status) => $emit('setstatus', status)"
            @reloadClaim="$emit('reloadClaim')"
            :claim="claim"
            :client_id="client_id"
            @changeMade="$emit('changeMade')"></ev-parking-information>

        <edit-modal :id="'editDateModal' + claim.id" title="Edit Date and Time Information">

            <div class="form-layout form-layout-2">
                <div class="row no-gutters">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label">Incident Date: <span class="tx-danger">*</span></label>
                            <input type="date" name="date" v-model="activeDate" class="form-control form-control-dark tx-14" placeholder="dd/mm/yyyy">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label">Incident Time: <span class="tx-danger">*</span></label>
                            <input type="time" name="time" step="1" v-model="activeTime" class="form-control form-control-dark tx-14" placeholder="hh:mm:ss">
                        </div>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Reason for Change: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="text" v-model="reason" placeholder="Explain why you are changing it">
                        </div>
                    </div>
                </div>
                <div class="form-layout-footer bd pd-20 bd-t-0">
                    <submit-button v-show="reason.length > 5" :clicked="dateClicked" :background="'btn btn-info'" :text="'Save Changes'" @click="saveDate"/>
                    <button class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>

        </edit-modal>
    </div>
</template>

<script>
import {DateTime} from 'luxon';
import SecureImage from "@/components/SecureImage";
import EditModal from "@/components/EditModal";
import SubmitButton from "@/components/SubmitButton";
import DriveoffInformation from "@/views/claims/components/driveoff/DriveoffInformation";
import CnafInformation from "@/views/claims/components/cnaf/CnafInformation";
import ParkingInformation from "@/views/claims/components/parking/ParkingInformation";
import EvParkingInformation from "@/views/claims/components/evparking/EvParkingInformation";
import NmopInformation from './nmop/NmopInformation.vue';
import authHeader from '@/services/auth-header';
import axios from 'axios';

export default {
    name: 'ClaimInformation',
    components: {
        SecureImage,
        EditModal,
        SubmitButton,
        DriveoffInformation,
        CnafInformation,
        ParkingInformation,
        EvParkingInformation,
        NmopInformation
    },
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: false
        }
    },
    data(){
        return {
            activeDate: "",
            activeTime: "",
            reason: "",
            dateClicked: false,
            subTypes: {}
        }
    },
    mounted(){
        if(this.claim.type !== "parking"){
            if(this.claim.events[0] !== undefined){
                let temp = DateTime.fromSeconds(parseInt(this.claim.events[0].created_at));
                this.activeDate = temp.toFormat('yyyy-MM-dd');
                this.activeTime = temp.toFormat('HH:mm:ss');
            }else if(this.claim.incident_timestamp !== null){
                let temp = DateTime.fromSeconds(parseInt(this.claim.incident_timestamp));
                this.activeDate = temp.toFormat('yyyy-MM-dd');
                this.activeTime = temp.toFormat('HH:mm:ss');
            }else{
                let temp = DateTime.fromSeconds(parseInt(this.claim.date_received));
                this.activeDate = temp.toFormat('yyyy-MM-dd');
                this.activeTime = temp.toFormat('HH:mm:ss');
            }
        }else{
            let temp = DateTime.fromSeconds(parseInt(this.claim.events[1].created_at));
            this.activeDate = temp.toFormat('yyyy-MM-dd');
            this.activeTime = temp.toFormat('HH:mm:ss');
        }
    },
    methods: {
        openEditDateModal(){
            if(!this.$can('claims-process')) return;
            if(this.claim.type === 'parking' || this.claim.type === 'evparking') return;
            $('#editDateModal' + this.claim.id).modal('toggle');
            $('.select2').select2();
        },
        saveDate(){
            if(!this.$can('claims-process')) return;
            if(this.claim.type === 'parking' || this.claim.type === 'evparking') return;
            if(this.dateClicked) return;
            this.dateClicked = true;
            let timestamp = DateTime.fromFormat(`${this.activeDate} ${this.activeTime}`, 'yyyy-MM-dd HH:mm:ss').toSeconds();

            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/datetime`, {
                client_id: this.client_id,
                timestamp: timestamp,
                reason: this.reason
            }, {
                headers: authHeader()
            })
            .then(response => {
                if(response.data.success){
                    $('#editDateModal' + this.claim.id).modal('toggle');
                    this.$success("Date updated successfully");
                    this.dateClicked = false;
                    this.claim.incident_timestamp = timestamp;
                    this.$emit('changeMade');
                    if(this.claim.status !== "DVLA Review" && this.claim.status !== "Pending"){
                        this.$emit('reloadClaim');
                    }
                }else{
                    throw new Error(response.data.message);
                }
            })
            .catch(error => {
                this.$error(error.response?.data?.message || error.message || error, error);
                this.dateClicked = false;
            });
        },
        loadSubTypes(){
            axios.get(`https://api.varsanpr.com/api/parking/types`, {
                headers: authHeader()
            })
            .then(response => {
                response.data.forEach((type) => {
                    this.subTypes[type.name] = type;
                });
            })
        }
    },
    computed: {
        reference: function(){
            let splitReference = this.claim.reference.split(this.claim.site.reference)[1];
            return `${splitReference.substr(0,4)} ${splitReference.substr(4, 4)}`;
        },
        created_at: function(){
            if(this.claim.type !== 'parking'){
                if(this.claim.events[0] !== undefined){
                    return DateTime.fromSeconds(parseInt(this.claim.events[0].created_at)).toFormat("dd/MM/yyyy HH:mm:ss")
                }
                if(this.claim.incident_timestamp !== null){
                    return DateTime.fromSeconds(parseInt(this.claim.incident_timestamp)).toFormat("dd/MM/yyyy HH:mm:ss")
                }else{
                    return DateTime.fromSeconds(parseInt(this.claim.date_received)).toFormat("dd/MM/yyyy HH:mm:ss")
                }

            }else{
                return DateTime.fromSeconds(parseInt(this.claim.events[1].created_at)).toFormat("dd/MM/yyyy HH:mm:ss")
            }
        },
        in_future: function(){
            if(this.claim.type !== 'parking'){
                if(this.claim.events[0] !== undefined){
                    return DateTime.fromSeconds(parseInt(this.claim.events[0].created_at)).diffNow().milliseconds > 0;
                }
                if(this.claim.incident_timestamp !== null){
                    return DateTime.fromSeconds(parseInt(this.claim.incident_timestamp)).diffNow().milliseconds > 0;
                }else{
                    return DateTime.fromSeconds(parseInt(this.claim.date_received)).diffNow().milliseconds > 0;
                }

            }else{
                return DateTime.fromSeconds(parseInt(this.claim.events[1].created_at)).diffNow().milliseconds > 0;
            }
        },
        subTypeDescription: function(){
            return this.subTypes[this.claim.sub_type]?.description || "No description available";
        }
    }
}
</script>