<template>
    <div style="width: 100%">

        <div class="row">
            <div class="col-6">

                <secure-image v-viewer v-if="vehicle_image !== null" :filename="vehicle_image.filename" :site_id="claim.site.id" :endpoint="'/api/claims/' + claim.id + '/evidence/'" :client_id="client_id" :storage_account="vehicle_image.storage_account" style="max-height: 75vh; width: auto; max-width: 100%;">

                    <p> <i class="fa-regular fa-triangle-exclamation text-danger"></i> There was an issue loading the image.</p>

                </secure-image>

                <div v-else class="alert alert-danger" role="alert">
                    <div class="d-flex align-items-center justify-content-start">
                      <i class="icon ion-ios-checkmark alert-icon tx-32 mg-t-5 mg-xs-t-0"></i>
                      <span><strong>Problem!</strong> The system is unable to detect a valid event image attached to this claim. </span>
                    </div><!-- d-flex -->
                </div><!-- alert -->

            </div>
            <div class="col-6">
                
                <div class="form-group">
                    <label for="registration">Registration</label>
                    <input type="text" class="form-control" id="registration" v-model="registration" placeholder="Enter registration">
                </div>

                <button v-if="result.make == null" class="btn btn-primary" @click="tryRegistration">Try</button>
                <button v-else class="btn btn-success" @click="addAttempt">Add Attempt</button>

                <p>If you're happy with the result, please go to the manage tab and change the vehicle registration.</p>

                <table v-if="result.make !== null" class="table mg-t-20">
                    <thead class="bg-primary text-white">
                        <tr>
                            <th>Make</th>
                            <th>Colour</th>
                            <th>Fuel Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ result.make }}</td>
                            <td>{{ result.colour }}</td>
                            <td>{{ result.fuelType }}</td>
                        </tr>
                    </tbody>
                </table>

                <table class="table mg-t-20">
                    <thead class="bg-primary text-white">
                        <tr>
                            <th>Registration</th>
                            <th>Time</th>
                            <th>User</th>
                            <th>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="attempt in attempts">
                            <td>{{ attempt.registration }}</td>
                            <td>{{ attempt.created_at }}</td>
                            <td>{{ attempt.user.email }}</td>
                            <td>{{ attempt.result }}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>

    </div>
</template>
<script>
import axios from 'axios';
import { DateTime } from 'luxon';
import { directive as viewer } from "v-viewer"
import SecureImage from '@/components/SecureImage';
import authHeader from '@/services/auth-header';

export default {
    name: 'TamperedPlate',
    directives: {
        viewer: viewer({
            debug: true,
        }),
    },
    components: {
        SecureImage
    },
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: false
        }
    },
    data(){
        return {
            attempts: [],
            registration: "",
            result: {
                make: null,
                colour: null,
                fuelType: null
            }
        }
    },
    mounted(){
        this.loadAttempts();
    },
    methods: {
        loadAttempts(){
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/tamperedplate?client_id=${this.client_id}`, {
                headers: authHeader()
            })
                .then(response => {
                    response.data.plates = response.data.plates.sort((a, b) => {
                        return b.created_at - a.created_at;
                    });
                    response.data.plates.forEach((attempt) => {
                        this.attempts.push({
                            registration: attempt.registration,
                            created_at: DateTime.fromSeconds(attempt.created_at).toFormat('dd/MM/yyyy HH:mm'),
                            user: {
                                email: attempt.user.email,
                            },
                            result: ''
                        });
                    })
                })
                .catch(error => {
                    console.log(error)
                    this.$error('Failed to load attempts');
                });
        },
        tryRegistration(){

            axios.get(`https://api.varsanpr.com/api/vehicle/external/dvla/${this.registration}`, {
                headers: authHeader()
            })
                .then(response => {
                    console.log(response.data.vehicle);
                    this.result.make = response.data.vehicle.make;
                    this.result.colour = response.data.vehicle.colour;
                    this.result.fuelType = response.data.vehicle.fuelType;
                    this.$emit('changeMade');
                })
                .catch(error => {
                    console.log(error)
                    this.$error(error.response?.data?.message || error?.message || error, error);
                    this.result.make = "";
                    this.result.colour = "";
                    this.result.fuelType = "";
                });

        },
        addAttempt(){
            axios.put(`https://api.varsanpr.com/api/claims/${this.claim.id}/tamperedplate`, {
                registration: this.registration,
                client_id: this.client_id
            }, {
                headers: authHeader()
            }).then(res => {
                console.log(res);
                this.$success('Attempt added');
                this.attempts.push({
                    registration: this.registration,
                    created_at: DateTime.local().toFormat('dd/MM/yyyy HH:mm'),
                    user: {
                        email: this.$store.state.auth.user.email,
                    },
                    result: `${this.result.make} ${this.result.colour} ${this.result.fuelType}`
                })
                this.registration = '';
                this.result = {
                    make: null,
                    colour: null,
                    fuelType: null
                };
                this.$emit('changeMade');
            }).catch(err => {
                console.log(err);
                this.$error('Failed to add attempt');
            });
        }
    },
    computed: {
        vehicle_image: function(){
            return this.claim.assets.filter((asset) => {
                return asset.type == 'vehicle_image'
            })[0] || null;
        }
    }
}
</script>