<template>
    <div>
        <div v-show="!searching && currentAppeal == null">
            <table width="100%" class="table table-striped table-hover tx-white">
                <thead class="thead-colored thead-primary">
                <tr>
                    <th>#</th>
                    <th>Message</th>
                    <th>Received</th>
                    <th>Status</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="a in appeals" @click="selectAppeal(a)" style="cursor: pointer">
                        <th>{{a.id}}</th>
                        <td>{{a.message.substr(0, 80)}}...</td>
                        <td>{{a.created_at}}</td>
                        <td :class="{
                            'text-info': a.status.toUpperCase() == 'PENDING',
                            'text-warning': a.status.toUpperCase() == 'REVIEWING',
                            'text-success': a.status.toUpperCase() == 'ACCEPTED',
                            'text-danger': a.status.toUpperCase() == 'REJECTED'
                        }">
                            {{a.status.toUpperCase()}}
                        </td>
                        <td></td>
                    </tr>
                    <tr v-if="appeals.length < 1 && !searching">
                        <td colspan="5" class="text-center">No Appeals</td>
                    </tr>
                    <tr v-if="searching">
                        <td colspan="5" class="text-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <claim-appeal v-if="currentAppeal != null" :appeal="currentAppeal" :claim="claim" @back="closeAppeal()" :client_id="client_id" @changeMade="$emit('changeMade')"></claim-appeal>

    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import { DateTime } from 'luxon';
import ClaimAppeal from "./ClaimAppeal";

export default {
    name: 'ClaimAppeals',
    components: {ClaimAppeal},
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
            appeals: [],
            currentAppeal: null,
            searching: true
        }
    },
    mounted(){
        this.getAppeals();
    },
    methods: {
        getAppeals(){
            this.searching = true;
            axios.get(`https://api.varsanpr.com/api/appeals?claim_id=${this.claim.id}&client_id=${this.client_id}`, {
                headers: authHeader()
            })
                .then(res => {
                    res.data.appeals.forEach((appeal) => {
                        appeal.created_at = DateTime.fromSeconds(appeal.created_at).toFormat('LLL dd, yyyy hh:mm a');
                    })
                    this.appeals = res.data.appeals;
                    this.$emit('appealcount', this.appeals.length);
                })
                .catch(err => {
                    this.$error(err.response.data.message, err);
                })
                .finally(() => {
                    this.searching = false;
                });
        },
        selectAppeal(appeal){
            this.currentAppeal = appeal;
        },
        closeAppeal(){
            this.currentAppeal = null;
        }
    }
}
</script>