<template>
    <button @click="$emit('click')" class="btn" v-bind:class="background" v-bind:disabled="clicked">
        <span v-if="!clicked">{{text}}</span>
        <div v-else class="sk-wave tx-white float-left" style="margin: 0;">
            <div class="sk-rect sk-rect1 bg-white"></div>
            <div class="sk-rect sk-rect2 bg-white"></div>
            <div class="sk-rect sk-rect3 bg-white"></div>
            <div class="sk-rect sk-rect4 bg-white"></div>
            <div class="sk-rect sk-rect5 bg-white"></div>
        </div>
    </button>
</template>

<script>
export default {
name: "SubmitButton",
    props: {
        background: String,
        text: String,
        clicked: Boolean,
    }
}
</script>

<style scoped>

</style>
