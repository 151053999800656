<template>
  <div class="wd-100p jobs-container">
    <table class="table">
      <thead>
        <tr>
          <th>Job ID</th>
          <th>Type</th>
          <th>Created At</th>
          <th>Status</th>
          <th>ClearSend Date</th>
          <th>Last Updated</th>
        </tr>
      </thead>
      <tbody v-if="loaded">
        <tr v-for="job in jobs" :key="job.id">
            <td class="wd-5p">{{job.id}}</td>
            <td class="wd-10p">{{job.type}}</td>
            <td>{{job.created_at_formatted}}</td>
            <td>{{ job.status || "Awaiting Approval" }}</td>
            <td>{{ job.clearsend_date || "Not Received" }}</td>
            <td>{{job.status_updated_at_formatted}}</td>
        </tr>
        <tr v-if="jobs.length < 1">
            <td class="wd-100p">No mail jobs available for this claim</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="text-center" colspan="5">Loading</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
import authHeader from '../../../services/auth-header';

export default {
  name: 'ClaimMailJobs',
  props: {
    claim: {
      type: Object,
      required: true
    },
    client_id: {
      type: [Number, String],
      required: true
    }
  },  
  data(){
    return {
      jobs: [],
      loaded: false
    }
  },
  mounted(){
    this.loadJobs();
  },
  methods: {
    loadJobs(){
      axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/mailjobs`, {
        headers: authHeader(),
        params: {
          client_id: this.client_id
        }
      })
      .then(response => {
        let jobs = response.data.jobs;
        jobs.forEach(job => {
          job.created_at_formatted = new Date(job.created_at).toLocaleString();
          job.status_updated_at_formatted = new Date(job.status_updated_at || job.created_at).toLocaleString();
          if(job.clearsend_date){
            job.clearsend_date = new Date(job.clearsend_date).toLocaleString();
          }
        });

        this.jobs = jobs;
      })
      .catch(error => {
        console.error(error);
        this.$error('Failed to load mail jobs');
      })
      .finally(() => {
        this.loaded = true;
      });
    }
  }
}
</script>