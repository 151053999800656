<template>
    <div class="row">
        <div class="col-12">

            <div class="card">
                <div class="card-header tx-white">
                    Claim Vehicle <button class="btn btn-sm btn-danger ft-right" @click="back">Back</button>
                </div>
                <div v-if="loading" class="card-body">
                    <loader :show="true"></loader>
                </div>
                <div v-else class="card-body">
                    <p><strong class="tx-danger">WARNING!</strong> By changing the vehicle that the claim is for, you are also changing the possible recipient of the claim so it is therefore
                        important that you only change the vehicle if you can justify why you are doing so.</p>
                    <p>Example <strong>valid</strong> reasons: </p>
                    <ul>
                        <li>Camera misread which has been identified during claim review</li>
                        <li>Plate has been tampered with i.e tape to modify a character</li>
                        <li>Wrong plate used i.e front plate was fake however the rear plate was correct and the front plate was used to create the claim</li>
                    </ul>

                    <p class="tx-bold"><strong class="tx-danger">WARNING!</strong> If this claim has already been submitted to the DVLA, it will be reset back to Generate the Enquiry. Any existing
                        responses from the DVLA must be destroyed immediately. Any existing responses stored within the system will be deleted upon updating the vehicle. <span class="tx-danger">This process cannot be reversed</span>.</p>

                    <p><strong>Current Vehicle Registration: </strong> {{vehicle.plate}}</p>
                    <p><strong>Current Vehicle Make, Model and Colour: </strong> {{vehicle.make}} {{vehicle.model}} {{vehicle.colour}}</p>

                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">New Vehicle Registration <span class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <input type="text" name="plate" v-model="registration" class="form-control form-control-dark">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label">Change Justification <span class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <textarea cols="5" rows="5" maxlength="255" v-model="justification" class="form-control form-control-dark"></textarea>
                            <character-limit
                                :min="20"
                                :max="255"
                                :desc="true"
                                :text="justification"></character-limit>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3 col-form-label"></label>
                        <div class="col-md-9">
                            <label class="ckbox">
                                <input v-model="remove" type="checkbox">
                                <span>Remove old vehicle from blacklist?</span>
                            </label>
                        </div>
                    </div>

                    <submit-button v-bind:class="{'disabled': !readyToSubmit}" @click="saveChanges" :clicked="submitting" :background="'btn-success'" :text="'Save Changes'"></submit-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CharacterLimit from "@/components/CharacterLimit";
import Loader from "@/components/Loader";
import SubmitButton from "@/components/SubmitButton";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "ManageVehicle",
    components: {CharacterLimit, Loader, SubmitButton},
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: Number,
            required: true
        }
    },
    data(){
        return {
            vehicle: {
                plate: "",
                make: "",
                model: "",
                colour: ""
            },
            registration: "",
            justification: "",
            remove: false,
            loading: true,
            submitting: false,
        }
    },
    mounted(){
        this.load()
    },
    methods: {
        back(){
            this.$emit('close');
        },
        load(){
            this.vehicle = this.claim.vehicle;
            this.vehicle.make = this.claim.make;
            this.vehicle.model = this.claim.model;
            this.vehicle.colour = this.claim.colour;
            this.loading = false;
        },
        saveChanges() {
            if(!this.readyToSubmit || this.submitting) return;

            if(this.registration === this.vehicle.plate){
                this.$error("The new vehicle registration cannot be the same as the current vehicle registration.");
                return;
            }

            this.submitting = true;
            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/vehicle`, {
                registration: this.registration,
                reason: this.justification,
                client_id: this.client_id,
                remove: this.remove
            }, {
                headers: authHeader()
            })
                .then(response => {
                    if(response.data.success !== true){
                        throw new Error("");
                    }
                    this.submitting = false;
                    this.$emit('changeMade');
                    this.$success("Successfully updated claim vehicle.");
                    this.$emit('update');
                })
                .catch(error => {
                    this.$error("Failed to update the claim vehicle. Please contact a system admin if this persists.", error);
                    this.submitting = false;
                });
        }
    },
    computed: {
        justificationLength: function(){
            return this.justification.length;
        },
        readyToSubmit: function(){
            return this.registration.length > 0 && this.registration.length < 10 && this.justificationLength > 20
        }
    }
}
</script>

<style scoped>

</style>
