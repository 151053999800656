<template>
    <div class="row">
        <div class="col-12">
            <label class="br-section-label mg-t-0-force">Claim Settings: </label>
            <div class="form-group">
                <label>Dashboard Pin</label>
                <div class="input-group">
                    <input type="text" required="" id="key" name="pin" v-model="site.pin" class="form-control form-control-dark d-inline-block float-left">
                    <div class="input-group-append">
                        <button class="btn btn-primary d-inline-block float-right" @click="generatePin">Generate</button>
                    </div>
                </div>
            </div>

            <label class="br-section-label mg-t-0-force">Tablet Settings: </label>

            <div class="form-group">
                <label class="form-control-label">Lite Only:</label><br/>
                <div @click="toggleLite" class="br-toggle br-toggle-rounded br-toggle-success" v-bind:class="{'on': lite_only}">
                    <div class="br-toggle-switch"></div>
                </div>
            </div>

            <div class="form-group">
                <label class="form-control-label">Drive Off:</label><br/>
                <div @click="toggleHandler('driveoff')" class="br-toggle br-toggle-rounded br-toggle-success" v-bind:class="{'on': driveoff_enabled}">
                    <div class="br-toggle-switch"></div>
                </div>
            </div>

            <div class="form-group">
                <label class="form-control-label">No Means of Payment:</label><br/>
                <div @click="toggleHandler('nmop')" class="br-toggle br-toggle-rounded br-toggle-success" v-bind:class="{'on': nmop_enabled}">
                    <div class="br-toggle-switch"></div>
                </div>
            </div>

            <div class="form-group">
                <label class="form-control-label">Staff Parking:</label><br/>
                <div @click="toggleHandler('staff_parking')" class="br-toggle br-toggle-rounded br-toggle-success" v-bind:class="{'on': staff_parking_enabled}">
                    <div class="br-toggle-switch"></div>
                </div>
            </div>

            <div class="form-group">
                <label class="form-control-label">Vehicle History:</label><br/>
                <div @click="toggleHandler('vehicle_history')" class="br-toggle br-toggle-rounded br-toggle-success" v-bind:class="{'on': vehicle_history_enabled}">
                    <div class="br-toggle-switch"></div>
                </div>
            </div>

            <div class="form-group">
                <label class="form-control-label">Customer Parking:</label><br/>
                <div @click="toggleHandler('customer_parking')" class="br-toggle br-toggle-rounded br-toggle-success" v-bind:class="{'on': customer_parking_enabled}">
                    <div class="br-toggle-switch"></div>
                </div>
            </div>

            <div class="form-group">
                <label class="form-control-label">Sign In:</label><br/>
                <div @click="toggleHandler('signin')" class="br-toggle br-toggle-rounded br-toggle-success" v-bind:class="{'on': signin_enabled}">
                    <div class="br-toggle-switch"></div>
                </div>
            </div>

            <div class="form-group">
                <label class="form-control-label">Evidence:</label><br/>
                <div @click="toggleHandler('evidence')" class="br-toggle br-toggle-rounded br-toggle-success" v-bind:class="{'on': evidence_enabled}">
                    <div class="br-toggle-switch"></div>
                </div>
            </div>

            <div class="form-group">
                <label class="form-control-label">Logout:</label><br/>
                <div @click="toggleHandler('logout')" class="br-toggle br-toggle-rounded br-toggle-success" v-bind:class="{'on': logout_enabled}">
                    <div class="br-toggle-switch"></div>
                </div>
            </div>

            <label class="br-section-label mg-t-0-force">Claim Settings: </label>

            <div class="form-group">
                <label class="form-control-label">Claims:</label><br/>
                <div @click="toggleClaims" class="br-toggle br-toggle-rounded br-toggle-success" v-bind:class="{'on': claims_enabled}">
                    <div class="br-toggle-switch"></div>
                </div>
            </div>

            <label class="br-section-label mg-t-0-force">Parking Settings: </label>

            <div class="form-group">
                <label>Parking Charge Percentage</label>
                <input type="text" id="rangeslider4" name="parking_perc_fines" v-model="site.parking_perc_fines" class="form-control form-control-dark">
            </div>
            <div class="form-group">
                <label>Parking Rates Percentage</label>
                <input type="text" id="rangeslider3" name="parking_perc_rates" v-model="site.parking_perc_rates" class="form-control form-control-dark">
            </div>
            <div class="form-group">
                <label class="form-control-label">Maximum Stay:</label>
                <input type="text" id="rangeslider1" name="max_stay" v-model="site.max_stay" class="form-control form-control-dark">
            </div>

            <div class="form-group">
                <label class="form-control-label">Grace Period:</label>
                <input type="text" id="rangeslider5" name="parking_grace_period" v-model="site.parking_grace_period" class="form-control form-control-dark">
            </div>

            <div class="form-group">
                <label class="form-control-label">No Return Period:</label>
                <input type="text" id="rangeslider2" name="no_return" v-model="site.no_return" class="form-control form-control-dark">
            </div>

            <div class="form-group">
                <label class="form-control-label">Parking:</label><br/>
                <div @click="toggleParking" class="br-toggle br-toggle-rounded br-toggle-success" v-bind:class="{'on': parking_enabled}">
                    <div class="br-toggle-switch"></div>
                </div>
            </div>

            <button class="btn btn-success" v-if="siteChange" @click="saveChanges">Save Changes</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "SiteClaimSettings",
    props: {
        site: Object,
        siteChange: Boolean
    },
    methods: {
        saveChanges(){
            axios.patch(`https://api.varsanpr.com/api/sites/${this.site.id}`, this.site, {
                headers: authHeader()
            })
                .then(response => {
                    this.$emit('update');
                    this.$success("Updated site details");
                })
                .catch(error => {
                    this.$error("Failed saving changes to site.", error);
                })
        },
        generatePin(){
            this.site.pin = '';
        },
        toggleParking(){
            this.site.parking_enabled = !this.site.parking_enabled;
        },
        toggleLite(){
            this.site.lite_only = !this.site.lite_only;
        },
        toggleClaims(){
            this.site.claims_enabled = !this.site.claims_enabled;
        },
        toggleHandler(obj){
            this.site[obj] = this.yesNo(this.site[obj]);
        },
        yesNo(v){
            if(v === 'No'){
                return 'Yes';
            }
            return 'No';
        }
    },
    mounted(){
        $('#rangeslider1').ionRangeSlider({
            min: 5,
            max: 1440,
            from: this.site.max_stay,
            step: 1,
            grid: true,
            prettify: function(value){
                if(value < 60){
                    return "00:"+value.toString().padEnd(2, "0");
                }else{
                    let hours = Math.floor(value / 60);
                    let temp = value - (hours * 60);
                    return hours.toString().padStart(2, "0") + ":" + temp.toString().padStart(2, "0");
                }
            },
            onChange: (data) => {
                this.site.max_stay = data.from;
            }
        });
        $('#rangeslider2').ionRangeSlider({
            min: 5,
            max: 1440,
            from: this.site.no_return,
            step: 1,
            grid: true,
            prettify: function(value){
                if(value < 60){
                    return "00:"+value.toString().padEnd(2, "0");
                }else{
                    let hours = Math.floor(value / 60);
                    let temp = value - (hours * 60);
                    return hours.toString().padStart(2, "0") + ":" + temp.toString().padStart(2, "0");
                }
            },
            onChange: (data) => {
                this.site.no_return = data.from;
            }
        });
        $('#rangeslider3').ionRangeSlider({
            min: 0,
            max: 1,
            from: this.site.parking_perc_rates,
            step: 0.01,
            grid: true,
            onChange: (data) => {
                this.site.parking_perc_rates = data.from;
            }
        });
        $('#rangeslider4').ionRangeSlider({
            min: 0,
            max: 1,
            from: this.site.parking_perc_fines,
            step: 0.01,
            grid: true,
            onChange: (data) => {
                this.site.parking_perc_fines = data.from;
            }
        });
        $('#rangeslider5').ionRangeSlider({
            min: 10,
            max: 1440,
            from: this.site.parking_grace_period,
            step: 1,
            grid: true,
            prettify: function(value){
                if(value < 60){
                    return "00:"+value.toString().padEnd(2, "0");
                }else{
                    let hours = Math.floor(value / 60);
                    let temp = value - (hours * 60);
                    return hours.toString().padStart(2, "0") + ":" + temp.toString().padStart(2, "0");
                }
            },
            onChange: (data) => {
                this.site.parking_grace_period = data.from;
            }
        });
        $('.select2').select2();
    },
    computed: {
        parking_enabled: function(){
            return this.site.parking_enabled;
        },
        claims_enabled: function(){
            return this.site.claims_enabled;
        },
        fuel_enabled: function(){
            this.checklistErrors.claims = [];
            if(!this.site.claims_enabled){
                this.checklistErrors.claims.push("Claims are currently disabled.");
            }
            if(this.sources.dvla_auth.length < 1){
                this.checklistErrors.claims.push("No DVLA Authorisation has been uploaded.");
            }
            if(this.sources.petroleum_cert.length < 1){
                this.checklistErrors.claims.push("No Petroleum Certificate has been uploaded.");
            }
            return (this.site.claims_enabled && this.sources.dvla_auth.length > 0 && this.sources.petroleum_cert.length > 0);
        },
        driveoff_enabled: function(){
            return this.site.driveoff;
        },
        nmop_enabled: function(){
            return this.site.nmop;
        },
        staff_parking_enabled: function(){
            return this.site.staff_parking;
        },
        vehicle_history_enabled: function(){
            return this.site.vehicle_history;
        },
        customer_parking_enabled: function(){
            return this.site.customer_parking;
        },
        signin_enabled: function(){
            return this.site.signin;
        },
        evidence_enabled: function(){
            return this.site.evidence;
        },
        logout_enabled: function(){
            return this.site.logout;
        },
        lite_only: function(){
            return this.site.lite_only;
        }
    }
}
</script>

<style scoped>

</style>
