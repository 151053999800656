<template>
    <div class="pd-x-10">

        <div v-if="claim.status !== 'Pending'" class="row d-flex justify-content-center flex-wrap">

            <table class="table" style="max-width: 800px;">
                <thead class="bg-primary">
                    <tr>
                        <th style="color: white !important;">DESCRIPTION</th>
                        <th style="color: white !important;">VALUE</th>
                        <th style="color: white !important;">VAT?</th>
                        <th style="color: white !important;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{claim.litres}} litres of {{claim.fuel}} (Pump {{claim.pump}})</td>
                        <td>£{{claim.value}}</td>
                        <td><i class="fa-solid fa-x text-danger"></i></td>
                        <td><button class="btn btn-sm btn-primary" @click="openEditFuelModal"><i class="fa-solid fa-pen-to-square"></i></button></td>
                    </tr>
                    <tr v-if="claim.fuel_second !== null">
                        <td>{{claim.litres_second}} of {{claim.fuel_second.toUpperCase()}} (Pump {{claim.pump_second || 0}})</td>
                        <td>£{{claim.value_second}}</td>
                        <td><i class="fa-solid fa-x text-danger"></i></td>
                        <td><button class="btn btn-sm btn-primary" @click="openEditSecondFuelModal"><i class="fa-solid fa-pen-to-square"></i></button></td>
                    </tr>
                    <tr v-else>
                        <td colspan="3"></td>
                        <td><button class="btn btn-sm btn-success" @click="openEditSecondFuelModal"><i class="fa-solid fa-plus"></i></button></td>
                    </tr>
                    <tr>
                        <td colspan="2"></td>
                        <td><strong>Sub-Total:</strong></td>
                        <td>£{{(parseFloat(claim.value) + parseFloat(claim.value_second || 0))}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"></td>
                        <td><strong>Less Payments:</strong></td>
                        <td>£{{currentPayments}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"></td>
                        <td><strong>Total:</strong></td>
                        <td>£{{currentlyDue}}</td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div v-if="$can('claims-process')" class="row mg-t-20">
            <p class="tx-white tx-medium tx-16">Current Actions</p>
            <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 100%;"/>
        </div>

        <div v-if="$can('claims-process')" class="row">
            <component :is="statusToComponent() || 'NoActions'"
            @changetab="(tab) => $emit('changetab', tab)"
                @reload="$emit('reloadClaim')"
                @setstatus="(status) => $emit('setstatus', status)"
                @pendingLock="(value) => $emit('pendingLock', value)"
                @eventLock="(value) => $emit('eventLock', value)"
                @evidenceLock="(value) => $emit('evidenceLock', value)"
                :claim="claim"
                :client_id="client_id"
                @changeMade="$emit('changeMade')"></component>
        </div>

        <edit-modal v-if="claim.status !== 'Pending'" :id="'editFuelModal' + claim.id" title="Edit Fuel Information">

            <div class="form-layout form-layout-2">
                <div class="row no-gutters">
                    <div class="col-md-3">
                        <div class="form-group mg-md-l--1">
                            <label class="form-control-label mg-b-0-force">Fuel Type: <span class="tx-danger">*</span></label>
                            <select class="form-control select2 select2-hidden-accessible" v-model="claim.fuel" data-placeholder="Choose fuel type" v-select2>
                                <option v-for="type in types" :value="type">{{type.toUpperCase()}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="form-control-label">Value (£): <span class="tx-danger">*</span></label>
                            <input class="form-control" type="number" step="0.01" v-model="claim.value" placeholder="Fuel value">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="form-control-label">Litres: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="number" step="0.01" v-model="claim.litres" placeholder="Litres dispensed">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="form-control-label">Pump Number: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="number" min="1" max="20" v-model="claim.pump" placeholder="Pump number">
                        </div>
                    </div>
                </div><!-- row -->
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Reason for Change: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="text" v-model="reason" placeholder="Explain why you are changing it">
                        </div>
                    </div>
                </div>
                <div class="form-layout-footer bd pd-20 bd-t-0">
                    <submit-button v-show="reason.length > 5" :clicked="fuelClicked" :background="'btn btn-info'" :text="'Save Changes'" @click="saveFuel"/>
                    <button class="btn btn-secondary" :id="'closeEditFuelModal' + claim.id" data-bs-dismiss="modal">Cancel</button>
                </div><!-- form-group -->
            </div>

        </edit-modal>

        <edit-modal v-if="claim.status !== 'Pending'" :id="'editSecondFuelModal' + claim.id" title="Edit Fuel Information">

            <div class="form-layout form-layout-2">
                <div class="row no-gutters">
                    <div class="col-md-3">
                        <div class="form-group mg-md-l--1">
                            <label class="form-control-label mg-b-0-force">Fuel Type: <span class="tx-danger">*</span></label>
                            <select class="form-control select2 select2-hidden-accessible" v-model="claim.fuel_second" data-placeholder="Choose fuel type" v-select2>
                                <option v-for="type in types" :value="type">{{type.toUpperCase()}}</option>
                                <option :value="'REMOVE'">REMOVE</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="form-control-label">Value (£): <span class="tx-danger">*</span></label>
                            <input class="form-control" type="number" step="0.01" v-model="claim.value_second" placeholder="Fuel value">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="form-control-label">Litres: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="number" step="0.01" v-model="claim.litres_second" placeholder="Litres dispensed">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="form-control-label">Pump Number: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="number" min="1" max="20" v-model="claim.pump_second" placeholder="Pump number">
                        </div>
                    </div>
                </div><!-- row -->
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Reason for Change: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="text" v-model="reason" placeholder="Explain why you are changing it">
                        </div>
                    </div>
                </div>
                <div class="form-layout-footer bd pd-20 bd-t-0">
                    <submit-button v-show="reason.length > 5" :clicked="fuelClicked" :background="'btn btn-info'" :text="'Save Changes'" @click="saveFuelSecond" />
                    <button class="btn btn-secondary" :id="'closeEditSecondFuelModal' + claim.id" data-bs-dismiss="modal">Cancel</button>
                </div><!-- form-group -->
            </div>

        </edit-modal>

    </div>
</template>

<script>
import {DateTime} from 'luxon';
import axios from 'axios';
import authHeader from '@/services/auth-header';
import EditModal from "@/components/EditModal";
import SubmitButton from "@/components/SubmitButton";
import DriveoffPending from '../driveoff/steps/DriveoffPending';
import CnafDvlaReview from './steps/CnafDvlaReview';
import CnafPostFailed from './steps/CnafPostFailed';
import TamperedPlate from '../driveoff/steps/TamperedPlate';
import NoActions from '../NoActions';

export default {
    name: 'CnafInformation',
    components: {
        EditModal,
        SubmitButton,
        DriveoffPending,
        CnafDvlaReview,
        CnafPostFailed,
        TamperedPlate,
        NoActions
    },
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: String,
            required: true
        }
    },
    data(){
        return {
            dateDue: null,
            daysUntilEscalation: null,
            types: [
                'Petrol',
                'Diesel',
                'AdBlue',
                'Liquid Petroleum Gas (LPG)',
                'Red Diesel'
            ],
            fuelClicked: false,
            fuelSecondClicked: false,
            reason: ""
        }
    },
    mounted(){
        if(this.claim.dates.date_letter_sent !== null){
            let datePosted = DateTime.fromFormat(this.claim.dates.date_letter_sent, 'dd/MM/yyyy');
            let dueDate = datePosted.plus({days: 14});
            this.daysUntilEscalation = parseFloat(Math.abs(DateTime.local().diff(dueDate, 'days').toObject().days)).toFixed(0);
            this.dateDue = dueDate;
        }
    },
    methods: {
        statusToComponent(){
            if(this.claim.status === 'Pending'){
                if(this.claim.progress_message === 'Tampered Plate'){
                    return 'TamperedPlate';
                }
                return 'DriveoffPending';
            }else{
                return {
                    'Post Failed': 'CnafPostFailed',
                    'DVLA Review': 'CnafDvlaReview',
                }[this.claim.status];
            }
        },
        openEditFuelModal(){
            if(!this.$can('claims-process')) return;
            $('#editFuelModal' + this.claim.id).modal('toggle');
            $('.select2').select2();
        },
        openEditSecondFuelModal(){
            if(!this.$can('claims-process')) return;
            $('#editSecondFuelModal' + this.claim.id).modal('toggle');
            $('.select2').select2();
        },
        saveFuel(){
            this.fuelClicked = true;
            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/fuels`, {
                client_id: this.client_id,
                ignore_second: true,
                fuel: this.claim.fuel,
                value: this.claim.value,
                litres: this.claim.litres,
                pump: this.claim.pump,
                reason: this.reason
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$success("Successfully updated claim details");
                this.$emit('changeMade');
                this.$emit('reloadClaim');
                this.fuelClicked = false;
                $('#closeEditFuelModal' + this.claim.id).click();
            })
            .catch(error => {
                this.$error("Failed to update claim details", error);
                this.fuelClicked = false;
            })
        },
        saveFuelSecond(){
            this.fuelClicked = true;
            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/fuels`, {
                client_id: this.client_id,
                ignore_second: false,
                fuel: this.claim.fuel,
                value: this.claim.value,
                litres: this.claim.litres,
                pump: this.claim.pump,
                fuel_second: this.claim.fuel_second,
                value_second: this.claim.value_second,
                litres_second: this.claim.litres_second,
                pump_second: this.claim.pump_second,
                reason: this.reason
            }, {
                headers: authHeader()
            })
                .then(response => {
                    this.$success("Successfully updated claim details");
                    this.$emit('changeMade');
                    this.$emit('reloadClaim');
                    this.fuelClicked = false;
                    $('#closeEditSecondFuelModal' + this.claim.id).click();
                })
                .catch(error => {
                    this.$error("Failed to update claim details", error);
                    this.fuelClicked = false;
                })
        }
    },
    computed: {
        currentlyDue: function(){
            let cost = 0;

            let amountToCollect = parseFloat(cost) + parseFloat(parseFloat(this.claim.value).toFixed(2));

            if(this.claim.fuel_second !== null){
                amountToCollect += parseFloat(parseFloat(this.claim.value_second).toFixed(2));
            }

            if(this.claim.payments.length > 0){
                this.claim.payments.forEach((payment) => {
                    amountToCollect = parseFloat(amountToCollect) - parseFloat(payment.amount);
                });
                this.claim.deductions.forEach((deduction) => {
                    amountToCollect = parseFloat(amountToCollect) + parseFloat(deduction.amount);
                });
            }

            this.claim.amountToCollect = parseFloat(amountToCollect).toFixed(2);

            return parseFloat(amountToCollect).toFixed(2);
        },
        currentPayments: function (){
            let paid = 0.00;
            this.claim.payments.forEach((p) => {
                paid += parseFloat(p.amount);
            });
            this.claim.deductions.forEach((d) => {
                paid -= parseFloat(d.amount);
            });
            return parseFloat(paid).toFixed(2);
        }
    }
}
</script>

<style scoped>
    .emph:hover{
        background-color: black;
        padding: 5px 10px;
        border-radius: 3px;
        font-size: 16px;
    }
    .receipt-footer {
        background-color: #f64747;
        text-align: center;
        min-height: 40px;
        max-height: 40px;
        height: 40px;
        line-height: 40px;
        color: white;
        font-size: 20px;
    }
    .receipt-footer.receipt-fee {
        background-color: #2abb9b
    }
    .receipt-body{
        flex-grow: 1;
        color: black;
        padding-left: 10px;
        padding-right: 10px;
    }
    .receipt-header{
        font-size: 20px;
        font-weight: bold;
        color: #0c0c0d;
        margin: 10px;
        text-align: center;
    }
    .receipt {
        width: 250px;
        background-color: #f5f6f8;

        max-height: 650px;
        display: flex;
        flex-direction: column;
        margin: 20px;
    }
    .subtotal {
        width: 200px;
        height: 40px;
        line-height: 40px;
        background-color: #68b7a1;
        font-size: 20px;
        font-width: bold;
        text-align: center;
        color: white;
    }
    .mermaidTooltip {
        color: white;
        font-size: 14px;
        padding: 5px;
    }
</style>
