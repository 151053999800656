<template>
    <div class="col-12">

        <p class="tx-white tx-bold">This claim needs to be reviewed before it can be sent to debt collection </p>

        <div v-for="key in detailKeys" class="form-group row">
            <label class="col-md-4 col-form-label">{{ key.replace('_', ' ') }} <span v-if="!['Tel_No', 'Email'].includes(key)" class="text-danger">*</span></label>
            <div class="col-md-8">
                <input type="text" :disabled="(['Ref', 'Address_Line1', 'Address_Line2', 'Address_Line3', 'Address_Line4', 'Post_Code'].includes(key) || verified)" :name="key" v-model="details[key]" class="form-control form-control-dark">
            </div>
        </div>

        <button v-show="!verified" class="btn btn-sm btn-success" @click="send(true)">Verify</button>
        <button v-show="!verified" class="btn btn-sm btn-primary" @click="send(false, true)">Update</button>
        <button v-show="(verified && sent_at == null)" @click="send(false)" class="btn btn-sm btn-danger">Unverify</button>

        <p v-if="(verified && verified_by?.email)">Verified by {{verified_by.email}}</p>

    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "DriveOffDvlaResponded",
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: false
        }
    },
    data(){
        return {
            details: {
                Ref: '',
                Title: '',
                Firstname: '',
                Name: '',
                Address_Line1: '',
                Address_Line2: '',
                Address_Line3: '',
                Address_Line4: '',
                Post_Code: '',
                Tel_No: '',
                Alt_Tel_No: '',
                Email: '',
                Notes: '',
                Date_Debt_Incurred: '',
                Amount_Outstanding: ''
            },
            verified: false,
            verified_by: null,
            sent_at: null
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData(){
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/debtreview?client_id=${this.client_id}`, {
                headers: authHeader()
            })
            .then(response => {
                let details = {};
                for(let key in response.data.details){
                    if(!['id', 'claim_id', 'verified', 'verified_by', 'verified_at', 'created_at', 'updated_at', 'sent_at', 'Alt_Tel_No'].includes(key)){
                        details[key] = response.data.details[key];
                    }
                }
                this.details = details;
                this.details.Amount_Outstanding = parseFloat(this.details.Amount_Outstanding).toFixed(2)
                this.verified = response.data.details.verified == 1;
                this.verified_by = response.data.details.verified_by;
                this.sent_at = response.data.details.sent_at || null;
            })
            .catch(error => {
                this.$error('Error loading data', error);
            });
        },
        send(verify = true, update = false){
            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/debtreview`, {
                verify: verify,
                client_id: this.client_id,
                update: update,
                ...this.details
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$emit('changeMade');
                this.$success('Verification updated!');
                this.loadData();
            })
            .catch(error => {
                this.$error('Error sending data', error);
            })
        }
    },
    computed: {
        detailKeys: function(){
            return Object.keys(this.details)
        }
    }
}
</script>