<template>
    <div class="pd-x-10">

        <div v-if="claim.status !== 'Pending'" class="row d-flex justify-content-center flex-wrap">

            <table class="table" style="max-width: 800px;">
                <thead class="bg-primary">
                    <tr>
                        <th style="color: white !important;">DESCRIPTION</th>
                        <th style="color: white !important;">VALUE</th>
                        <th style="color: white !important;">VAT?</th>
                        <th style="color: white !important;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{claim.litres}} litres of {{claim.fuel}}{{ claim.is_cannister == 1 ? ' (Cannister)' : '' }} (Pump {{claim.pump}})</td>
                        <td>£{{claim.value}}</td>
                        <td><i class="fa-solid fa-x text-danger"></i></td>
                        <td><button class="btn btn-sm btn-primary" @click="openEditFuelModal"><i class="fa-solid fa-pen-to-square"></i></button></td>
                    </tr>
                    <tr v-if="claim.fuel_second !== null">
                        <td>{{claim.litres_second}} of {{claim.fuel_second.toUpperCase()}}{{ claim.is_cannister_second == 1 ? ' (Cannister)' : '' }} (Pump {{claim.pump_second || 0}})</td>
                        <td>£{{claim.value_second}}</td>
                        <td><i class="fa-solid fa-x text-danger"></i></td>
                        <td><button class="btn btn-sm btn-primary" @click="openEditSecondFuelModal"><i class="fa-solid fa-pen-to-square"></i></button></td>
                    </tr>
                    <tr v-else>
                        <td colspan="3"></td>
                        <td><button class="btn btn-sm btn-success" @click="openEditSecondFuelModal"><i class="fa-solid fa-plus"></i></button></td>
                    </tr>
                    <tr>
                        <td>Administration Fee</td>
                        <td>£{{currentFee}}</td>
                        <td><i class="fa-solid fa-check text-success"></i></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="2"></td>
                        <td><strong>Sub-Total:</strong></td>
                        <td>£{{(parseFloat(claim.value) + parseFloat(claim.value_second || 0) + parseFloat(currentFee)).toFixed(2)}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"></td>
                        <td><strong>Less Payments:</strong></td>
                        <td>£{{currentPayments}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"></td>
                        <td><strong>Total:</strong></td>
                        <td>£{{currentlyDue}}</td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div class="row mg-t-20">
            <p class="tx-white tx-medium tx-16">Current Actions</p>
            <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 100%;"/>
        </div>

        <div class="row">
            <component :is="statusToComponent(claim.status) || 'NoActions'" :claim="claim" :client_id="client_id"
            @changetab="(tab) => $emit('changetab', tab)"
            @reload="$emit('reloadClaim')"
            @setstatus="(status) => $emit('setstatus', status)"
            @pendingLock="(value) => $emit('pendingLock', value)"
            @eventLock="(value) => $emit('eventLock', value)"
            @evidenceLock="(value) => $emit('evidenceLock', value)"
            @changeMade="$emit('changeMade')"></component>
        </div>

        <edit-modal v-if="claim.status !== 'Pending'" :id="'editFuelModal' + claim.id" title="Edit Fuel Information">

            <div class="form-layout form-layout-2">
                <div class="row no-gutters">
                    <div class="col-md-3">
                        <div class="form-group mg-md-l--1">
                            <label class="form-control-label mg-b-0-force">Fuel Type: <span class="tx-danger">*</span></label>
                            <select class="form-control select2 select2-hidden-accessible" v-model="claim.fuel" data-placeholder="Choose fuel type" v-select2>
                                <option v-for="type in types" :value="type">{{type.toUpperCase()}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="form-control-label">Value (£): <span class="tx-danger">*</span></label>
                            <input class="form-control" type="number" step="0.01" v-model="claim.value" placeholder="Fuel value">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="form-control-label">Litres: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="number" step="0.01" v-model="claim.litres" placeholder="Litres dispensed">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="form-control-label">Pump Number: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="number" min="1" max="20" v-model="claim.pump" placeholder="Pump number">
                        </div>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Reason for Change: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="text" v-model="reason" placeholder="Explain why you are changing it">
                        </div>
                    </div>
                </div>
                <div class="form-layout-footer bd pd-20 bd-t-0">
                    <submit-button v-show="reason.length > 5" :clicked="fuelClicked" :background="'btn btn-info'" :text="'Save Changes'" @click="saveFuel"/>
                    <button class="btn btn-secondary" :id="'closeEditFuelModal' + claim.id" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>

        </edit-modal>

        <edit-modal v-if="claim.status !== 'Pending'" :id="'editSecondFuelModal' + claim.id" title="Edit Fuel Information">

            <div class="form-layout form-layout-2">
                <div class="row no-gutters">
                    <div class="col-md-3">
                        <div class="form-group mg-md-l--1">
                            <label class="form-control-label mg-b-0-force">Fuel Type: <span class="tx-danger">*</span></label>
                            <select class="form-control select2 select2-hidden-accessible" v-model="claim.fuel_second" data-placeholder="Choose fuel type" v-select2>
                                <option v-for="type in types" :value="type">{{type.toUpperCase()}}</option>
                                <option :value="'REMOVE'">REMOVE</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="form-control-label">Value (£): <span class="tx-danger">*</span></label>
                            <input class="form-control" type="number" step="0.01" v-model="claim.value_second" placeholder="Fuel value">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="form-control-label">Litres: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="number" step="0.01" v-model="claim.litres_second" placeholder="Litres dispensed">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="form-control-label">Pump Number: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="number" min="1" max="20" v-model="claim.pump_second" placeholder="Pump number">
                        </div>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Reason for Change: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="text" v-model="reason" placeholder="Explain why you are changing it">
                        </div>
                    </div>
                </div>
                <div class="form-layout-footer bd pd-20 bd-t-0">
                    <submit-button v-show="reason.length > 5" :clicked="fuelClicked" :background="'btn btn-info'" :text="'Save Changes'" @click="saveFuelSecond" />
                    <button class="btn btn-secondary" :id="'closeEditSecondFuelModal' + claim.id" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>

        </edit-modal>

    </div>
</template>

<script>
import {DateTime} from 'luxon';
import SubmitButton from "@/components/SubmitButton";
import EditModal from "@/components/EditModal";
import axios from "axios";
import authHeader from "@/services/auth-header";

import NoActions from "@/views/claims/components/NoActions";
import DriveoffPending from "@/views/claims/components/driveoff/steps/DriveoffPending";
import DriveoffDebtCollectionReview from "@/views/claims/components/driveoff/steps/DriveoffDebtCollectionReview";
import DriveoffSentToKeeper from "@/views/claims/components/driveoff/steps/DriveoffSentToKeeper";
import DriveoffPostFailed from "@/views/claims/components/driveoff/steps/DriveoffPostFailed";
import DriveoffDvlaReview from "@/views/claims/components/driveoff/steps/DriveoffDvlaReview";
import TamperedPlate from "@/views/claims/components/driveoff/steps/TamperedPlate";

export default {
    name: 'DriveoffInformation',
    components: {
        SubmitButton,
        EditModal,
        NoActions,
        DriveoffPending,
        DriveoffDebtCollectionReview,
        DriveoffSentToKeeper,
        DriveoffPostFailed,
        DriveoffDvlaReview,
        TamperedPlate
    },
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: false
        }
    },
    data(){
        return { 
            dateDue: null,
            daysUntilEscalation: null,
            types: [
                'Petrol',
                'Diesel',
                'AdBlue',
                'Liquid Petroleum Gas (LPG)',
                'Red Diesel'
            ],
            fuelClicked: false,
            fuelSecondClicked: false,
            reason: ""
        }
    },
    mounted(){

        if(this.claim.dates.date_posted_driver !== null){
            let datePosted = DateTime.fromFormat(this.claim.dates.date_posted_driver, 'dd/MM/yyyy');
            let dueDate = datePosted.plus({days: 30});
            let daysUntilEscalation = parseFloat(Math.abs(DateTime.local().diff(dueDate, 'days').toObject().days)).toFixed(0);
            this.dateDue = dueDate;
        }

    },
    methods: {
        statusToComponent(){
            if(this.claim.status === 'Pending'){
                if(this.claim.progress_message === 'Tampered Plate'){
                    return 'TamperedPlate';
                }
                return 'DriveoffPending';
            }else{
                return {
                    'Debt Collection Review': 'DriveoffDebtCollectionReview',
                    'Sent to Keeper': 'DriveoffSentToKeeper',
                    'Post Failed': 'DriveoffPostFailed',
                    'DVLA Review': 'DriveoffDvlaReview',
                }[this.claim.status];
            }
        },
        openEditFuelModal(){
            if(!this.$can('claims-process')) return;
            $('#editFuelModal' + this.claim.id).modal('toggle');
            $('.select2').select2();
        },
        openEditSecondFuelModal(){
            if(!this.$can('claims-process')) return;
            $('#editSecondFuelModal' + this.claim.id).modal('toggle');
            $('.select2').select2();
        },
        saveFuel(){
            this.fuelClicked = true;
            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/fuels`, {
                client_id: this.client_id,
                ignore_second: true,
                fuel: this.claim.fuel,
                value: this.claim.value,
                litres: this.claim.litres,
                pump: this.claim.pump,
                reason: this.reason
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$success("Successfully updated claim details");
                this.$emit('changeMade');
                this.$emit('reloadClaim');
                this.fuelClicked = false;
                $('#closeEditFuelModal' + this.claim.id).click();

            })
            .catch(error => {
                this.$error("Failed to update claim details", error);
                this.fuelClicked = false;
            })
        },
        saveFuelSecond(){
            this.fuelClicked = true;
            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/fuels`, {
                client_id: this.client_id,
                ignore_second: false,
                fuel: this.claim.fuel,
                value: this.claim.value,
                litres: this.claim.litres,
                pump: this.claim.pump,
                fuel_second: this.claim.fuel_second,
                value_second: this.claim.value_second,
                litres_second: this.claim.litres_second,
                pump_second: this.claim.pump_second,
                reason: this.reason
            }, {
                headers: authHeader()
            })
                .then(response => {
                    this.$success("Successfully updated claim details");
                    this.$emit('changeMade');
                    this.$emit('reloadClaim');
                    this.fuelClicked = false;
                    $('#closeEditSecondFuelModal' + this.claim.id).click();
                })
                .catch(error => {
                    this.$error("Failed to update claim details", error);
                    this.fuelClicked = false;
                })
        }
    },
    computed: {
        currentFee: function(){
            let cost = 30;

            if(this.claim.lock_fee !== 1){
                let today = DateTime.local();
                if(this.claim.dates.date_posted_driver !== null && this.claim.lock_lower_fee != 1){
                    if(DateTime.local().minus({'days': 17}) > DateTime.fromFormat(this.claim.dates.date_posted_driver, 'dd/MM/yyyy')){
                        cost += 30;
                    }
                }
            }else{
                cost = parseFloat(this.claim.locked_fee)
            }

            cost = parseFloat(cost).toFixed(2);

            return cost;
        },
        currentlyDue: function(){
            let cost = 30;

            if(this.claim.lock_fee !== 1){
                let today = DateTime.local();
                if(this.claim.dates.date_posted_driver !== null && this.claim.lock_lower_fee != 1){
                    if(DateTime.local().minus({'days': 17}) > DateTime.fromFormat(this.claim.dates.date_posted_driver, 'dd/MM/yyyy')){
                        cost += 30;
                    }
                }
            }else{
                cost = parseFloat(this.claim.locked_fee)
            }

            cost = parseFloat(cost).toFixed(2);

            let amountToCollect = parseFloat(cost) + parseFloat(parseFloat(this.claim.value).toFixed(2));

            if(this.claim.fuel_second !== null){
                amountToCollect += parseFloat(parseFloat(this.claim.value_second).toFixed(2));
            }

            if(this.claim.payments.length > 0){
                this.claim.payments.forEach((payment) => {
                    amountToCollect = parseFloat(amountToCollect) - parseFloat(payment.amount);
                });
            }
            this.claim.deductions.forEach((deduction) => {
                amountToCollect = parseFloat(amountToCollect) + parseFloat(deduction.amount);
            });

            this.claim.amountToCollect = parseFloat(amountToCollect).toFixed(2);
            return parseFloat(amountToCollect).toFixed(2);
        },
        currentPayments: function (){
            let paid = 0.00;
            this.claim.payments.forEach((p) => {
                paid += parseFloat(p.amount);
            });
            this.claim.deductions.forEach((d) => {
                paid -= parseFloat(d.amount);
            });
            return parseFloat(paid).toFixed(2);
        }
    }
}
</script>