<template>
    <div>
        <p class="tx-bold tx-white">Some issues were encountered when attempting to submit to the KADOE service. Please find details below.</p>

        <p><strong>KADOE Error:</strong> {{claim.post_failure_reason}}</p>

        <p>Below are a list of actions to take based on the error encountered. Please make sure you verify all the details of the parking charge before you take any action.</p>
        <ul>
            <li>KS400: This enquirys hash already exists -> Leave the claim, it should receive the details in the next few days.</li>
            <li>KS500: Invalid VRM Registration is in unknown format -> Cancel <a href="javascript:void(0);" @click="$emit('changetab', 'manage')" class="tx-bold">(See Management Data tab)</a></li>
            <li>KADOE Submission Failed -> Click Retry Submission</li>
        </ul>

        <button class="btn btn-sm btn-primary" @click="updateStatus">Retry Submission</button>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
export default {
    name: "ParkingKadoeError",
    props: {
        claim: Object,
        client_id: Number | String
    },
    methods: {
        updateStatus(){
            axios.post(`https://api.varsanpr.com/api/claims/${this.claim.id}/kadoe?client_id=${this.client_id}`, {
                headers: authHeader()
            })
                .then(response => {
                    this.$emit('setstatus', 'At DVLA')
                    this.$emit('changeMade');
                    this.$success("Successfully updated claim status")
                })
                .catch(error => {
                    this.$error("Failed to update claim status.", error);
                });
        },
    }
}
</script>
