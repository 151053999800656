<template>
    <div>
        <div class="row">
            <div class="col-12">
                <table v-show="!loading" width="100%" class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                    <tr>
                        <th>#</th>
                        <th>TYPE</th>
                        <th>STATUS</th>
                        <th>CANCELLATION REASON</th>
                        <th>VALUE</th>
                        <th>DATE RECEIVED</th>
                        <th>SITE</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="c in claims" v-if="!loading" style="cursor: pointer;" @click="$emit('open-claim', c)">
                        <td>{{c.id}}</td>
                        <td>{{c.type}}</td>
                        <td>{{c.status}}</td>
                        <td>{{c.cancellation_reason}}</td>
                        <td>£{{c.value}}</td>
                        <td>{{c.date_received}}</td>
                        <td>{{c.site_name}}</td>
                    </tr>
                    <tr v-if="claims.length < 1 && !loading">
                        <td colspan="9" class="text-center">Looks like there are no other claims for this vehicle...</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {DateTime} from 'luxon';
import axios from 'axios';
import authHeader from '@/services/auth-header';
export default {
    name: "ClaimHistory",
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
            claims: [],
            loading: true
        }
    },
    mounted(){
        axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/history?client_id=${this.client_id}`, {
            headers: authHeader()
        })
        .then(response => {
            response.data.history.forEach((claim) => {
                claim.date_received = DateTime.fromSeconds(parseInt(claim.date_received)).toFormat('dd/MM/yyyy');
                claim.client_id = this.client_id;
            })
            this.claims = response.data.history;
            this.$emit('history', this.claims.length);
            this.loading = false;
        })
        .catch(error => {
            this.$error("Failed loading historic claims.", error);
            this.loading = false;
        });
    }
}
</script>

<style scoped>

</style>
