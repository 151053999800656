<template>
    <div>

        <div class="pd-x-10">

            <div v-if="$can('claims-process') && false" class="row mg-t-20">
                <p class="tx-white tx-medium tx-16">Parking Conditions</p>
                <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 100%;"/>
            </div>
    
            <table v-if="false" class="table">
                <thead>
                <tr>
                    <th>Condition</th>
                    <th>Enforced From</th>
                    <th>Enforced To</th>
                    <th>Created By</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-show="max_stay.hours + max_stay.minutes > 0">
                        <td>Maximum stay of {{max_stay.hours}} Hours {{max_stay.minutes}} Minutes</td>
                        <td>-</td>
                        <td>-</td>
                        <td>System</td>
                    </tr>
                    <tr v-show="no_return.hours + no_return.minutes > 0">
                        <td>No return of {{no_return.hours}} Hours {{no_return.minutes}} Minutes</td>
                        <td>-</td>
                        <td>-</td>
                        <td>System</td>
                    </tr>
                    <tr v-show="parking_grace_period.hours + parking_grace_period.minutes > 0">
                        <td>Grace period of {{parking_grace_period.hours}} Hours {{parking_grace_period.minutes}} Minutes</td>
                        <td>-</td>
                        <td>-</td>
                        <td>System</td>
                    </tr>
                    <tr v-for="condition in conditions" :key="condition.id">
                        <td>{{condition.condition}}</td>
                        <td>{{condition.enforced_from}}</td>
                        <td>{{condition.enforced_to}}</td>
                        <td>{{condition.created_by}}</td>
                    </tr>
                </tbody>
            </table>

            <div v-if="$can('claims-process')" class="row mg-t-20">
                <p class="tx-white tx-medium tx-16">Parking Context</p>
                <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 100%;"/>
            </div>

            <div class="row">
                <!-- ENTRY IMAGE -->
                <div class="col-6">
                    <p class="text-center" style="font-weight: bold; color: white;">ENTRY SIGN</p>
                    <img v-viewer :src="entrySignage[0].imageUrl" style="width: auto; display: block; max-height: 300px;" v-if="entrySignage.length > 0"/>
                    <div v-else>
                        <p class="text-center" style="color: white;">No Entrance Signage</p>
                    </div>
                </div>
                <!-- T&C SIGNAGE (CAROUSEL IF MULTIPLE) -->
                <div class="col-6">
                    <p class="text-center" style="font-weight: bold; color: white;">T&C SIGN</p>
                    <img v-viewer :src="tAndCSignage[0].imageUrl" style="width: auto; display: block; max-height: 300px;" v-if="tAndCSignage.length > 0"/>
                    <div v-else>
                        <p class="text-center" style="color: white;">No T&C Signage</p>
                    </div>
                </div>
            </div>
    
            <div v-if="$can('claims-process')" class="row mg-t-20">
                <p class="tx-white tx-medium tx-16">Parking Details</p>
                <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 100%;"/>
            </div>
    
            <div v-if="claim.sub_type === 'Overstay'">
                <table class="table">
                    <thead>
                    <tr>
                        <th>Entry At</th>
                        <th>Exit At</th>
                        <th>Total Stay</th>
                        <th>Max Stay</th>
                        <th>Grace Period</th>
                        <th>Overstay By</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{entry_at}}</td>
                        <td>{{exit_at}}</td>
                        <td>{{total_stay.hours}} Hours {{total_stay.minutes}} Minutes {{total_stay.seconds}} Seconds</td>
                        <td>{{max_stay.hours}} Hours {{max_stay.minutes}} Minutes</td>
                        <td>{{parking_grace_period.hours}} Hours {{parking_grace_period.minutes}} Minutes</td>
                        <td>{{overstay_by.hours}} Hours {{overstay_by.minutes}} Minutes {{overstay_by.seconds}} Seconds</td>
                    </tr>
                    </tbody>
                </table>
            </div>
    
            <div v-if="claim.sub_type === 'EV'">
                <table class="table">
                    <thead>
                    <tr>
                        <th>Entry At</th>
                        <th>Exit At</th>
                        <th>Total Stay</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{entry_at}}</td>
                        <td>{{exit_at}}</td>
                        <td>{{total_stay.hours}} Hours {{total_stay.minutes}} Minutes {{total_stay.seconds}} Seconds</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div v-if="$can('claims-process')" class="row mg-t-20">
                <p class="tx-white tx-medium tx-16">Current Actions</p>
                <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 100%;"/>
            </div>

            <div v-if="$can('claims-process')" class="row">
                <component :is="statusToComponent() || 'NoActions'" @changetab="(tab) => $emit('changetab', tab)"
                    @setstatus="(status) => $emit('setstatus', status)"
                    @reloadClaim="(data) => $emit('reloadClaim', data)"
                    :claim="claim"
                    :client_id="client_id"
                    @changeMade="$emit('changeMade')"></component>
            </div>
        </div>
    </div>
</template>

<script>
import {DateTime} from "luxon";
import ParkingPending from "./steps/ParkingPending";
import ParkingKadoeError from "./steps/ParkingKadoeError";
import ParkingDvlaResponded from "./steps/ParkingDvlaResponded";
import ParkingDebtCollectionReview from "./steps/ParkingDebtCollectionReview.vue";
import NoActions from '../NoActions.vue'
import axios from "axios";
import authHeader from "../../../../services/auth-header";
import SecureImage from "../../../../components/SecureImage";

export default {
    name: "ParkingInformation",
    components: {ParkingDvlaResponded, ParkingKadoeError, ParkingPending, ParkingDebtCollectionReview, NoActions, SecureImage},
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
            conditons: [],
            signage: []
        }
    },
    mounted(){
        this.getSignagePics();
    },
    methods: {
        statusToComponent(){
            return {
                'Pending': 'ParkingPending',
                'KADOE Error': 'ParkingKadoeError',
                'DVLA Responded': 'ParkingDvlaResponded',
                'Debt Collection Review': 'ParkingDebtCollectionReview'
            }[this.claim.status];
        },
        getParkingConditions(){
            let targetDate;
            if(this.claim.sub_type === 'Overstay'){
                targetDate = DateTime.fromSeconds(this.claim.events[0].created_at).toISODate();
            }else{
                targetDate = DateTime.fromSeconds(this.claim.ev_entry).toISODate();
            }
            axios.get(`https://api.varsanpr.com/api/sites/${this.claim.site_id}/parkingconditions`, {
                headers: authHeader(),
                params: {
                    targetDate: targetDate
                }
            })
            .then(response => {
                this.conditions = response.data;
                this.conditions.forEach((condition) => {
                    condition.enforced_from = condition.enforced_from ? DateTime.fromISO(condition.enforced_from).toFormat('dd/MM/yyyy') : null;
                    condition.enforced_to = condition.enforced_to ? DateTime.fromISO(condition.enforced_to).toFormat('dd/MM/yyyy') : null;
                });
            })
            .catch(error => {
                console.log(error);
            });
        },
        getSignagePics(){
            axios.get(`https://api.varsanpr.com/api/sites/${this.claim.site_id}/signage`, {
                headers: authHeader()
            })
            .then(response => {
                this.signage = response.data.signage;
            })
            .catch(err => {
                console.log(err);
            })
        }
    },
    computed: {
        entry_at: function(){
            if(this.claim.sub_type === 'Overstay'){
                return DateTime.fromSeconds(this.claim.events[0].created_at).toFormat('HH:mm:ss dd/MM/yyyy');
            }
            return DateTime.fromSeconds(this.claim.ev_entry).toFormat('HH:mm:ss dd/MM/yyyy');
        },
        exit_at: function(){
            if(this.claim.sub_type === 'Overstay'){
                return DateTime.fromSeconds(this.claim.events[1].created_at).toFormat('HH:mm:ss dd/MM/yyyy');
            }
            return DateTime.fromSeconds(this.claim.ev_exit).toFormat('HH:mm:ss dd/MM/yyyy');
        },
        total_stay: function(){
            if(this.claim.sub_type === 'Overstay'){
                return DateTime.fromSeconds(this.claim.events[1].created_at).diff(DateTime.fromSeconds(this.claim.events[0].created_at), ['hours','minutes','seconds']).toObject();
            }
            return DateTime.fromSeconds(this.claim.ev_exit).diff(DateTime.fromSeconds(this.claim.ev_entry), ['hours','minutes','seconds']).toObject();
        },
        overstay_by: function(){
            return DateTime.fromSeconds(this.claim.events[1].created_at).diff(DateTime.fromSeconds(this.claim.events[0].created_at).plus({minutes: parseInt(this.claim.site.max_stay) + parseInt(this.claim.site.parking_grace_period)}), ['hours','minutes','seconds']).toObject();
        },
        max_stay: function(){
            let maxStay = parseInt(this.claim.site.max_stay);
            if(isNaN(maxStay)) return {hours: 0, minutes: 0};
            let hours = (maxStay / 60) >> 0;
            maxStay -= hours * 60;
            return {hours: hours, minutes: maxStay};
        },
        parking_grace_period: function(){
          let gracePeriod = parseInt(this.claim.site.parking_grace_period);
          if(isNaN(gracePeriod)) return {hours: 0, minutes: 0};
          let hours = (gracePeriod / 60) >> 0;
          gracePeriod -= hours * 60;
          return {hours: hours, minutes: gracePeriod || 0}
        },
        no_return: function(){
            let noReturn = parseInt(this.claim.site.no_return);
            if(isNaN(noReturn)) return {hours: 0, minutes: 0};
            let hours = (noReturn / 60) >> 0;
            noReturn -= hours * 60;
            return {hours: hours, minutes: noReturn};
        },
        tAndCSignage: function(){
            return this.signage.filter((sign) => sign.type === 'T&C');
        },
        entrySignage: function(){
            return this.signage.filter((sign) => sign.type === 'Entrance');
        }
    }
}
</script>