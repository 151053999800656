<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card bd-0">
                    <div class="card-header tx-uppercase tx-medium bd-0 tx-white bg-dark">
                        HEADS UP!
                    </div><!-- card-header -->
                    <div class="card-body bd bd-t-0 tx-black bg-white rounded-bottom">
                        <p class="mg-b-0">This page displays all vehicles entered on tablets available on site 2 hours before entry to 2 hours after exit. For example, a vehicle enters at 12pm and leaves at 2pm this page will show
                        entries from 10am to 4pm.</p>
                    </div><!-- card-body -->
                </div>
            </div>
        </div>
        <div class="row mg-t-20">
            <div class="col-12">
                <pagination @search="search" :data="pagination"></pagination>
                <table v-show="!loading" width="100%" class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                    <tr>
                        <th>REGISTRATION</th>
                        <th>SITE</th>
                        <th>ADDED</th>
                        <th>EXPIRY</th>
                        <th>SOURCE</th>
                        <th>DISTANCE</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="entry in data" v-if="!loading">
                        <td>{{entry.plate}}</td>
                        <td>{{entry.name}}</td>
                        <td>{{entry.added}}</td>
                        <td>{{entry.expiry}}</td>
                        <td>{{entry.source}}</td>
                        <td>{{entry.distance}}</td>
                    </tr>
                    <tr v-if="data.length < 1 && !loading">
                        <td colspan="6" class="text-center">Looks like there are no entries from the tablet...</td>
                    </tr>
                    </tbody>
                </table>
                <pagination @search="search" :data="pagination"></pagination>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {DateTime} from 'luxon';
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "ClaimCustomerParking",
    components: {Pagination},
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
            loading: true,
            data: [],
            pagination: {
              currPage: 1,
              totalResults: 0,
              from: 0,
              to: 0,
              lastPage: 1,
              perPage: 20
            }
        }
    },
    mounted() {
        this.search();
    },
    methods: {
      search(page = 1){
        axios.get(`https://api.varsanpr.com/api/parking?site_id=${this.claim.site_id}&start_datetime=${this.claim.events[0].created_at - 28800}&end_datetime=${this.claim.events[1].created_at + 28800}&page=${page}`, {
            headers: authHeader()
        })
        .then(response => {
            response.data.events.forEach((event) => {
                event.added_seconds = event.added;
                event.added = DateTime.fromSeconds(event.added).toFormat('dd/MM/yyyy HH:mm');
                event.expiry = DateTime.fromSeconds(event.expiry).toFormat('dd/MM/yyyy HH:mm');
                event.distance = this.levenshteinDistance(event.plate, this.claim.vehicle.plate);
            })
            // sort results by the closest match to the claimant's vehicle
            response.data.events.sort((a, b) => {
                return b.added_seconds - a.added_seconds;
            });
            this.data = response.data.events;
            this.pagination.currPage = response.data.pagination.page;
            this.pagination.totalResults = response.data.pagination.total;
            this.pagination.from = (this.pagination.currPage - 1) * response.data.pagination.showing + 1;
            this.pagination.to = this.pagination.from + response.data.pagination.showing;
            this.pagination.perPage = response.data.pagination.showing;
            this.pagination.lastPage = response.data.pagination.lastPage;
            this.loading = false;
        })
        .catch(error => {
            this.$error('Failed to load Customer Parking', error);
            this.loading = false;
        })
      },
      levenshteinDistance(a, b){
        if(a.length === 0) return b.length;
        if(b.length === 0) return a.length;
      
        let matrix = [];
      
        // increment along the first column of each row
        let i;
        for(i = 0; i <= b.length; i++){
          matrix[i] = [i];
        }
      
        // increment each column in the first row
        let j;
        for(j = 0; j <= a.length; j++){
          matrix[0][j] = j;
        }
      
        // Fill in the rest of the matrix
        for(i = 1; i <= b.length; i++){
          for(j = 1; j <= a.length; j++){
            if(b.charAt(i-1) === a.charAt(j-1)){
              matrix[i][j] = matrix[i-1][j-1];
            }else{
              matrix[i][j] = Math.min(
                matrix[i-1][j-1] + 1, // substitution
                Math.min(
                  matrix[i][j-1] + 1, // insertion
                  matrix[i-1][j] + 1 // deletion
                )
              );
            }
          }
        }
      
        return matrix[b.length][a.length];
      }
    }
}
</script>

<style scoped>

</style>
