<template>
    <div class="row">
        <div v-show="!showEmail" class="col">

            <table v-show="!loading" width="100%" class="table table-striped table-hover tx-white">
                <thead class="thead-colored thead-primary">
                <tr>
                    <th>NAME</th>
                    <th>DESCRIPTION</th>
                    <th>EMAIL</th>
                    <th>TELEPHONE</th>
                    <th>TYPE</th>
                    <th>CATEGORY</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="contact in contacts" :key="contact.id">
                        <td>{{contact.name}}</td>
                        <td>{{contact.description}}</td>
                        <td>
                            {{contact.email}}
                            <span v-if="contact.email?.includes('@')">
                                <button class="btn btn-sm btn-info" style="margin-left: 15px;" @click="newEmail(contact)">New Email</button>
                            </span>
                        </td>
                        <td>
                            {{contact.telephone}}
                            <span v-if="contact.telephone?.includes('+44')">
                                <button class="btn btn-sm btn-info" style="margin-left: 15px;" @click="phone.makeOutgoingCall(contact.telephone, null, claim.reference)">Call</button>
                            </span>
                        </td>
                        <td>{{contact.contact_type}}</td>
                        <td>{{contact.category}}</td>
                    </tr>
                    <tr v-if="contacts.length < 1">
                        <td colspan="6" class="text-center">Looks like there are no contacts for this site...</td>
                    </tr>
                </tbody>
            </table>

            <button class="btn btn-sm btn-primary" @click="openAddModal">Add Contact</button>

            <br/>

            <h4 style="margin-top: 20px; color: white; text-decoration: underline;">Call Recordings</h4>
            <table class="table">
                <thead class="thead-colored thead-primary">
                    <tr>
                        <th>Datetime</th>
                        <th>User</th>
                        <th>To</th>
                        <th>Notes</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="call in calls">
                        <td>{{ call.created_at }}</td>
                        <td>{{ call.user_email }}</td>
                        <td>{{ call.recipient }}</td>
                        <td>{{ call.notes }}</td>
                        <td>
                            <a v-if="call.sas" :href="call.sas" target="_blank" class="btn btn-primary btn-sm">Listen</a>
                        </td>
                    </tr>
                </tbody>
            </table>
            
        </div>

        <div v-show="showEmail" class="col">
            <h5>Send Email</h5>
            <div v-if="currentContact">
                <div class="form-group mg-b-sm">
                    <label for="name">Name <span class="text-danger">*</span></label>
                    <input v-model="currentContact.name" :disabled="true" type="text" class="form-control" id="name" placeholder="Enter name">
                </div>

                <div class="form-group mg-b-sm">
                    <label for="name">Email <span class="text-danger">*</span></label>
                    <input v-model="currentContact.email" :disabled="true" type="email" class="form-control" id="email" placeholder="Enter email">
                </div>

                <div class="form-group mg-b-sm">
                    <label for="status">Status <span class="text-danger">*</span></label>
                    <select v-model="email.status" class="form-control" id="status">
                        <option :value="2">Open</option>
                        <option :value="3">Pending</option>
                        <option :value="4">Resolved</option>
                        <option :value="5">Closed</option>
                    </select>
                </div>

                <div class="form-group mg-b-sm">
                    <label for="priority">Priority <span class="text-danger">*</span></label>
                    <select v-model="email.priority" class="form-control" id="priority">
                        <option :value="1">Low</option>
                        <option :value="2">Medium</option>
                        <option :value="3">High</option>
                        <option :value="4">Urgent</option>
                    </select>
                </div>
            </div>

            <div class="form-group mg-b-sm">
                <label for="subject">Subject <span class="text-danger">*</span></label>
                <input v-model="email.subject" type="text" class="form-control" id="subject" placeholder="Enter subject">
            </div>

            <div class="form-group mg-b-sm">
                <label for="subject">Message <span class="text-danger">*</span></label>
                <div :id="'summernote' + claim.id"></div>
            </div>

            <button class="btn btn-primary btn-sm" @click="sendEmail">Send Email</button>
            <button class="btn btn-warning btn-sm" @click="cancelEmail">Cancel</button>
        </div>

        <div :id="'addContactModal' + claim.id" class="modal fade" style="display: none; padding-right: 17px;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content bg-dark tx-white tx-size-sm">
                    <div class="modal-header bg-dark pd-x-20">
                        <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">Add Contact</h6>
                        <button type="button" class="close tx-white" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body bg-dark tx-white pd-20">

                        <div class="form-group mg-b-sm">
                            <label for="name">Name <span class="text-danger">*</span></label>
                            <input v-model="contact.name" type="text" class="form-control" id="name" placeholder="Enter name">
                        </div>

                        <div class="form-group mg-b-sm">
                            <label for="name">Description</label>
                            <textarea v-model="contact.description" class="form-control" id="description" placeholder="Enter description"></textarea>
                        </div>

                        <div class="form-group mg-b-sm">
                            <label for="name">Email</label>
                            <input v-model="contact.email" type="email" class="form-control" id="email" placeholder="Enter email">
                        </div>

                        <div class="form-group mg-b-sm">
                            <label for="phone">Telephone</label>
                            <input v-model="contact.phone" type="tele" class="form-control" id="phone" placeholder="Enter telephone">
                        </div>

                        <div class="form-group mg-b-sm">
                            <label for="contact_type">Contact Type</label>
                            <select v-model="contact.contact_type" class="form-control" id="contact_type">
                                <option value="Primary">Primary</option>
                                <option value="Secondary">Secondary</option>
                                <option value="Teritary">Teritary</option>
                            </select>
                        </div>

                        <div class="form-group mg-b-sm">
                            <label for="category">Category</label>
                            <select v-model="contact.category" class="form-control" id="category">
                                <option value="All">All</option>
                                <option value="Claims">Claims</option>
                                <option value="Support">Support</option>
                            </select>
                        </div>

                        <button class="btn btn-sm btn-success" @click="postContact">Create</button>

                    </div><!-- modal-body -->
                    <div class="modal-footer bg-dark tx-white">
                        <button :id="'closeAddContactModal' + claim.id" type="button" class="btn btn-secondary tx-size-xs" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div><!-- modal-dialog -->
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import Phone from "@/services/phone.js";
import {DateTime} from 'luxon';

export default {
    name: "ClaimContacts",
    components: {
        Phone
    },
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
            loading: true,
            contacts: [],
            contact: {
                name: '',
                description: '',
                email: '',
                phone: '',
                contact_type: 'Primary',
                category: 'All'
            },
            currentContact: null,
            email: {
                subject: '',
                contents: '',
                status: 5,
                priority: 2,
            },
            showEmail: false,
            phone: null,
            calls: []
        }
    },
    mounted(){
        if(this.$can('calls-view') || this.$can('calls-listen')){
            this.phone = new Phone();
        }
        this.getContacts();
        this.getCalls();
        setTimeout(() => {
            $('#summernote' + this.claim.id).summernote({
                placeholder: 'Write your email here...',
                tabsize: 2,
                height: 300,
                toolbar: [
                    ['style', ['style']],
                    ['font', ['bold', 'underline', 'clear']],
                    ['fontsize', ['fontsize']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['height', ['height']],
                    ['insert', ['picture', 'link']],
                    ['view', ['fullscreen', 'codeview']],
                ]
            });
        }, 1000);
    },
    methods: {
        getCalls(){
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/calls?client_id=${this.client_id}`, {
                headers: authHeader()
            })
            .then(response => {
                this.calls = response.data.calls;
                this.calls.forEach((call) => {
                    call.created_at = DateTime.fromSeconds(call.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
                })
            })
            .catch(error => {
                this.$error(error.response?.data?.message || error?.message || error.toString(), error);
            });
        },
        getContacts(){
            this.loading = true;
            axios.get(`https://api.varsanpr.com/api/sites/${this.claim.site_id}/contacts`, {
                headers: authHeader()
            })
            .then(response => {
                this.contacts = response.data.contacts.filter((c) => {
                    return c.category === 'All' || c.category === 'Claims';
                });
                this.loading = false;
            })   
            .catch(error => {
                this.$error(error.response?.data?.message || error?.message || error.toString(), error);
                this.loading = false;
            });
        },
        postContact(){
            axios.post(`https://api.varsanpr.com/api/sites/${this.claim.site_id}/contacts`, {
                name: this.contact.name,
                description: this.contact.description,
                email: this.contact.email,
                phone: this.contact.phone,
                contact_type: this.contact.contact_type,
                category: this.contact.category
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$success(response.data.message);
                this.getContacts();
                this.$emit('changeMade');
                $('#closeAddContactModal' + this.claim.id).click();
                this.contact = {
                    name: '',
                    description: '',
                    email: '',
                    phone: '',
                    contact_type: 'Primary',
                    category: 'All'
                }
            })
            .catch(error => {
                this.$error(error.response?.data?.message || error?.message || error.toString(), error);
            });
        },
        openAddModal(){
            $('#addContactModal' + this.claim.id).modal('toggle');
        },
        newEmail(contact){
            this.currentContact = contact;
            this.showEmail = true;
        },
        sendEmail(){
            let message = $('#summernote' + this.claim.id).summernote('code');
            this.email.contents = message;

            axios.post(`https://api.varsanpr.com/api/tickets`, {
                client_id: this.client_id,
                claim_id: this.claim.id,
                contact_id: this.currentContact.id,
                subject: this.email.subject,
                message: this.email.contents,
                status: this.email.status,
                priority: this.email.priority,
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$success(response.data.message);
                this.$emit('refresh');
                this.$emit('changeMade');
                this.cancelEmail();
            })
            .catch(error => {
                this.$error(error.response?.data?.message || error?.message || error.toString(), error);
            });
        },
        cancelEmail(){
            this.currentContact = null;
            this.showEmail = false;
            this.email = {
                subject: '',
                contents: '',
                status: 5,
                priority: 2,
            };   
        },
    }
}
</script>