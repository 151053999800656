<template>
    <div>
        <table class="table">
            <thead>
            <tr>
                <th>Entry At</th>
                <th>Exit At</th>
                <th>Total Stay</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{{entry_at}}</td>
                <td>{{exit_at}}</td>
                <td>{{total_stay.hours}} Hours {{total_stay.minutes}} Minutes {{total_stay.seconds}} Seconds</td>
            </tr>
            </tbody>
        </table>

        <div class="pd-x-10">

            <div v-if="$can('claims-process')" class="row mg-t-20">
                <p class="tx-white tx-medium tx-16">Current Actions</p>
                <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; width: 100%;"/>
            </div>

            <div v-if="$can('claims-process')" class="row">
                <component :is="statusToComponent() || 'NoActions'" @changetab="(tab) => $emit('changetab', tab)"
                    @setstatus="(status) => $emit('setstatus', status)"
                    @reloadClaim="(data) => $emit('reloadClaim', data)"
                    @reload="(data) => $emit('reloadClaim', data)"
                    :claim="claim"
                    :client_id="client_id"
                    @changeMade="$emit('changeMade')"></component>
            </div>
        </div>
    </div>
</template>

<script>
import {DateTime} from "luxon";
import ParkingPending from "../parking/steps/ParkingPending";
import ParkingKadoeError from "../parking/steps/ParkingKadoeError";
import ParkingDebtCollectionReview from "../parking/steps/ParkingDebtCollectionReview.vue";
import EvParkingDvlaResponded from "./steps/EvParkingDvlaResponded.vue";
import NoActions from "../NoActions.vue";

export default {
    name: "EvParkingInformation",
    components: { ParkingKadoeError, ParkingPending, ParkingDebtCollectionReview, EvParkingDvlaResponded, NoActions },
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
        }
    },
    methods: {
        statusToComponent(){
            return {
                'Pending': 'ParkingPending',
                'KADOE Error': 'ParkingKadoeError',
                'DVLA Responded': 'EvParkingDvlaResponded',
                'Debt Collection Review': 'ParkingDebtCollectionReview'
            }[this.claim.status];
        }
    },
    computed: {
        entry_at: function(){
            return DateTime.fromSeconds(this.claim.ev_entry).toFormat('HH:mm:ss dd/MM/yyyy');
        },
        exit_at: function(){
            return DateTime.fromSeconds(this.claim.ev_exit).toFormat('HH:mm:ss dd/MM/yyyy');
        },
        total_stay: function(){
            return DateTime.fromSeconds(this.claim.ev_exit).diff(DateTime.fromSeconds(this.claim.ev_entry), ['hours','minutes','seconds']).toObject();
        }
    },
    mounted(){
    }
}
</script>