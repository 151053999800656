<template>
    <div>
        <div class="row">
            <div class="col-12">
                <table width="100%" class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                    <tr>
                        <th>#</th>
                        <th>USER</th>
                        <th>MESSAGE</th>
                        <th>CREATED AT</th>
                        <th>DUE ON</th>
                        <th><button class="btn btn-sm btn-success float-right" @click="openModal">New Reminder</button></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-show="!loading" v-for="r in reminders">
                        <td>{{r.id}}</td>
                        <td>{{r.user?.email}}</td>
                        <td>{{r.message}}</td>
                        <td>{{ r.created_at }}</td>
                        <td>{{ r.expires_at }}</td>
                        <td><button class="btn btn-sm btn-danger" @click="deleteReminder(r)">DELETE</button></td>
                    </tr>
                    <tr v-if="reminders.length < 1 && !loading">
                        <td colspan="6" class="text-center">Looks like there are no reminders for this case...</td>
                    </tr>
                    <tr v-if="loading">
                        <td colspan="6" class="text-center">Loading data...</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div :id="'addReminderModal' + claim.id" class="modal fade" style="display: none; padding-right: 17px;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content bg-dark tx-white tx-size-sm">
                    <div class="modal-header bg-dark pd-x-20">
                        <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">Add Reminder</h6>
                        <button type="button" class="close tx-white" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body bg-dark tx-white pd-20">

                        <div class="row no-gutters">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label">Reminder Message: <span class="tx-danger">*</span></label>
                                    <input class="form-control" type="text" v-model="reminder.message" placeholder="Reminder message">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-b-0">
                                    <label>Reminder Date: <span class="tx-danger">*</span></label>
                                    <input type="date" class="form-control" placeholder="DD/MM/YYYY" v-model="reminder.date" required="true">
                                </div><!-- form-group -->
                            </div>
                            <div class="col-12 mg-t-10">
                                <submit-button :background="'btn btn-info'" :text="'Create Reminder'" :clicked="creating" @click="createReminder" />
                                <button class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            </div><!-- form-group -->
                        </div><!-- row -->

                    </div><!-- modal-body -->
                    <div class="modal-footer bg-dark tx-white">
                        <button :id="'closeAddReminderModal' + claim.id" type="button" class="btn btn-secondary tx-size-xs" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div><!-- modal-dialog -->
        </div>
    </div>
</template>

<script>
import {DateTime} from "luxon";
import SubmitButton from "@/components/SubmitButton";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "ClaimReminders",
    components: {SubmitButton},
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
            reminders: [],
            loading: true,
            creating: false,
            deleting: false,
            reminder: {
                message: "",
                date: ""
            },
            disabledDates: {
                to: new Date()
            },
        }
    },
    mounted(){
        this.loadReminders();
    },
    methods: {
        loadReminders(){
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/reminders?client_id=${this.client_id}`, {
                headers: authHeader()
            })
                .then(response => {
                    this.reminders = response.data.reminders;
                    this.reminders.forEach((r) => {
                        r.created_at = DateTime.fromSeconds(r.created_at).toFormat('dd/MM/yyyy HH:mm');
                        r.expires_at = DateTime.fromSeconds(r.expires_at).toFormat('dd/MM/yyyy HH:mm');
                    })
                    this.loading = false;
                })
                .catch(error => {
                    this.$error("Failed loading reminders.", error);
                    this.loading = false;
                });
        },
        createReminder(){
            if(this.creating) return;
            this.creating = true;
            axios.put(`https://api.varsanpr.com/api/claims/${this.claim.id}/reminders`, {
                client_id: this.client_id,
                message: this.reminder.message,
                due: this.reminder.date + 'T00:00'
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$emit('changeMade');
                this.$success("Created reminder");
                this.creating = false;
                $('#closeAddReminderModal' + this.claim.id).click();
                this.loadReminders();
            })
            .catch(error => {
                this.error("Failed to create reminder", error);
                this.creating = false;
            })
        },
        deleteReminder(reminder){
            if(this.deleting) return;
            this.deleting = true;
            axios.delete(`https://api.varsanpr.com/api/claims/${this.claim.id}/reminders/${reminder.id}`, {
                data: {
                    client_id: this.client_id
                },
                headers: authHeader()
            })
            .then(response => {
                this.$emit('changeMade');
                this.$success("Deleted reminder");
                this.reminders = this.reminders.filter((r) => {
                    return r.id !== reminder.id
                });
            })
            .catch(error => {
                this.$error("Failed to delete reminder", error);
            })
            .finally(() => {
                this.deleting = false;
            });
        },
        openModal(){
            $('#addReminderModal' + this.claim.id).modal('toggle');
        }
    }
}
</script>

<style scoped>

</style>
