<template>
    <div class="row">
        <div :class="{'col-6': pdf_url !== null, 'col-12': pdf_url === null}">
            <div class="tx-white">
                <p class="tx-bold">This claim failed during postage. The reason for failure can be found below.</p>
        
                <p><strong>Failure Reason: </strong> {{claim.post_failure_reason}}</p>
        
                <p>If the above error message is not related to the address, please contact Lewis (lm@varstechnology.co.uk)</p>
        
                <p class="tx-info">In order to view and ammend the address, please go to the <strong>Management</strong> tab. <a href="javascript:void(0);" @click="$emit('changetab', 'manage')" class="tx-bold">(See Management tab)</a></p>
        
            </div>
        </div>
        <div v-if="pdf_url !== null" class="col-6">
            <p>Notice Preview: </p>
            <pdf v-if="pdf_url !== null" :src="pdf_url"
                     @num-pages="pageCount = $event"
                     @page-loaded="currentPage = $event"
                     :rotate="currentRotation"
                :page="currentPage"></pdf>
            <p class="mg-t-10 d-flex justify-content-between" v-if="pdf_url !== null">
                <button :disabled="currentPage < 2" :class="{'disabled': currentPage < 2}" class="btn btn-sm btn-primary" @click="previousPage">Previous</button>
                <button class="btn btn-sm btn-primary" @click="rotateAntiClockwise"><i class="fas fa-undo"></i></button>
                <span class="tx-white">{{currentPage}}/{{pageCount}}</span>
                <button class="btn btn-sm btn-primary" @click="rotateClockwise"><i class="fas fa-redo"></i></button>
                <button :disabled="currentPage > (pageCount - 1)" :class="{'disabled': currentPage > (pageCount - 1)}" class="btn btn-sm btn-primary" @click="nextPage">Next</button>
            </p>
        </div>
    </div>
</template>

<script>
import pdf from 'vue-pdf';
import axios from 'axios';
import authHeader from '@/services/auth-header';
export default {
    name: "DriveoffPostFailed",
    components: {pdf},
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: false
        }
    },
    data(){
        return {
            pdf_url: null,
            currentPage: 1,
            pageCount: 0,
            currentRotation: 0,
        }
    },
    mounted(){
        this.generateNotice();
    },
    beforeUnmount(){
        if(this.pdf_url){
            window.URL.revokeObjectURL(this.pdf_url);
        }
    },
    methods: {
        generateNotice(){
            if(this.pdf_url){
                window.URL.revokeObjectURL(this.pdf_url);
            }
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/preview?client_id=${this.client_id}&notice_type=driveoff`, {
                responseType: 'arraybuffer',
                headers: authHeader()
            })
                .then(response => {
                    let blob = new Blob([response.data], {type: 'application/pdf'});
                    this.pdf_url = window.URL.createObjectURL(blob);
                })
                .catch(error => {
                    this.$error("Failed to generate preview!", error);
                    this.posting = false;
                })
        },
        nextPage(){
            if(this.currentPage < this.pageCount){
                this.currentPage++;
            }
        },
        previousPage(){
            if(this.currentPage > 1){
                this.currentPage--;
            }
        },
        rotateClockwise(){
          let current = this.currentRotation;
          current += 90;
          if(current > 360){
              current -= 360;
          }
          this.currentRotation = current;
        },
        rotateAntiClockwise(){
            let current = this.currentRotation;
            current -= 90;
            if(current > -360){
                current += 360;
            }
            this.currentRotation = current;
        },
    }
}
</script>