<template>
    <div>

        <p class="tx-white tx-bold">We have received a response from the DVLA. Please review the details provided below: </p>

        <p><strong>Name:</strong> {{response.name}}</p>
        <p><strong>Address:</strong> {{response.address_one}}</p>
        <p style="margin-left: 7.6ch;">{{response.address_two}}</p>
        <p style="margin-left: 7.6ch;">{{response.address_three}}</p>
        <p style="margin-left: 7.6ch;">{{response.address_four}}</p>

        <p><strong>Special Notes:</strong> {{response.special_notes}}</p>
        <p><strong>DVLA Reference:</strong> {{response.dvla_reference}}</p>

        <p class="tx-white">Before confirming, make sure to check for the following: </p>
        <ul>
            <li>Is is an emergency service registered vehicle? It may be undercover police</li>
            <li>Is it registered to the land owner?</li>
        </ul>

        <button class="btn btn-sm btn-success" @click="send">Confirm and Send</button>

        <div class="row" style="min-width: 80vw; margin-top: 20px;" v-if="preview_url.length > 0">
            <div class="col-6">

                <h4 style="color: white;">Notice Preview</h4>

                <iframe v-if="preview_url" :src="preview_url" width="100%" height="1000px" min-height="500px;"></iframe>

            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "EvParkingDvlaResponded",
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
            response: {
                name: "",
                address_one: "",
                address_two: "",
                address_three: "",
                address_four: "",
                special_notes: "",
                dvla_reference: "",
            },
            type: "englandpofa",
            preview_url: ""
        }
    },
    mounted() {
        axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/keeper?client_id=${this.client_id}`, {
            headers: authHeader()
        })
            .then(response => {
                this.response = response.data;
                this.downloadPreview();
            })
            .catch(error => {
                this.$error("Failed to load DVLA response.", error);
            });
    },
    beforeUnmount(){
        if(this.preview_url){
            window.URL.revokeObjectURL(this.preview_url);
        }
    },
    methods: {
        downloadPreview(){
            if(this.preview_url){
                window.URL.revokeObjectURL(this.preview_url);
            }
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/preview?site_id=${this.claim.site.id}&client_id=${this.client_id}&notice_type=${this.type}`, {
                responseType: 'arraybuffer',
                headers: authHeader()
            })
            .then(response => {
                let blob = new Blob([response.data], {type: 'application/pdf'});
                this.preview_url = window.URL.createObjectURL(blob);
            })
            .catch(error => {
                console.log(error);
                this.preview_url = "";
            })
        },
        send(){
            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/status`, {
                client_id: this.client_id,
                job: this.type,
                send: true
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$emit('setstatus', 'Mail Queue')
                this.$emit('changeMade');
                this.$emit('reloadClaim', {});
                this.$success("Successfully updated claim status")
            })
            .catch(error => {
                this.$error("Failed to create mail job.", error);
            })
        }
    }
}
</script>

<style scoped>

</style>
