<template>
    <div class="tx-white">
        <p class="tx-bold">This claim has been sent to the keeper of the vehicle.</p>

        <p v-if="claim.dates.date_posted_driver !== null"><strong>Date Sent: </strong> {{claim.dates.date_posted_driver}}</p>

        <p>The keeper has {{daysLeft}} days left to do 1 of the following: </p>
        <ul>
            <li>Pay the owed amount</li>
            <li>Appeal the claim</li>
        </ul>

        <p>If the keeper does not pay this claim will be automatically escalated.</p>

        <p class="tx-info">If you wish to mark this claim as paid or cancel it, please go to the <strong>Management</strong> tab. <a href="javascript:void(0);" @click="$emit('changetab', 'manage')" class="tx-bold">(See Management tab)</a></p>

    </div>
</template>

<script>
import SubmitButton from "@/components/SubmitButton";
import {DateTime} from "luxon";

export default {
    name: "DriveoffSentToKeeper",
    props: {
        claim: Object,
    },
    components: {
        SubmitButton,
    },
    computed: {
        daysLeft: function(){

            let dateDue = DateTime.fromFormat(this.claim.dates.date_posted_driver, 'dd/MM/yyyy').plus({days: 29});
            let today = DateTime.local();
            let dl = dateDue.diff(today, 'days').toObject().days;
            if(dl < 0){
                dl = 0;
            }
            return parseFloat(dl).toFixed(0);
        }
    }
}
</script>

<style scoped>

</style>
