<template>
    <div>
        <div class="br-chat-body" style="max-height: 600px; width: 100%; overflow-y: scroll;">
            <p v-if="notes.length < 1">No notes for this claim!</p>
            <div v-for="n in notes" v-bind:class="{'flex-row-reverse': n.log?.user_id === $store.state.auth.user.id || n.note?.user_id === $store.state.auth.user.id}" class="media">
                <div class="media-body" v-if="n.type == 'note'">
                    <div class="msg-wrapper">
                        <note :note="n.note.note" @open="openTicket"></note>
                    </div><!-- msg-wrapper -->
                    <div><span>{{n.note.created_at}}</span>&nbsp;by&nbsp;<span>{{n.note.user}}</span><a href="javascript:void(0);" class="mg-l-10 note-remove-link" v-if="$can('claims-fix-fee')" @click="removeNote(n.note)">Remove</a></div>
                </div><!-- media-body -->
                <div class="media-body" v-else>
                    <div class="msg-wrapper bg-dark">
                        <div class="msg-content">
                            <span style="color: white;">{{n.log.activity}}</span>
                        </div><!-- msg-content -->
                    </div>
                    <div><span>{{n.log.created_at}}</span>&nbsp;by&nbsp;<span>{{n.log.user}}</span></div>
                </div>
            </div>
        </div>

        <br/>
        <br/>
        <br/>

        <div class="br-chat-footer mg-t-20">
            <div><input v-model="newNote" type="text" class="form-control" placeholder="Write your message here..." @keydown.enter="createNote"></div>
            <nav class="nav">
                <a v-if="!sendingMessage" href="javascript:void(0);" @click="createNote" class="nav-link"><i class="icon ion-android-send"></i></a>
                <div v-else class="sk-wave">
                    <div class="sk-rect sk-rect1 bg-white"></div>
                    <div class="sk-rect sk-rect2 bg-white"></div>
                    <div class="sk-rect sk-rect3 bg-white"></div>
                    <div class="sk-rect sk-rect4 bg-white"></div>
                    <div class="sk-rect sk-rect5 bg-white"></div>
                </div>
            </nav>
        </div>

        <div :id="'ticketModal-' + claim.reference" class="modal fade" style="display: none; padding-right: 17px;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document" style="max-width: 80%;">
                <div class="modal-content bg-dark tx-white tx-size-sm">
                    <div class="modal-header bg-dark pd-x-20">
                        <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">View Ticket: #{{ticket.id}}</h6>
                        <button type="button" class="close tx-white" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div v-if="ticket.id" class="modal-body bg-dark pd-20" style="max-height: 75vh; overflow-y: scroll;">

                        <p><strong>Provided Reference: </strong>{{ticket.custom_fields.cf_customer_reference_number || "None"}}</p>

                        <div class="br-msg-body bg-light">
                            <h6 class="tx-inverse mg-b-25 lh-4">{{ticket.subject}}</h6>

                            <div class="tx-inverse" v-html="ticket.description"></div>
                        </div>

                        <div v-for="msg in ticket.conversations" class="br-msg-body bg-light">
                            <h6 class="tx-inverse mg-b-25 lh-4">{{msg.from_email}} to {{msg.to_emails.join(', ')}}</h6>

                            <div class="tx-inverse" v-html="msg.body.split('<blockquote></blockquote>')[0]"></div>
                        </div>

                    </div>
                    <div class="modal-footer bg-dark tx-white">
                        <button :id="'closeTicketModal-' + claim.reference" type="button" class="btn btn-secondary tx-size-xs" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import {DateTime} from "luxon";
import axios from 'axios';
import authHeader from '@/services/auth-header';
import Note from "./Note";

export default {
    name: "ClaimNotes",
    components: {Note},
    props: {
        claim: Object,
        client_id: {
            type: [Number, String]
        }
    },
    data(){
        return {
            newNote: "",
            sendingMessage: false,
            ticket: {},
            claimNotes: [],
            notes: [],
            logs: [],
            deleting: false
        }
    },
    mounted(){
        this.getLogs();
        this.getNotes();
        this.updateScroll();
        this.emitter.on(`${this.claim.reference}-new-note`, this.getNotes);
    },
    beforeUnmount(){
        this.emitter.off(`${this.claim.reference}-new-note`, this.getNotes);
    },
    methods: {
        getLogs(){
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/logs`, {
                params: {
                    client_id: this.client_id
                },
                headers: authHeader()
            })
            .then(response => {
                let claimLogs = response.data.logs;
                let processedLogs = [];
                claimLogs.forEach((log) => {
                    if(log.activity.includes('Locked') || log.activity.includes('Restricted') || log.activity.includes('Unlocked')){
                        log._ts = log.created_at;
                        log.created_at = DateTime.fromSeconds(log.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
                        processedLogs.push(log);
                    }
                });
                this.logs = processedLogs.sort((a, b) => {
                    return b._ts - a._ts;
                });
                this.processNotes();
            })
            .catch(err => {
                this.$error(err?.response?.data?.message || "Failed to load logs.", err);
            })
        },
        getNotes(){
            console.log(`Loading notes`);
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/notes`, {
                params: {
                    client_id: this.client_id
                },
                headers: authHeader()
            })
            .then(response => {
                this.claimNotes = response.data.notes;
                setTimeout(() => {
                    this.updateScroll();
                }, 500);
                this.processNotes();
            })
            .catch(err => {
                this.$error(err?.response?.data?.message || "Failed to load notes.", err);
            })
        },
        processNotes(){
            let notes = this.claimNotes;
            let processedNotes = [];
            notes.forEach((note) => {
                if(!note._ts){
                    note._ts = note.created_at;
                    note.created_at = DateTime.fromSeconds(note.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
                }
                processedNotes.push({
                    type: 'note',
                    note: note,
                    _ts: note._ts
                })
            });
            this.logs.forEach((log) => {
                processedNotes.push({
                    type: 'log',
                    log: log,
                    _ts: log._ts
                })
            });
            this.notes = processedNotes.sort((a, b) => {
                return a._ts - b._ts;
            });
            this.$emit('notecount', this.notes.length);
        },
        openTicket(id){
            console.log("Open Ticket: ", id);
            axios.get(`https://api.varsanpr.com/api/misc/freshdesk/${id}`, {
                headers: authHeader()
            })
            .then(response => {
                this.ticket = response.data.ticket;
                $('#ticketModal-' + this.claim.reference).modal('toggle');
            })
            .catch(error => {
                this.$error("Failed to load ticket.", error);
            })
        },
        createNote(){
            if(this.sendingMessage) return;
            this.sendingMessage = true;
            axios.put(`https://api.varsanpr.com/api/claims/${this.claim.id}/notes`, {
                client_id: this.client_id,
                note: this.newNote
            }, {
                headers: authHeader()
            }).then(response => {
                response.data.created_at = DateTime.fromSeconds(response.data.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
                response.data.user = response.data.user.email;
                // this.$emit('new-note', response.data);
                this.getNotes();
                this.$emit('changeMade');
                this.sendingMessage = false;
                this.newNote = "";
            })
            .catch(error => {
                this.$error("Failed to create new note.", error);
                this.sendingMessage = false;
            })
        },
        removeNote(note){
            if(this.deleting) return;
            this.deleting = true;
            axios.delete(`https://api.varsanpr.com/api/claims/${this.claim.id}/notes/${note.id}`, {
                data: {
                    client_id: this.$store.state.auth.user.selectedClient
                },
                headers: authHeader()
            })
            .then(response => {
                this.getNotes();
                this.$emit('changeMade');
            })
            .catch(error => {
                this.$error("Failed to remove note.", error);
            })
            .finally(() => {
                this.deleting = false;
            });
        },
        updateScroll(){
            var element = document.getElementsByClassName("br-chat-body")[0];
            element.scrollTop = element.scrollHeight;
        }
    }
}
</script>

<style scoped>
    .note-remove-link{
        font-size: 11px !important;
        color: #DC3545 !important;
    }

    .note-remove-link:hover {
        text-decoration: underline !important;
    }
</style>
