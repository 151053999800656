<template>
    <div>
        <Loader :show="loading" message="Loading Claims..."></Loader>
        <div v-if="!loading && claim?.reference" class="row">

            <div class="col-12">

                <div class="card bd-0 mg-b-20">
                    <div class="card-header bg-gray-700 tx-white">
                        <ul class="nav nav-tabs nav-tabs-for-dark card-header-tabs">

                            <!-- INFORMATION -->
                            <li class="nav-item">
                                <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.main}" href="javascript:void(0);" @click="selectTab('main')">Claim Information</a>
                            </li>

                            <!-- APPEALS -->
                            <li class="nav-item" v-show="numberOfAppeals > 0">
                                <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.appeals}" href="javascript:void(0);" @click="selectTab('appeals')"><span class="tx-warning">Appeals ({{ numberOfAppeals }})</span></a>
                            </li>

                            <!-- CANCELLATION REQUESTS -->
                            <li class="nav-item" v-show="numberOfCancellations > 0">
                                <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.cancellations}" href="javascript:void(0);" @click="selectTab('cancellations')"><span class="tx-danger">Cancellation Requests ({{ numberOfCancellations }})</span></a>
                            </li>

                            <!-- NOTES-->
                            <li class="nav-item">
                                <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.notes}" href="javascript:void(0);" @click="selectTab('notes')">Notes <span v-if="(noteCount || 0) > 0">({{noteCount}})</span></a>
                            </li>

                            <!-- VEHICLE -->
                            <li class="nav-item">
                                <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.vehicle, 'disabled': (pendingLock && claim.status !== 'DVLA Review')}" href="javascript:void(0);" @click="(pendingLock && claim.status !== 'DVLA Review') ? null : selectTab('vehicle')">Vehicle</a>
                            </li>

                            <!-- HISTORY -->
                            <li class="nav-item" v-show="numberOfHistoricClaims > 0">
                                <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.history}" href="javascript:void(0);" @click="selectTab('history')">History ({{ numberOfHistoricClaims }})</a>
                            </li>

                            <!-- CUSTOMER PARKING -->
                            <li class="nav-item" v-if="claim.type === 'parking'">
                                <a class="nav-link text-white bd-0 pd-y-8" v-bind:class="{'active pd-y-8': tabs.customerparking}" href="javascript:void(0);" @click="selectTab('customerparking')">Customer Parking</a>
                            </li>

                            <!-- UPLOADED EVIDENCE -->
                            <li class="nav-item">
                                <a class="nav-link text-white bd-0" v-bind:class="{'active pd-y-8': tabs.evidence, 'disabled': pendingLock}" href="javascript:void(0);" @click="pendingLock ? null : selectTab('evidence')">Uploaded Evidence ({{ claim.assets.length }})</a>
                            </li>

                            <!-- MANAGE -->
                            <li class="nav-item">
                                <a class="nav-link text-white bd-0" v-bind:class="{'active pd-y-8': tabs.manage}" href="javascript:void(0);" @click="selectTab('manage')">Manage</a>
                            </li>

                            <!-- REMINDERS -->
                            <li class="nav-item">
                                <a class="nav-link text-white bd-0" v-bind:class="{'active pd-y-8': tabs.reminders}" href="javascript:void(0);" @click="selectTab('reminders')">Reminders ({{ numberOfReminders }})</a>
                            </li>

                            <!-- PAYMENTS -->
                            <li class="nav-item">
                                <a class="nav-link text-white bd-0" v-bind:class="{'active pd-y-8': tabs.payments}" href="javascript:void(0);" @click="selectTab('payments')">Payments <span v-show="numberOfPayments > 0 || numberOfDeductions > 0">({{numberOfPayments}}) & Deductions ({{ numberOfDeductions }})</span></a>
                            </li>

                            <!-- LOGS -->
                            <li class="nav-item">
                                <a class="nav-link text-white bd-0" v-bind:class="{'active pd-y-8': tabs.logs}" href="javascript:void(0);" @click="selectTab('logs')">Logs</a>
                            </li>
                            
                            <!-- CONTACTS -->
                            <li class="nav-item">
                                <a class="nav-link text-white bd-0" v-bind:class="{'active pd-y-8': tabs.contacts}" href="javascript:void(0);" @click="selectTab('contacts')">Contacts</a>
                            </li>

                            <!-- SITE -->
                            <li class="nav-item">
                                <a class="nav-link text-white bd-0" v-bind:class="{'active pd-y-8': tabs.site}" href="javascript:void(0);" @click="selectTab('site')">Site</a>
                            </li>
                
                        </ul>
                    </div>
                    <div class="card-body">

                        <div v-show="viewers.length > 0" class="alert alert-info" role="alert">
                            <strong class="d-block d-sm-inline-block-force">{{ viewers.length }} people viewing this claim</strong>
                            <ul>
                                <li v-for="viewer in viewers" :key="viewer.user.id">
                                    <strong>{{ viewer.user.email }}</strong>
                                </li>
                            </ul>
                        </div><!-- alert -->

                        <claim-information :claim="claim" :client_id="clientID" v-show="tabs.main" @refresh="loadClaim" @reloadClaim="loadClaim" @changetab="selectTab"
                        @pendingLock="(value) => pendingLockOverride = value"
                            @eventLock="(value) => eventsLockOverride = value"
                            @evidenceLock="(value) => evidenceLockOverride = value" @changeMade="handleChange"></claim-information>
                        <claim-notes :ref="'claim_notes_' + claim_id" :claim="claim" :client_id="clientID" v-show="tabs.notes" @notecount="(count) => noteCount = count" @changeMade="handleChange"></claim-notes>
                        <claim-appeals :claim="claim" :client_id="clientID" v-show="tabs.appeals" @appealcount="(count) => numberOfAppeals = count" @changeMade="handleChange"></claim-appeals>
                        <claim-cancellations :ref="'claim_cancellations_' + claim_id" :claim="claim" :client_id="clientID" v-show="tabs.cancellations" @changeMade="handleChange"></claim-cancellations>
                        <claim-contacts :claim="claim" :client_id="clientID" @refresh="loadClaim" v-show="tabs.contacts" @changeMade="handleChange"></claim-contacts>
                        <claim-customer-parking :claim="claim" :client_id="clientID" v-if="claim.type === 'parking'" v-show="tabs.customerparking"></claim-customer-parking>
                        <claim-evidence :ref="'claim_evidence_' + claim_id" :claim="claim" :client_id="clientID" v-show="tabs.evidence" @reload="loadClaim" @changeMade="handleChange"></claim-evidence>
                        <claim-history :claim="claim" :client_id="clientID" v-show="tabs.history" @open-claim="(c) => $emit('open-claim', c)" @history="(count) => numberOfHistoricClaims = count"></claim-history>
                        <claim-logs-and-jobs :claim="claim" :client_id="clientID" v-show="tabs.logs"></claim-logs-and-jobs>
                        <claim-management :claim="claim" :client_id="clientID" v-show="tabs.manage" @update="loadClaim" @lockFee="(lock) => claim.lock_lower_fee = (lock ? 1 : 0)" @changeMade="handleChange" ></claim-management>
                        <claim-payments :claim="claim" :client_id="clientID" v-show="tabs.payments" @payments="(count) => numberOfPayments = count" @deductions="(count) => numberOfDeductions = count" @changeMade="handleChange"></claim-payments>
                        <claim-reminders :claim="claim" :client_id="clientID" v-show="tabs.reminders" @reminders="(count) => numberOfReminders = count" @changeMade="handleChange"></claim-reminders>
                        <claim-site :claim="claim" :client_id="clientID" @reload="loadClaim" v-show="tabs.site"></claim-site>
                        <vehicle :claim="claim" :client_id="clientID" v-show="tabs.vehicle" @reload="loadClaim" @changeMade="handleChange"></vehicle>

                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>

/**
 * NOTES:
 * 
 * - Appeals: only show if there is an appeal
 * - Cancellations: only show if there is a cancellation request
 */

import Loader from '@/components/Loader.vue';
import axios from 'axios';
import authHeader from '@/services/auth-header';
import {DateTime} from 'luxon';

// Claim Components
import ClaimInformation from './components/ClaimInformation.vue';
import ClaimNotes from './components/ClaimNotes.vue';
import ClaimAppeals from './components/ClaimAppeals.vue';
import ClaimCancellations from './components/ClaimCancellations.vue';
import ClaimContacts from './components/ClaimContacts.vue';
import ClaimCustomerParking from './components/ClaimCustomerParking.vue';
import ClaimEvidence from './components/ClaimEvidence.vue';
import ClaimHistory from './components/ClaimHistory.vue';
import ClaimLogsAndJobs from './components/ClaimLogsAndJobs.vue';
import ClaimManagement from './components/ClaimManagement.vue';
import ClaimPayments from './components/ClaimPayments.vue';
import ClaimReminders from './components/ClaimReminders.vue';
import ClaimSite from './components/ClaimSite.vue';
import Vehicle from './components/Vehicle.vue';

export default {
    name: 'Claim',
    components: {
        Loader,
        ClaimInformation,
        ClaimNotes,
        ClaimAppeals,
        ClaimCancellations,
        ClaimContacts,
        ClaimCustomerParking,
        ClaimEvidence,
        ClaimHistory,
        ClaimLogsAndJobs,
        ClaimManagement,
        ClaimPayments,
        ClaimReminders,
        ClaimSite,
        Vehicle
    },
    props: {
        claim_id: {
            type: [Number, String],
            required: false
        },
        client_id: {
            type: [Number, String],
            required: false
        },
        presence: {
            type: Array,
            required: false
        }
    },
    data(){
        return {
            loading: true,
            tabs: {
                main: true,
                evidence: false,
                data: false,
                manage: false,
                vehicle: false,
                logs: false,
                notes: false,
                appeals: false,
                cancellations: false,
                history: false,
                customerparking: false,
                reminders: false,
                payments: false,
                contacts: false,
                site: false
            },
            claim: {
                type: 'driveoff',
                id: -1,
                payments: [],
                appeals: [],
                notes: [],
                logs: []
            },
            numberOfPayments: 0,
            numberOfDeductions: 0,
            numberOfReminders: 0,
            numberOfHistoricClaims: 0,
            numberOfAppeals: 0,
            numberOfCancellations: 0,
            noteCount: 0,
            clientID: null,
            pendingLockOverride: false,
            uploadedEvidenceLockOverride: false,
            eventsLockOverride: false,
            hasChanged: false,
            opened_at: DateTime.local().toSeconds()
        }
    },
    // beforeUnmount(){
    //     axios.put(`https://api.varsanpr.com/api/claims/${this.claim.id}/viewduration`, {
    //         client_id: this.clientID,
    //         opened_at: this.opened_at
    //     }, {
    //         headers: authHeader()
    //     })
    //     .catch(err => {
    //         console.log(err);
    //     });
        
    //     if(!this.$can('claims-manage-team')){
    //         let reason = prompt(`Please explain why you needed to view this claim?`);
    //         if(reason == null){
    //             // User cancelled
    //             this.$emit('open-claim', {
    //                 id: this.claim.id,
    //                 reference: this.claim.reference,
    //             });
    //             return;
    //         }
    //         if(reason.length < 2){
    //             reason = 'User failed to provide a justification for viewing the claim.';
    //         }

    //         axios.put(`https://api.varsanpr.com/api/claims/${this.claim.id}/viewing`, {
    //             client_id: this.clientID,
    //             reason: reason
    //         }, {
    //             headers: authHeader()
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    //     }
    // },
    beforeUnmount(){
        this.emitter.off(`claim_${this.claim_id}_mmc_update`);
        this.emitter.off(`claim_${this.claim_id}_trigger_next`);
    },
    mounted(){
        this.claim.id = this.claim_id;
        this.clientID = this.$store.state.auth.user.selectedClient;
        if(typeof this.client_id === 'number'){
            this.clientID = this.client_id;
        }
        if(this.$route.query.cleint_id && this.$route.query.src == 'teams'){
            this.clientID = parseInt(this.$route.query.client_id);
        }

        this.loadClaim();

        this.emitter.on(`claim_${this.claim_id}_mmc_update`, (data) => {
            this.claim.vehicle_make = data.make;
            this.claim.vehicle_model = data.model;
            this.claim.vehicle_colour = data.colour;
            this.claim.fuelType = data.fuelType;
            this.claim.override_mmc = 1;
        });

        this.emitter.on(`claim_${this.claim_id}_trigger_next`, () => {
            this.$emit('triggerNext', this.claim);
        });
    },
    methods: {
        loadClaim(reload = false){
            this.loading = true;
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}?client_id=${this.clientID}&reload=${reload}`, {
                headers: authHeader()
            })
            .then(response => {
                if(!response.data) return;
                for (let date in response.data.claim.dates){
                    if(response.data.claim.dates.hasOwnProperty(date)){
                        if(date.includes('date_') && response.data.claim.dates[date] !== null){
                            response.data.claim.dates[date] = DateTime.fromSeconds(response.data.claim.dates[date]).toFormat('dd/MM/yyyy');
                        }
                    }
                }
                if(response.data.claim.lastEvent){
                    response.data.claim.lastEvent.created_at = DateTime.fromSeconds(response.data.claim.lastEvent.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
                }
                response.data.claim.payments = [];
                response.data.claim.deductions = [];
                this.claim = response.data.claim;

                this.numberOfCancellations = this.claim.cancellations.length;
            })
            .catch(error => {
                this.$error(error.response?.data?.message || error.message || error, error);
            })
            .finally(() => {
                this.loading = false;
            })
        },
        selectTab(tab){
            for (let t in this.tabs){
                this.tabs[t] = false;
            }
            this.tabs[tab] = true;
        },
        handleChange(){
            this.hasChanged = true;
        },
        async triggerClose(){
            axios.put(`https://api.varsanpr.com/api/claims/${this.claim.id}/viewduration`, {
                client_id: this.clientID,
                opened_at: this.opened_at
            }, {
                headers: authHeader()
            })
            .catch(err => {
                console.log(err);
            });
            
            if(!this.$can('claims-manage-team')){
                let reason = prompt(`Please explain why you needed to view this claim? (${this.claim.reference})`);
                if(reason == null){
                    // User cancelled
                    // this.$emit('open-claim', {
                    //     id: this.claim.id,
                    //     reference: this.claim.reference,
                    // });
                    console.log('Reason is null, false')
                    return false;
                }
                if(reason.length < 2){
                    return false;
                }

                return await axios.put(`https://api.varsanpr.com/api/claims/${this.claim.id}/viewing`, {
                    client_id: this.clientID,
                    reason: reason
                }, {
                    headers: authHeader()
                })
                .then(response => {
                    console.log(true)
                    return true;
                })
                .catch(error => {
                    console.log(error);
                    console.log(false)
                    return false;
                });
            }else{
                console.log(true);
                return true;
            }
        },
        newNote(message){
            this.emitter.emit(`${message.reference}-new-note`, message);
        },
        newEvidence(message){
            this.$info(message.reference + ' A new piece of evidence has been uploaded. Reload the claim to view!');
            // this.$refs['claim_evidence_' + this.claim.id][0].getEvidence();
        },
        newCancellation(message){
            this.$info(message.reference + ' A new cancellation request has been submitted. Reload the claim to view!');
            // this.$refs['claim_cancellations_' + this.claim.id][0].getCancellations();
        }
    },
    computed: {
        pendingLock(){
            if(parseInt(this.$store.state.auth.user.id) === 3 || parseInt(this.$store.state.auth.user.id) === 511) return false;
            if(this.claim.status === 'DVLA Review') return true;
            return (this.claim.status === 'Pending' || this.claim.status === 'DVLA Review') && this.claim.type !== 'parking' && this.claim.type !== 'evparking' && this.claim.progress_message !== 'Tampered Plate' && !this.pendingLockOverride;
        },
        uploadedEvidenceLock(){
            return this.claim.status === 'Pending' && this.claim.type !== 'parking' && this.claim.type !== 'evparking' && this.claim.progress_message !== 'Tampered Plate' && !this.uploadedEvidenceLockOverride;
        },
        eventsLock(){
            if(this.claim.status === 'DVLA Review') return true;
            return (this.claim.status === 'Pending' || this.claim.status === 'DVLA Review') && this.claim.type !== 'parking' && this.claim.type !== 'evparking' && this.claim.progress_message !== 'Tampered Plate' && !this.eventsLockOverride;
        },
        isViewed(){
            return this.presence.filter((entry) => {
                return entry.reference == this.claim.reference && entry.user.id != this.$store.state.auth.user.id;
            }) > 0;
        },
        viewers() {
            return this.presence.filter((entry) => {
                return entry.reference == this.claim.reference && entry.user.id != this.$store.state.auth.user.id;
            });
        }
    }
}
</script>

<style scoped>

.card-header-tabs.nav-tabs-for-dark .nav-link.active {
    border-bottom: 2px solid white;
    background-color: transparent;
}

.card-header-tabs.nav-tabs-for-dark .nav-link:hover {
    background-color: transparent;
    border-bottom: 2px solid white;
    font-weight: bold;
}

.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

</style>