<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h1 class="tx-white">Site Signage - {{site.name}}</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-4 mg-b-20">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group">
                            <div class="custom-file">
                                <input type="file" accept="image/*" ref="file" @change="selectFile" class="custom-file-input">
                                <label class="custom-file-label">{{signage_image}}</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control form-control-dark" v-model="name" placeholder="Name of file">
                        </div>
                        <button class="btn btn-sm btn-success" :disabled="readyToSubmit" :class="{'disabled': readyToSubmit}" @click="upload">Upload Image</button>
                    </div>
                </div>
            </div>
            <div v-for="s in signage" class="col-4 mg-b-20">
                <div class="card">
                    <div class="card-body">
                        <secure-image :endpoint="`/api/sites/${site.id}/signage/`" :filename="s.filename" :site_id="site.id" style="width: 100%; height: auto;"></secure-image>
<!--                        <img alt="Signage image" :src="s.src" style="width: 100%;">-->
                        <h3 class="tx-white"><strong>Name:</strong> {{s.name}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {DateTime} from "luxon";
import SecureImage from "@/components/SecureImage";
import axios from 'axios';
import authHeader from "@/services/auth-header";

export default {
    name: "Signage",
    props: {
        site: Object,
    },
    components: {
        SecureImage
    },
    data(){
        return {
            signage: [],
            file: null,
            name: ""
        }
    },
    mounted() {
        axios.get(`https://api.varsanpr.com/api/sites/${this.site.id}/signage`, {
            headers: authHeader()
        })
        .then(response => {
            response.data.signage.forEach((s) => {
                s.created_at = DateTime.fromSeconds(s.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
                s.src = `https://api.varsanpr.com/api/sites/${this.site.id}/signage/${s.filename}`;
            });
            this.signage = response.data.signage;
        })
        .catch(error => {
            this.$error("Failed to load signage.", error);
        });
    },
    methods: {
        selectFile(event){
            this.file = event.target.files[0];
            this.name = this.file.name.split('.')[0];
        },
        upload(){
            if(this.name.length < 1) return;
            const params = new FormData();
            params.append('file', this.file, this.file.name);
            params.append('name', this.name);
            axios.put(`https://api.varsanpr.com/api/sites/${this.site.id}/signage`, params, {
                headers: authHeader()
            })
                .then(response => {
                    response.data.signage.forEach((s) => {
                        s.created_at = DateTime.fromSeconds(s.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
                        s.src = `https://api.varsanpr.com/api/sites/${this.site.id}/signage/${s.filename}`;
                    });
                    this.signage = response.data.signage;
                    this.$success("Uploaded image");
                })
                .catch(error => {
                    this.$error("Failed uploading document.", error);
                });
        },
    },
    computed: {
        signage_image: function(){
            if(this.file == null){
                return "Select File";
            }
            if(this.file.name == null){
                return "File Selected";
            }
            return this.file.name;
        },
        readyToSubmit: function(){
            return !(this.file !== null && this.name.length > 0);
        }
    }
}
</script>

<style scoped>

</style>