<template>
    <div>
        <div class="row mg-t-20">
            <div class="col-lg-12">
                <div class="alert alert-info mg-b-10" role="alert">
                    <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <strong class="d-block d-sm-inline-block-force">Heads up!</strong> When you accept or reject a request, the user will receive an email notifying them. This will include the reason provided if you reject it.
                </div><!-- alert -->

                <table width="100%" class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                    <tr>
                        <th>User Requested</th>
                        <th>Reason</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="entry in claim.cancellations" style="cursor: pointer">
                        <th>{{entry.email}}</th>
                        <td>{{entry.reason}}</td>
                        <td v-if="entry.rejected_at !== null">
                            Rejected: {{entry.rejection_reason}}
                        </td>
                        <td v-else-if="entry.accepted_at !== null">
                            Accepted
                        </td>
                        <td v-else>
                            <button class="btn btn-sm btn-primary" @click="acceptRequest(entry)">Accept Request</button>
                            <button class="btn btn-sm btn-danger" @click="rejectRequest(entry)">Reject Request</button>
                        </td>
                    </tr>
                    <tr v-if="claim.cancellations.length < 1 && !searching">
                        <td colspan="4" class="text-center">No Cancellations</td>
                    </tr>
                    <tr v-if="searching">
                        <td colspan="4" class="text-center">
                            <div class="spinner-border text-secondary m-2" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import {DateTime} from "luxon";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "ClaimCancellations",
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
            searching: false,
            updating: false
        }
    },
    methods: {
        async acceptRequest(entry){

            if(this.updating) return;
            this.updating = true;

            if(this.claim.type == 'parking' || this.claim.type == 'evparking'){
                let {value: category} = await Swal.fire({
                title: 'Please select a cancellation category',
                text: 'This will be used by customers to understand the reasons their claims are being cancelled. If you are unsure which one to select please speak to your manager.',
                icon: 'warning',
                input: 'select',
                inputValue: 'Other',
                inputOptions: {
                    'Duplicate Claim': 'Duplicate Claim',
                    'Tampered Plate': 'Tampered Plate',
                    'Cloned Plate': 'Cloned Plate',
                    'Fake Plate': 'Fake Plate',
                    'Foreign Plate': 'Foreign Plate',
                    'No Plate': 'No Plate',
                    'Requested by Site': 'Requested by Site',
                    'Stolen Plates/Vehicle': 'Stolen Plates/Vehicle',
                    'Other': 'Other'
                },
            })

                  axios.post(`https://api.varsanpr.com/api/cancellations/accept`, {
                      claim_id: entry.claim_id,
                      user_id: entry.user_id,
                      client_id: entry.client_id,
                      paid_on_site: false,
                        category: category
                  }, {
                    headers: authHeader()
                  })
                      .then(response => {
                        this.$success("Cancellation has been accepted.");
                        entry.accepted_at = DateTime.local().toSeconds();
                        entry.accepted = 1;
                        this.$emit('changeMade');
                        this.$emit("update");
                      })
                      .catch(error => {
                          console.log(error);
                          this.$error("Failed to cancel claim.", error);
                      })
                        .finally(() => {
                            this.updating = false;
                        });

            }else{
                await Swal.fire({
                title: `Is this being cancelled because it was Paid on Site?`,
                icon: "warning",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No'
            })
            .then(async (result) => {

                console.log("Result: ", result);

                if(result.isDismissed){
                    this.updating = false;
                    return;
                }else{
                    let {value: category} = await Swal.fire({
                title: 'Please select a cancellation category',
                text: 'This will be used by customers to understand the reasons their claims are being cancelled. If you are unsure which one to select please speak to your manager.',
                icon: 'warning',
                input: 'select',
                inputValue: 'Other',
                inputOptions: {
                    'Duplicate Claim': 'Duplicate Claim',
                    'Tampered Plate': 'Tampered Plate',
                    'Cloned Plate': 'Cloned Plate',
                    'Fake Plate': 'Fake Plate',
                    'Foreign Plate': 'Foreign Plate',
                    'No Plate': 'No Plate',
                    'Requested by Site': 'Requested by Site',
                    'Stolen Plates/Vehicle': 'Stolen Plates/Vehicle',
                    'Other': 'Other'
                },
            })

                  axios.post(`https://api.varsanpr.com/api/cancellations/accept`, {
                      claim_id: entry.claim_id,
                      user_id: entry.user_id,
                      client_id: entry.client_id,
                      paid_on_site: result.isConfirmed,
                        category: category
                  }, {
                    headers: authHeader()
                  })
                      .then(response => {
                        this.$success("Cancellation has been accepted.");
                        entry.accepted_at = DateTime.local().toSeconds();
                        entry.accepted = 1;
                        this.$emit('changeMade');
                        this.$emit("update");
                      })
                      .catch(error => {
                          console.log(error);
                          this.$error("Failed to cancel claim.", error);
                      })
                        .finally(() => {
                            this.updating = false;
                        });
                }

            });
            }
        },
        async rejectRequest(entry){
            if(this.updating) return;
            this.updating = true;
            const {value: reason} = await Swal.fire({
                title: `Please explain your reason for rejecting this request`,
                input: 'textarea',
                inputPlaceholder: 'Please explain why you are rejecting this request',
                showCancelButton: true
            });

            if(reason){
                axios.post(`https://api.varsanpr.com/api/cancellations/reject`, {
                    reason: reason,
                    claim_id: this.claim.id,
                    user_id: entry.user_id,
                    client_id: entry.client_id
                }, {
                    headers: authHeader()
                })
                    .then(response => {
                        this.$success("Cancellation request rejected.");
                        entry.rejected_at = DateTime.local().toSeconds();
                        entry.accepted = 0;
                        this.$emit('changeMade');
                    })
                    .catch(error => {
                        console.log(error);
                        this.$error("Unable to reject request.");
                    })
                    .finally(() => {
                        this.updating = false;
                    });
            }else{
                this.updating = false;
            }
        }
    }
}
</script>

<style scoped>

</style>
