<template>

  <div class="card bd-0 mg-b-20" style="background: transparent;">
    <div class="card-header bg-secondary tx-white">
        <ul class="nav nav-tabs nav-tabs-for-dark card-header-tabs">
            <li class="nav-item">
                <a class="nav-link text-white bd-0 pd-y-8 ft-left" :class="{'active pd-y-8': tab === 'logs'}" href="javascript:void(0);" @click="tab = 'logs'">Logs</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white bd-0 pd-y-8 ft-left" :class="{'active pd-y-8': tab === 'jobs'}" href="javascript:void(0);" @click="tab = 'jobs'">Mail Jobs</a>
          </li>
        </ul>
    </div><!-- card-header -->
    <div class="card-body" style="background: transparent;">
      <claim-logs :claim="claim" :client_id="client_id" v-show="tab === 'logs'"></claim-logs>
      <claim-mail-jobs :claim="claim" :client_id="client_id" v-show="tab === 'jobs'"></claim-mail-jobs>
    </div>
  </div>

</template>

<script>
import ClaimLogs from './ClaimLogs';
import ClaimMailJobs from './ClaimMailJobs';

export default {
  name: 'ClaimLogsAndJobs',
  components: {
    ClaimLogs,
    ClaimMailJobs
  },
  props: {
    claim: {
      type: Object,
      required: true
    },
    client_id: {
      type: [Number, String],
      required: true
    }
  },
  data(){
    return {
      tab: 'logs'
    }
  }
}
</script>

<style scoped>
.card-header-tabs.nav-tabs-for-dark .nav-link.active {
  border-bottom: 2px solid white;
  background-color: transparent;
}

.card-header-tabs.nav-tabs-for-dark .nav-link:hover {
  background-color: transparent;
  border-bottom: 2px solid white;
  font-weight: bold;
}
</style>