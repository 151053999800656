<template>
    <div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">

                <div class="card">
                    <div class="card-header tx-white">
                        Mailing List - {{site_name}}
                    </div>
                    <div v-show="loading" class="card-body">
                        <loader :show="loading"></loader>
                    </div>
                    <div v-show="!loading" class="card-body">

                        <p class="tx-medium tx-info">When considering adding a user to this mailing list, please consider the fact that they will receive any emails which are
                        toggled on for this site.</p>

                        <div class="form-group mg-b-10">
                            <label class="d-block">User</label>
                            <div>
                                <select v-model="selected_user" class="form-control form-control-dark select2" v-select2>
                                    <option v-for="u in users" :key="u.id" v-bind:value="u.id">{{u.email}}</option>
                                </select>
                            </div>
                        </div><!-- form-group -->

                        <button class="btn btn-sm btn-success" @click="addUser">Add User</button>

                    </div>
                </div>

            </div>
            <div class="col-lg-8 col-md-6 col-sm-12">
                <div class="card">
                    <div v-if="loadingExisitng" class="card-body">
                        <loader :show="loadingExisitng"></loader>
                    </div>
                    <div v-else class="card-body">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Email</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="u in list">
                                    <td>{{u.id}}</td>
                                    <td>{{u.email}}</td>
                                    <td><button class="btn btn-sm btn-danger" @click="removeUser(u.id)">Remove</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from "@/components/Loader";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "MailingList",
    props: {
        site_id: Number,
        site_name: String,
    },
    components: {Loader},
    data(){
        return {
            users: [],
            list: [],
            selected_user: null,
            loading: true,
            loadingExisitng: true,
        }
    },
    mounted(){
        axios.get(`https://api.varsanpr.com/api/users/list`, {
            headers: authHeader()
        })
        .then(response => {
            this.users = response.data.users;
            this.loading = false;
            $(".select2").select2();
        })
        .catch(error => {
            this.$error("Failed to load user list. Please reload the page to try again.");
            this.loading = false;
        });
        axios.get(`https://api.varsanpr.com/api/sites/${this.site_id}/mailing`, {
            headers: authHeader()
        })
            .then(response => {
                this.list = response.data.list;
                this.loadingExisitng = false;
            })
            .catch(error => {
                this.$error("Failed to load site mailing list. Please reload the page to try again.");
                this.loadingExisitng = false;
            });
    },
    methods: {
        addUser(){
            if(this.selected_user === null) return;
            if(this.selected_user === -1) return;

            axios.put(`https://api.varsanpr.com/api/sites/${this.site_id}/mailing`, {
                user_id: this.selected_user
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.list.push({id: this.selected_user, email: this.users.filter((u) => {return u.id === this.selected_user})[0].email});
                this.$success("Added user to mailing list.");
            })
            .catch(error => {
                this.$error("Failed to add user to the mailing list.");
            });
        },
        removeUser(id){
            axios.delete(`https://api.varsanpr.com/api/sites/${this.site_id}/mailing`, {
                data: {
                    user_id: id
                },
                headers: authHeader()
            })
                .then(response => {
                    this.list = this.list.filter((e) => {
                        return e.id !== id;
                    });
                    this.$success("Removed user from mailing list.");
                })
                .catch(error => {
                    this.$error("Failed to remove user from the mailing list.");
                });
        }
    }
}
</script>

<style scoped>

</style>