<template>
    <div class="row">
        <div :class="{'col-6': pdf_url !== null, 'col-12': pdf_url === null}">
            <p>System failed to send mail for this notice. Please review the error message and proceed when the issue has been resolved.</p>

            <p><strong>Error: </strong> {{claim.post_failure_reason}}</p>

            <p><strong>Provided Address: </strong></p>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Name <span class="text-danger">*</span></label>
                <div class="col-md-9">
                    <input type="text" name="name" v-model="details.name" class="form-control form-control-dark">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 col-form-label">Address One <span class="text-danger">*</span></label>
                <div class="col-md-9">
                    <input type="text" name="address_one" v-model="details.address_one" class="form-control form-control-dark">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 col-form-label">Address Two <span class="text-danger">*</span></label>
                <div class="col-md-9">
                    <input type="text" name="address_two" v-model="details.address_two" class="form-control form-control-dark">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 col-form-label">Address Three <span class="text-danger">*</span></label>
                <div class="col-md-9">
                    <input type="text" name="address_three" v-model="details.address_three" class="form-control form-control-dark">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 col-form-label">Address Four</label>
                <div class="col-md-9">
                    <input type="text" name="address_four" v-model="details.address_four" class="form-control form-control-dark">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 col-form-label">Postcode</label>
                <div class="col-md-9">
                    <input type="text" name="address_four" v-model="details.postcode" class="form-control form-control-dark">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 col-form-label">Reason for Change <span class="text-danger">*</span></label>
                <div class="col-md-9">
                    <input type="text" name="reason" v-model="reason" class="form-control form-control-dark">
                </div>
            </div>
            <p class="tx-info">If you are unable to correct the address, please go to the management tab and escalate to a Drive Off.</p>
            <submit-button v-if="changed" :text="'Save Changes and Repost'" :background="'btn btn-success pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12'" :clicked="posting" @click="saveAndRepost"></submit-button>
        </div>
        <div v-if="pdf_url !== null" class="col-6">
            <p>Notice Preview: </p>
            <iframe v-if="pdf_url !== null" :src="pdf_url" width="100%" height="750px" min-height="500px;"></iframe>
        </div>
    </div>
</template>

<script>
import SubmitButton from "@/components/SubmitButton";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "NmopPostFailed",
    components: {SubmitButton},
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
            details: {
                name: "",
                address_one: "",
                address_two: "",
                address_three: "",
                address_four: "",
                postcode: "",
                email: "",
                number: ""
            },
            reason: "Post Failed, correcting address using Royal Mail address finder.",
            originalDetails: {
                name: "",
                address_one: "",
                address_two: "",
                address_three: "",
                address_four: "",
                postcode: "",
                email: "",
                number: ""
            },
            posting: false,
            pdf_url: null,
            currentPage: 1,
            pageCount: 0,
            currentRotation: 0,
        }
    },
    beforeUnmount(){
        if(this.pdf_url){
            window.URL.revokeObjectURL(this.pdf_url);
        }
    },
    mounted(){
        axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/keeper?client_id=${this.client_id}`, {
            headers: authHeader()
        })
            .then(response => {
                let setPostcode = false;
                if(response.data.address_two.length < 2){
                    response.data.address_two = response.data.postcode;
                    setPostcode = true;
                }
                if(response.data.address_three.length < 2 && !setPostcode){
                    response.data.address_three = response.data.postcode;
                    setPostcode = true;
                }
                if(response.data.address_four.length < 2 && !setPostcode){
                    response.data.address_four = response.data.postcode;
                    setPostcode = true;
                }
                console.log(setPostcode);
                this.details = response.data;
                this.originalDetails = JSON.parse(JSON.stringify(this.details));
                this.generateNotice();
            })
            .catch(error => {
                this.$error("Failed loading NMOP details.", error);
            });
    },
    methods: {

        saveAndRepost(){
            if(this.posting) return;
            this.posting = true;
            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/keeper`, {
                ...this.details,
                reason: this.reason,
                client_id: this.client_id
            }, {
                headers: authHeader()
            })
                .then(response => {
                    if(!response.data?.success){
                        throw new Error("Failed to update No Means of Payment details!");
                    }else{
                        this.$success("Updated details and sent for postage!");
                        this.$emit('changeMade');
                        this.posting = false;
                        this.$emit('updateClaim');
                    }
                })
                .catch(error => {
                    this.$error("Failed to update No Means of Payment details!", error);
                    this.posting = false;
                })
        },
        generateNotice(){
            if(this.pdf_url){
                window.URL.revokeObjectURL(this.pdf_url);
            }
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/preview?client_id=${this.client_id}&notice_type=nmop`, {
                responseType: 'arraybuffer',
                headers: authHeader()
            },)
                .then(response => {
                    let blob = new Blob([response.data], {type: 'application/pdf'});
                    this.pdf_url = window.URL.createObjectURL(blob);
                })
                .catch(error => {
                    this.$error("Failed to generate preview!", error);
                    this.posting = false;
                })
        },
        nextPage(){
            if(this.currentPage < this.pageCount){
                this.currentPage++;
            }
        },
        previousPage(){
            if(this.currentPage > 1){
                this.currentPage--;
            }
        },
        rotateClockwise(){
          let current = this.currentRotation;
          current += 90;
          if(current > 360){
              current -= 360;
          }
          this.currentRotation = current;
        },
        rotateAntiClockwise(){
            let current = this.currentRotation;
            current -= 90;
            if(current > -360){
                current += 360;
            }
            this.currentRotation = current;
        },

    },
    computed: {
        changed: function(){
            return !(JSON.stringify(this.details) === JSON.stringify(this.originalDetails));
        }
    }
}
</script>

<style scoped>

</style>
